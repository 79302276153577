@charset "UTF-8";
:root {
  --cui-blue: #0d6efd;
  --cui-indigo: #6610f2;
  --cui-purple: #6f42c1;
  --cui-pink: #d63384;
  --cui-red: #dc3545;
  --cui-orange: #fd7e14;
  --cui-yellow: #ffc107;
  --cui-green: #198754;
  --cui-teal: #20c997;
  --cui-cyan: #0dcaf0;
  --cui-black: #000015;
  --cui-white: #fff;
  --cui-gray: #9da5b1;
  --cui-gray-dark: #768192;
  --cui-gray-100: #f0f4f7;
  --cui-gray-200: #ebedef;
  --cui-gray-300: #d8dbe0;
  --cui-gray-400: #c4c9d0;
  --cui-gray-500: #b1b7c1;
  --cui-gray-600: #9da5b1;
  --cui-gray-700: #8a93a2;
  --cui-gray-800: #768192;
  --cui-gray-900: #636f83;
  --cui-primary: #5046e5;
  --cui-secondary: #ebedef;
  --cui-success: #51cc8a;
  --cui-info: #747af2;
  --cui-warning: #fc0;
  --cui-danger: #ef376e;
  --cui-light: #f0f4f7;
  --cui-dark: #636f83;
  --cui-primary-rgb: 80, 70, 229;
  --cui-secondary-rgb: 235, 237, 239;
  --cui-success-rgb: 81, 204, 138;
  --cui-info-rgb: 116, 122, 242;
  --cui-warning-rgb: 255, 204, 0;
  --cui-danger-rgb: 239, 55, 110;
  --cui-light-rgb: 240, 244, 247;
  --cui-dark-rgb: 99, 111, 131;
  --cui-white-rgb: 255, 255, 255;
  --cui-black-rgb: 0, 0, 21;
  --cui-body-color-rgb: 44, 56, 74;
  --cui-body-bg-rgb: 255, 255, 255;
  --cui-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --cui-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --cui-body-font-family: var(--cui-font-sans-serif);
  --cui-body-font-size: 0.9375rem;
  --cui-body-font-weight: 400;
  --cui-body-line-height: 1.5;
  --cui-body-color: rgba(44, 56, 74, 0.95);
  --cui-body-bg: #fff;
  --cui-border-width: 1px;
  --cui-border-style: solid;
  --cui-border-color: #ebedef;
  --cui-border-color-translucent: rgba(0, 0, 21, 0.0875);
  --cui-border-radius: 0.375rem;
  --cui-border-radius-sm: 0.25rem;
  --cui-border-radius-lg: 0.5rem;
  --cui-border-radius-xl: 1rem;
  --cui-border-radius-2xl: 2rem;
  --cui-border-radius-pill: 50rem;
  --cui-heading-color: unset;
  --cui-link-color: #5046e5;
  --cui-link-hover-color: #4038b7;
  --cui-code-color: #d63384;
  --cui-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--cui-body-font-family);
  font-size: var(--cui-body-font-size);
  font-weight: var(--cui-body-font-weight);
  line-height: var(--cui-body-line-height);
  color: var(--cui-body-color);
  text-align: var(--cui-body-text-align);
  background-color: var(--cui-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 21, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--cui-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
}
html:not([dir=rtl]) dd {
  margin-left: 0;
}
*[dir=rtl] dd {
  margin-right: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--cui-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--cui-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--cui-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--cui-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  color: var(--cui-pre-color, unset);
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--cui-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--cui-kbd-color, var(--cui-body-bg));
  background-color: var(--cui-kbd-bg, var(--cui-body-color));
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--cui-table-caption-color, rgba(44, 56, 74, 0.38));
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
html:not([dir=rtl]) legend {
  float: left;
}
*[dir=rtl] legend {
  float: right;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

*[dir=rtl] [type=tel],
*[dir=rtl] [type=url],
*[dir=rtl] [type=email],
*[dir=rtl] [type=number] {
  direction: ltr;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  list-style: none;
}
html:not([dir=rtl]) .list-unstyled {
  padding-left: 0;
}
*[dir=rtl] .list-unstyled {
  padding-right: 0;
}

.list-inline {
  list-style: none;
}
html:not([dir=rtl]) .list-inline {
  padding-left: 0;
}
*[dir=rtl] .list-inline {
  padding-right: 0;
}

.list-inline-item {
  display: inline-block;
}
html:not([dir=rtl]) .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
*[dir=rtl] .list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #9da5b1;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--cui-thumbnail-bg, #fff);
  border: 1px solid var(--cui-thumbnail-border-color, var(--cui-border-color));
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--cui-figure-caption-color, #9da5b1);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --cui-gutter-x: 1.5rem;
  --cui-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--cui-gutter-x) * 0.5);
  padding-left: calc(var(--cui-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --cui-gutter-x: 1.5rem;
  --cui-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--cui-gutter-y));
  margin-right: calc(-0.5 * var(--cui-gutter-x));
  margin-left: calc(-0.5 * var(--cui-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--cui-gutter-x) * 0.5);
  padding-left: calc(var(--cui-gutter-x) * 0.5);
  margin-top: var(--cui-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

html:not([dir=rtl]) .offset-1 {
  margin-left: 8.33333333%;
}
*[dir=rtl] .offset-1 {
  margin-right: 8.33333333%;
}

html:not([dir=rtl]) .offset-2 {
  margin-left: 16.66666667%;
}
*[dir=rtl] .offset-2 {
  margin-right: 16.66666667%;
}

html:not([dir=rtl]) .offset-3 {
  margin-left: 25%;
}
*[dir=rtl] .offset-3 {
  margin-right: 25%;
}

html:not([dir=rtl]) .offset-4 {
  margin-left: 33.33333333%;
}
*[dir=rtl] .offset-4 {
  margin-right: 33.33333333%;
}

html:not([dir=rtl]) .offset-5 {
  margin-left: 41.66666667%;
}
*[dir=rtl] .offset-5 {
  margin-right: 41.66666667%;
}

html:not([dir=rtl]) .offset-6 {
  margin-left: 50%;
}
*[dir=rtl] .offset-6 {
  margin-right: 50%;
}

html:not([dir=rtl]) .offset-7 {
  margin-left: 58.33333333%;
}
*[dir=rtl] .offset-7 {
  margin-right: 58.33333333%;
}

html:not([dir=rtl]) .offset-8 {
  margin-left: 66.66666667%;
}
*[dir=rtl] .offset-8 {
  margin-right: 66.66666667%;
}

html:not([dir=rtl]) .offset-9 {
  margin-left: 75%;
}
*[dir=rtl] .offset-9 {
  margin-right: 75%;
}

html:not([dir=rtl]) .offset-10 {
  margin-left: 83.33333333%;
}
*[dir=rtl] .offset-10 {
  margin-right: 83.33333333%;
}

html:not([dir=rtl]) .offset-11 {
  margin-left: 91.66666667%;
}
*[dir=rtl] .offset-11 {
  margin-right: 91.66666667%;
}

.g-0,
.gx-0 {
  --cui-gutter-x: 0;
}

.g-0,
.gy-0 {
  --cui-gutter-y: 0;
}

.g-1,
.gx-1 {
  --cui-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --cui-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --cui-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --cui-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --cui-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --cui-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --cui-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --cui-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --cui-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --cui-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  html:not([dir=rtl]) .offset-sm-0 {
    margin-left: 0;
  }
  *[dir=rtl] .offset-sm-0 {
    margin-right: 0;
  }
  html:not([dir=rtl]) .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  *[dir=rtl] .offset-sm-1 {
    margin-right: 8.33333333%;
  }
  html:not([dir=rtl]) .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  *[dir=rtl] .offset-sm-2 {
    margin-right: 16.66666667%;
  }
  html:not([dir=rtl]) .offset-sm-3 {
    margin-left: 25%;
  }
  *[dir=rtl] .offset-sm-3 {
    margin-right: 25%;
  }
  html:not([dir=rtl]) .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  *[dir=rtl] .offset-sm-4 {
    margin-right: 33.33333333%;
  }
  html:not([dir=rtl]) .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  *[dir=rtl] .offset-sm-5 {
    margin-right: 41.66666667%;
  }
  html:not([dir=rtl]) .offset-sm-6 {
    margin-left: 50%;
  }
  *[dir=rtl] .offset-sm-6 {
    margin-right: 50%;
  }
  html:not([dir=rtl]) .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  *[dir=rtl] .offset-sm-7 {
    margin-right: 58.33333333%;
  }
  html:not([dir=rtl]) .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  *[dir=rtl] .offset-sm-8 {
    margin-right: 66.66666667%;
  }
  html:not([dir=rtl]) .offset-sm-9 {
    margin-left: 75%;
  }
  *[dir=rtl] .offset-sm-9 {
    margin-right: 75%;
  }
  html:not([dir=rtl]) .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  *[dir=rtl] .offset-sm-10 {
    margin-right: 83.33333333%;
  }
  html:not([dir=rtl]) .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  *[dir=rtl] .offset-sm-11 {
    margin-right: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --cui-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --cui-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --cui-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --cui-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --cui-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --cui-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --cui-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --cui-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --cui-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --cui-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --cui-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --cui-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  html:not([dir=rtl]) .offset-md-0 {
    margin-left: 0;
  }
  *[dir=rtl] .offset-md-0 {
    margin-right: 0;
  }
  html:not([dir=rtl]) .offset-md-1 {
    margin-left: 8.33333333%;
  }
  *[dir=rtl] .offset-md-1 {
    margin-right: 8.33333333%;
  }
  html:not([dir=rtl]) .offset-md-2 {
    margin-left: 16.66666667%;
  }
  *[dir=rtl] .offset-md-2 {
    margin-right: 16.66666667%;
  }
  html:not([dir=rtl]) .offset-md-3 {
    margin-left: 25%;
  }
  *[dir=rtl] .offset-md-3 {
    margin-right: 25%;
  }
  html:not([dir=rtl]) .offset-md-4 {
    margin-left: 33.33333333%;
  }
  *[dir=rtl] .offset-md-4 {
    margin-right: 33.33333333%;
  }
  html:not([dir=rtl]) .offset-md-5 {
    margin-left: 41.66666667%;
  }
  *[dir=rtl] .offset-md-5 {
    margin-right: 41.66666667%;
  }
  html:not([dir=rtl]) .offset-md-6 {
    margin-left: 50%;
  }
  *[dir=rtl] .offset-md-6 {
    margin-right: 50%;
  }
  html:not([dir=rtl]) .offset-md-7 {
    margin-left: 58.33333333%;
  }
  *[dir=rtl] .offset-md-7 {
    margin-right: 58.33333333%;
  }
  html:not([dir=rtl]) .offset-md-8 {
    margin-left: 66.66666667%;
  }
  *[dir=rtl] .offset-md-8 {
    margin-right: 66.66666667%;
  }
  html:not([dir=rtl]) .offset-md-9 {
    margin-left: 75%;
  }
  *[dir=rtl] .offset-md-9 {
    margin-right: 75%;
  }
  html:not([dir=rtl]) .offset-md-10 {
    margin-left: 83.33333333%;
  }
  *[dir=rtl] .offset-md-10 {
    margin-right: 83.33333333%;
  }
  html:not([dir=rtl]) .offset-md-11 {
    margin-left: 91.66666667%;
  }
  *[dir=rtl] .offset-md-11 {
    margin-right: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --cui-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --cui-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --cui-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --cui-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --cui-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --cui-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --cui-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --cui-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --cui-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --cui-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --cui-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --cui-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  html:not([dir=rtl]) .offset-lg-0 {
    margin-left: 0;
  }
  *[dir=rtl] .offset-lg-0 {
    margin-right: 0;
  }
  html:not([dir=rtl]) .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  *[dir=rtl] .offset-lg-1 {
    margin-right: 8.33333333%;
  }
  html:not([dir=rtl]) .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  *[dir=rtl] .offset-lg-2 {
    margin-right: 16.66666667%;
  }
  html:not([dir=rtl]) .offset-lg-3 {
    margin-left: 25%;
  }
  *[dir=rtl] .offset-lg-3 {
    margin-right: 25%;
  }
  html:not([dir=rtl]) .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  *[dir=rtl] .offset-lg-4 {
    margin-right: 33.33333333%;
  }
  html:not([dir=rtl]) .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  *[dir=rtl] .offset-lg-5 {
    margin-right: 41.66666667%;
  }
  html:not([dir=rtl]) .offset-lg-6 {
    margin-left: 50%;
  }
  *[dir=rtl] .offset-lg-6 {
    margin-right: 50%;
  }
  html:not([dir=rtl]) .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  *[dir=rtl] .offset-lg-7 {
    margin-right: 58.33333333%;
  }
  html:not([dir=rtl]) .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  *[dir=rtl] .offset-lg-8 {
    margin-right: 66.66666667%;
  }
  html:not([dir=rtl]) .offset-lg-9 {
    margin-left: 75%;
  }
  *[dir=rtl] .offset-lg-9 {
    margin-right: 75%;
  }
  html:not([dir=rtl]) .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  *[dir=rtl] .offset-lg-10 {
    margin-right: 83.33333333%;
  }
  html:not([dir=rtl]) .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  *[dir=rtl] .offset-lg-11 {
    margin-right: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --cui-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --cui-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --cui-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --cui-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --cui-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --cui-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --cui-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --cui-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --cui-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --cui-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --cui-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --cui-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  html:not([dir=rtl]) .offset-xl-0 {
    margin-left: 0;
  }
  *[dir=rtl] .offset-xl-0 {
    margin-right: 0;
  }
  html:not([dir=rtl]) .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  *[dir=rtl] .offset-xl-1 {
    margin-right: 8.33333333%;
  }
  html:not([dir=rtl]) .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  *[dir=rtl] .offset-xl-2 {
    margin-right: 16.66666667%;
  }
  html:not([dir=rtl]) .offset-xl-3 {
    margin-left: 25%;
  }
  *[dir=rtl] .offset-xl-3 {
    margin-right: 25%;
  }
  html:not([dir=rtl]) .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  *[dir=rtl] .offset-xl-4 {
    margin-right: 33.33333333%;
  }
  html:not([dir=rtl]) .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  *[dir=rtl] .offset-xl-5 {
    margin-right: 41.66666667%;
  }
  html:not([dir=rtl]) .offset-xl-6 {
    margin-left: 50%;
  }
  *[dir=rtl] .offset-xl-6 {
    margin-right: 50%;
  }
  html:not([dir=rtl]) .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  *[dir=rtl] .offset-xl-7 {
    margin-right: 58.33333333%;
  }
  html:not([dir=rtl]) .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  *[dir=rtl] .offset-xl-8 {
    margin-right: 66.66666667%;
  }
  html:not([dir=rtl]) .offset-xl-9 {
    margin-left: 75%;
  }
  *[dir=rtl] .offset-xl-9 {
    margin-right: 75%;
  }
  html:not([dir=rtl]) .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  *[dir=rtl] .offset-xl-10 {
    margin-right: 83.33333333%;
  }
  html:not([dir=rtl]) .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  *[dir=rtl] .offset-xl-11 {
    margin-right: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --cui-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --cui-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --cui-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --cui-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --cui-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --cui-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --cui-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --cui-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --cui-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --cui-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --cui-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --cui-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  html:not([dir=rtl]) .offset-xxl-0 {
    margin-left: 0;
  }
  *[dir=rtl] .offset-xxl-0 {
    margin-right: 0;
  }
  html:not([dir=rtl]) .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  *[dir=rtl] .offset-xxl-1 {
    margin-right: 8.33333333%;
  }
  html:not([dir=rtl]) .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  *[dir=rtl] .offset-xxl-2 {
    margin-right: 16.66666667%;
  }
  html:not([dir=rtl]) .offset-xxl-3 {
    margin-left: 25%;
  }
  *[dir=rtl] .offset-xxl-3 {
    margin-right: 25%;
  }
  html:not([dir=rtl]) .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  *[dir=rtl] .offset-xxl-4 {
    margin-right: 33.33333333%;
  }
  html:not([dir=rtl]) .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  *[dir=rtl] .offset-xxl-5 {
    margin-right: 41.66666667%;
  }
  html:not([dir=rtl]) .offset-xxl-6 {
    margin-left: 50%;
  }
  *[dir=rtl] .offset-xxl-6 {
    margin-right: 50%;
  }
  html:not([dir=rtl]) .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  *[dir=rtl] .offset-xxl-7 {
    margin-right: 58.33333333%;
  }
  html:not([dir=rtl]) .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  *[dir=rtl] .offset-xxl-8 {
    margin-right: 66.66666667%;
  }
  html:not([dir=rtl]) .offset-xxl-9 {
    margin-left: 75%;
  }
  *[dir=rtl] .offset-xxl-9 {
    margin-right: 75%;
  }
  html:not([dir=rtl]) .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  *[dir=rtl] .offset-xxl-10 {
    margin-right: 83.33333333%;
  }
  html:not([dir=rtl]) .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  *[dir=rtl] .offset-xxl-11 {
    margin-right: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --cui-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --cui-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --cui-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --cui-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --cui-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --cui-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --cui-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --cui-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --cui-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --cui-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --cui-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --cui-gutter-y: 3rem;
  }
}
.table {
  --cui-table-color: var(--cui-body-color);
  --cui-table-bg: transparent;
  --cui-table-border-color: var(--cui-border-color);
  --cui-table-accent-bg: transparent;
  --cui-table-striped-color: var(--cui-body-color);
  --cui-table-striped-bg: rgba(0, 0, 21, 0.05);
  --cui-table-active-color: var(--cui-body-color);
  --cui-table-active-bg: rgba(0, 0, 21, 0.1);
  --cui-table-hover-color: var(--cui-body-color);
  --cui-table-hover-bg: rgba(0, 0, 21, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--cui-table-color);
  vertical-align: top;
  border-color: var(--cui-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--cui-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --cui-table-accent-bg: var(--cui-table-striped-bg);
  color: var(--cui-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --cui-table-accent-bg: var(--cui-table-striped-bg);
  color: var(--cui-table-striped-color);
}

.table-active {
  --cui-table-accent-bg: var(--cui-table-active-bg);
  color: var(--cui-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --cui-table-accent-bg: var(--cui-table-hover-bg);
  color: var(--cui-table-hover-color);
}

.table-primary {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #dcdafa;
  --cui-table-border-color: rgba(204, 203, 234, 0.995);
  --cui-table-striped-bg: rgba(212, 211, 242, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(204, 203, 234, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(208, 207, 238, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-secondary {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #fbfbfc;
  --cui-table-border-color: rgba(232, 233, 236, 0.995);
  --cui-table-striped-bg: rgba(242, 242, 244, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(232, 233, 236, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(237, 238, 240, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-success {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #dcf5e8;
  --cui-table-border-color: rgba(204, 228, 218, 0.995);
  --cui-table-striped-bg: rgba(212, 236, 225, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(204, 228, 218, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(208, 232, 221, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-info {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #e3e4fc;
  --cui-table-border-color: rgba(210, 212, 236, 0.995);
  --cui-table-striped-bg: rgba(219, 220, 244, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(210, 212, 236, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(214, 216, 240, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-warning {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #fff5cc;
  --cui-table-border-color: rgba(236, 228, 192, 0.995);
  --cui-table-striped-bg: rgba(245, 236, 198, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(236, 228, 192, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(241, 232, 195, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-danger {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #fcd7e2;
  --cui-table-border-color: rgba(233, 200, 212, 0.995);
  --cui-table-striped-bg: rgba(243, 208, 219, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(233, 200, 212, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(238, 204, 216, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-light {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #f0f4f7;
  --cui-table-border-color: rgba(222, 227, 231, 0.995);
  --cui-table-striped-bg: rgba(231, 235, 239, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(222, 227, 231, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(227, 231, 235, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-dark {
  --cui-table-color: #fff;
  --cui-table-bg: #636f83;
  --cui-table-border-color: #737d8f;
  --cui-table-striped-bg: #6b7689;
  --cui-table-striped-color: #fff;
  --cui-table-active-bg: #737d8f;
  --cui-table-active-color: #000015;
  --cui-table-hover-bg: #6f7a8c;
  --cui-table-hover-color: #000015;
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  color: var(--cui-form-label-color, );
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: var(--cui-form-label-color, );
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--cui-form-text-color, rgba(44, 56, 74, 0.38));
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-input-border-color, #c4c9d0);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-focus-bg, #fff);
  border-color: var(--cui-input-focus-border-color, #a8a3f2);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: var(--cui-input-placeholder-color, #9da5b1);
  opacity: 1;
}
.form-control:disabled {
  background-color: #ebedef;
  border-color: #c4c9d0;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--cui-form-file-button-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-form-file-button-bg, #ebedef);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--cui-form-file-button-hover-bg, #dfe1e3);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--cui-input-plaintext-color, rgba(44, 56, 74, 0.95));
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-form-select-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-form-select-bg, #fff);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23768192' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid var(--cui-form-select-border-color, #c4c9d0);
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
html:not([dir=rtl]) .form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}
*[dir=rtl] .form-select {
  padding: 0.375rem 0.75rem 0.375rem 2.25rem;
}
html:not([dir=rtl]) .form-select {
  background-position: right 0.75rem center;
}
*[dir=rtl] .form-select {
  background-position: left 0.75rem center;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: var(--cui-form-select-focus-border-color, #a8a3f2);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
}
html:not([dir=rtl]) .form-select[multiple], html:not([dir=rtl]) .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
}
*[dir=rtl] .form-select[multiple], *[dir=rtl] .form-select[size]:not([size="1"]) {
  padding-left: 0.75rem;
}
.form-select:disabled {
  color: var(--cui-form-select-disabled-color, );
  background-color: var(--cui-form-select-disabled-bg, #ebedef);
  border-color: var(--cui-form-select-disabled-border-color, #c4c9d0);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--cui-form-select-color, rgba(44, 56, 74, 0.95));
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
html:not([dir=rtl]) .form-select-sm {
  padding-left: 0.5rem;
}
*[dir=rtl] .form-select-sm {
  padding-right: 0.5rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
html:not([dir=rtl]) .form-select-lg {
  padding-left: 1rem;
}
*[dir=rtl] .form-select-lg {
  padding-right: 1rem;
}

.dark-theme .form-select > option {
  background-color: #44454e;
}

.form-multi-select {
  --cui-form-multi-select-padding-y: 0.375rem;
  --cui-form-multi-select-padding-x: 0.75rem;
  --cui-form-multi-select-font-family: ;
  --cui-form-multi-select-font-size: 1rem;
  --cui-form-multi-select-font-weight: 400;
  --cui-form-multi-select-line-height: 1.5;
  --cui-form-multi-select-color: rgba(44, 56, 74, 0.95);
  --cui-form-multi-select-bg: #fff;
  --cui-form-multi-select-bg-position: right 0.75rem center;
  --cui-form-multi-select-bg-size: 16px 12px;
  --cui-form-multi-select-border-color: #c4c9d0;
  --cui-form-multi-select-border: 1px solid var(--cui-form-multi-select-border-color);
  --cui-form-multi-select-border-radius: 0.375rem;
  --cui-form-multi-select-disabled-color: #9da5b1;
  --cui-form-multi-select-disabled-bg: #ebedef;
  --cui-form-multi-select-focus-color: rgba(44, 56, 74, 0.95);
  --cui-form-multi-select-focus-bg: #fff;
  --cui-form-multi-select-focus-border-color: #7c74ec;
  --cui-form-multi-select-selection-tags-padding-y: 0.125rem;
  --cui-form-multi-select-selection-tags-padding-x: 0.125rem;
  --cui-form-multi-select-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23768192' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  --cui-form-multi-select-indicator-padding: 2.25rem;
  --cui-form-multi-select-cleaner-width: 0.75rem;
  --cui-form-multi-select-cleaner-height: 0.75rem;
  --cui-form-multi-select-cleaner-padding-y: 0.5rem;
  --cui-form-multi-select-cleaner-padding-x: 0.75rem;
  --cui-form-multi-select-cleaner-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%2844, 56, 74, 0.681%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  --cui-form-multi-select-cleaner-hover-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%2844, 56, 74, 0.95%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  --cui-form-multi-select-cleaner-focus-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%2844, 56, 74, 0.95%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  --cui-form-multi-select-search-color: rgba(44, 56, 74, 0.95);
  --cui-form-multi-select-search-bg: #fff;
  --cui-form-multi-select-search-border-radius: 0.375rem;
  --cui-form-multi-select-select-all-padding-y: 0.5rem;
  --cui-form-multi-select-select-all-padding-x: 0.75rem;
  --cui-form-multi-select-select-all-color: rgba(44, 56, 74, 0.681);
  --cui-form-multi-select-select-all-bg: transparent;
  --cui-form-multi-select-select-all-border-color: #c4c9d0;
  --cui-form-multi-select-select-all-border: 1px solid var(--cui-form-multi-select-select-all-border-color);
  --cui-form-multi-select-select-all-hover-color: rgba(44, 56, 74, 0.95);
  --cui-form-multi-select-select-all-hover-bg: transparent;
  --cui-form-multi-select-options-margin-top: 0.625rem;
  --cui-form-multi-select-options-bg: #fff;
  --cui-form-multi-select-options-border-color: #ebedef;
  --cui-form-multi-select-options-border: 1px solid var(--cui-form-multi-select-options-border-color);
  --cui-form-multi-select-options-border-radius: 0.375rem;
  --cui-form-multi-select-options-padding-y: 0.5rem;
  --cui-form-multi-select-options-padding-x: 0.75rem;
  --cui-form-multi-select-options-color: rgba(44, 56, 74, 0.95);
  --cui-form-multi-select-option-padding-y: 0.5rem;
  --cui-form-multi-select-option-padding-x: 1.25rem;
  --cui-form-multi-select-option-border-radius: 0.375rem;
  --cui-form-multi-select-option-hover-color: #5e697c;
  --cui-form-multi-select-option-hover-bg: rgba(240, 244, 247, 0.5);
  --cui-form-multi-select-option-disabled-color: #9da5b1;
  --cui-form-multi-select-option-indicator-width: 1em;
  --cui-form-multi-select-option-indicator-bg: #fff;
  --cui-form-multi-select-option-indicator-border: 1px solid rgba(0, 0, 21, 0.25);
  --cui-form-multi-select-option-indicator-border-radius: 0.25em;
  --cui-form-multi-select-option-selected-bg: #f0f4f7;
  --cui-form-multi-select-option-selected-indicator-bg: #5046e5;
  --cui-form-multi-select-option-selected-indicator-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.87%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  --cui-form-multi-select-option-selected-indicator-border-color: #5046e5;
  --cui-form-multi-select-tag-padding-y: calc(0.125rem - 1px);
  --cui-form-multi-select-tag-padding-x: 0.5rem;
  --cui-form-multi-select-tag-margin-y: 0.125rem;
  --cui-form-multi-select-tag-margin-x: 0.125rem;
  --cui-form-multi-select-tag-bg: #f0f4f7;
  --cui-form-multi-select-tag-border-color: #ebedef;
  --cui-form-multi-select-tag-border: 1px solid var(--cui-form-multi-select-tag-border-color);
  --cui-form-multi-select-tag-border-radius: 0.25rem;
  position: relative;
  display: block;
  width: 100%;
  padding: var(--cui-form-multi-select-padding-y) var(--cui-form-multi-select-padding-x);
  font-family: var(--cui-form-multi-select-font-family);
  font-size: var(--cui-form-multi-select-font-size);
  font-weight: var(--cui-form-multi-select-font-weight);
  line-height: var(--cui-form-multi-select-line-height);
  color: var(--cui-form-multi-select-color);
  background-color: var(--cui-form-multi-select-bg);
  background-image: var(--cui-form-multi-select-indicator);
  background-repeat: no-repeat;
  background-size: var(--cui-form-multi-select-bg-size);
  border: var(--cui-form-multi-select-border);
  border-radius: var(--cui-form-multi-select-border-radius);
  appearance: none;
}
html:not([dir=rtl]) .form-multi-select {
  padding-right: var(--cui-form-multi-select-indicator-padding);
}
*[dir=rtl] .form-multi-select {
  padding-left: var(--cui-form-multi-select-indicator-padding);
}
html:not([dir=rtl]) .form-multi-select {
  background-position: var(--cui-form-multi-select-bg-position);
}
*[dir=rtl] .form-multi-select {
  background-position: var(--cui-form-multi-select-bg-position);
}
.form-multi-select:disabled, .form-multi-select.disabled {
  color: var(--cui-form-multi-select-disabled-color);
  background-color: var(--cui-form-multi-select-disabled-bg);
}
.form-multi-select:disabled .form-multi-select-search, .form-multi-select.disabled .form-multi-select-search {
  background: transparent;
}
html:not([dir=rtl]) .form-multi-select.form-multi-select-with-cleaner {
  padding-right: 4.5rem;
}
*[dir=rtl] .form-multi-select.form-multi-select-with-cleaner {
  padding-left: 4.5rem;
}
.form-multi-select.is-invalid {
  --cui-form-multi-select-border-color: #ef376e;
}
.form-multi-select.is-valid {
  --cui-form-multi-select-border-color: #51cc8a;
}

.form-multi-select-selection-tags {
  min-height: 2.25rem;
  padding: var(--cui-form-multi-select-selection-tags-padding-y) var(--cui-form-multi-select-selection-tags-padding-x);
}
html:not([dir=rtl]) .form-multi-select-selection-tags {
  padding-right: var(--cui-form-multi-select-indicator-padding);
}
*[dir=rtl] .form-multi-select-selection-tags {
  padding-left: var(--cui-form-multi-select-indicator-padding);
}
.form-multi-select-selection-tags .form-multi-select-search {
  height: 2rem;
}
html:not([dir=rtl]) .form-multi-select-selection-tags .form-multi-select-search {
  margin-left: var(--cui-form-multi-select-padding-x);
}
*[dir=rtl] .form-multi-select-selection-tags .form-multi-select-search {
  margin-right: var(--cui-form-multi-select-padding-x);
}

.form-multi-select-selection {
  display: inline;
  padding: 0;
}

.form-multi-select-selection-cleaner {
  position: absolute;
  top: 50%;
  box-sizing: content-box;
  width: var(--cui-form-multi-select-cleaner-width);
  height: var(--cui-form-multi-select-cleaner-height);
  padding: var(--cui-form-multi-select-cleaner-padding-y) var(--cui-form-multi-select-cleaner-padding-x);
  background-color: transparent;
  background-image: var(--cui-form-multi-select-cleaner-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--cui-form-multi-select-cleaner-width) auto;
  border: 0;
  transform: translateY(-50%);
}
html:not([dir=rtl]) .form-multi-select-selection-cleaner {
  right: var(--cui-form-multi-select-indicator-padding);
}
*[dir=rtl] .form-multi-select-selection-cleaner {
  left: var(--cui-form-multi-select-indicator-padding);
}
html:not([dir=rtl]) .form-multi-select-selection-cleaner {
  border-right: 1px solid var(--cui-form-multi-select-cleaner-border-color, #c4c9d0);
}
*[dir=rtl] .form-multi-select-selection-cleaner {
  border-left: 1px solid var(--cui-form-multi-select-cleaner-border-color, #c4c9d0);
}
.form-multi-select-selection-cleaner:hover {
  background-image: var(--cui-form-multi-select-cleaner-hover-bg);
}
.form-multi-select-selection-cleaner:focus {
  background-image: var(--cui-form-multi-select-cleaner-focus-bg);
  outline: 0;
}

.form-multi-select-search {
  width: auto;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: var(--cui-form-multi-select-search-color);
  background-color: var(--cui-form-multi-select-search-bg);
  border: 0;
  appearance: none;
  border-radius: var(--cui-form-multi-select-search-border-radius);
}
.form-multi-select-search:focus {
  outline: 0;
}
.form-multi-select-search[size] {
  display: none;
}
html:not([dir=rtl]) .form-multi-select-search[size] {
  margin-left: var(--cui-form-multi-select-padding-x);
}
*[dir=rtl] .form-multi-select-search[size] {
  margin-right: var(--cui-form-multi-select-padding-x);
}
.form-multi-select-search:placeholder-shown {
  width: 100%;
}

.form-multi-select-all {
  display: block;
  width: 100%;
  padding: var(--cui-form-multi-select-select-all-padding-y) var(--cui-form-multi-select-select-all-padding-x);
  color: var(--cui-form-multi-select-select-all-color);
  text-align: start;
  background-color: var(--cui-form-multi-select-select-all-bg);
  border: 0;
  border-bottom: var(--cui-form-multi-select-select-all-border);
}
.form-multi-select-all:hover {
  color: var(--cui-form-multi-select-select-all-hover-color);
  background-color: var(--cui-form-multi-select-select-all-hover-bg);
}

.form-multi-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  width: 100%;
  margin-top: var(--cui-form-multi-select-options-margin-top);
  background-color: var(--cui-form-multi-select-options-bg);
  background-clip: padding-box;
  border: var(--cui-form-multi-select-options-border);
  border-radius: var(--cui-form-multi-select-options-border-radius);
}

.form-multi-select-options {
  padding: var(--cui-form-multi-select-options-padding-y) var(--cui-form-multi-select-options-padding-x);
  font-size: 1rem;
  color: var(--cui-form-multi-select-options-color);
}

.form-multi-select-option {
  position: relative;
  padding: var(--cui-form-multi-select-option-padding-y) var(--cui-form-multi-select-option-padding-x);
  margin-bottom: 2px;
  vertical-align: top;
  cursor: pointer;
  border-radius: var(--cui-form-multi-select-option-border-radius);
}
.form-multi-select-option:hover, .form-multi-select-option:focus {
  color: var(--cui-form-multi-select-option-hover-color);
  text-decoration: none;
  background-color: var(--cui-form-multi-select-option-hover-bg);
}
.form-multi-select-option.disabled, .form-multi-select-option:disabled {
  color: var(--cui-form-multi-select-option-disabled-color);
  pointer-events: none;
  background-color: transparent;
}
.form-multi-select-option.form-multi-select-option-with-checkbox {
  padding: 0.5rem 1.25rem;
}
html:not([dir=rtl]) .form-multi-select-option.form-multi-select-option-with-checkbox {
  padding-left: calc(var(--cui-form-multi-select-option-padding-x) + var(--cui-form-multi-select-option-indicator-width));
}
*[dir=rtl] .form-multi-select-option.form-multi-select-option-with-checkbox {
  padding-right: calc(var(--cui-form-multi-select-option-padding-x) + var(--cui-form-multi-select-option-indicator-width));
}
.form-multi-select-option.form-multi-select-option-with-checkbox::before {
  position: absolute;
  top: 0.7rem;
  display: block;
  width: var(--cui-form-multi-select-option-indicator-width);
  height: var(--cui-form-multi-select-option-indicator-width);
  pointer-events: none;
  content: "";
  background-color: var(--cui-form-multi-select-option-indicator-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--cui-form-multi-select-option-indicator-border);
  border-radius: var(--cui-form-multi-select-option-indicator-border-radius);
}
html:not([dir=rtl]) .form-multi-select-option.form-multi-select-option-with-checkbox::before {
  left: calc(var(--cui-form-multi-select-option-padding-x) * 0.5);
}
*[dir=rtl] .form-multi-select-option.form-multi-select-option-with-checkbox::before {
  right: calc(var(--cui-form-multi-select-option-padding-x) * 0.5);
}
.form-multi-select-option.form-multi-selected {
  background-color: var(--cui-form-multi-select-option-selected-bg);
}
.form-multi-select-option.form-multi-selected::before {
  background-color: var(--cui-form-multi-select-option-selected-indicator-bg);
  background-image: var(--cui-form-multi-select-option-selected-indicator-bg-image);
  border-color: var(--cui-form-multi-select-option-selected-indicator-border-color);
}

.form-multi-select-optgroup-label {
  padding: 0.75rem 0.625rem 0.5rem 0.625rem;
  font-size: 80%;
  font-weight: 700;
  color: rgba(44, 56, 74, 0.38);
  text-transform: uppercase;
}

.form-multi-select-options-empty {
  padding: var(--cui-form-multi-select-option-padding-y) var(--cui-form-multi-select-option-padding-x);
}

.form-multi-select-tag {
  display: inline-block;
  padding: var(--cui-form-multi-select-tag-padding-y) var(--cui-form-multi-select-tag-padding-x);
  margin: var(--cui-form-multi-select-tag-margin-y) var(--cui-form-multi-select-tag-margin-x);
  background-color: var(--cui-form-multi-select-tag-bg);
  border: var(--cui-form-multi-select-tag-border);
  border-radius: var(--cui-form-multi-select-tag-border-radius);
}

.form-multi-select-tag-delete {
  padding: 0;
  background-color: transparent;
  border: 0;
}
html:not([dir=rtl]) .form-multi-select-tag-delete {
  margin-left: 0.5rem;
}
*[dir=rtl] .form-multi-select-tag-delete {
  margin-right: 0.5rem;
}

.form-multi-select.show {
  color: var(--cui-form-multi-select-focus-color);
  background-color: var(--cui-form-multi-select-focus-bg);
  border-color: var(--cui-form-multi-select-focus-border-color);
  box-shadow: 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
}
.form-multi-select.show .form-multi-select-search[size] {
  display: inline;
}
.form-multi-select.show .form-multi-select-dropdown {
  z-index: 1000;
  display: initial;
}

.form-multi-select-sm {
  --cui-form-multi-select-padding-y: 0.25rem;
  --cui-form-multi-select-padding-x: 0.5rem;
  --cui-form-multi-select-font-size: 0.875rem;
  --cui-form-multi-select-border-radius: 0.25rem;
  --cui-form-multi-select-selection-tags-padding-x: 0.125rem;
  --cui-form-multi-select-tag-padding-y: 0;
  --cui-form-multi-select-tag-padding-x: 0.5rem;
  --cui-form-multi-select-tag-margin-y: 0.0675rem;
  --cui-form-multi-select-tag-margin-x: 0.0675rem;
  --cui-form-multi-select-tag-border-radius: 0.125rem;
}
.form-multi-select-sm.form-multi-select-selection-tags {
  min-height: 1.8125rem;
}
.form-multi-select-sm.form-multi-select-selection-tags .form-multi-select-search {
  height: calc(1.6775rem - 2px);
}

.form-multi-select-lg {
  --cui-form-multi-select-padding-y: 0.5rem;
  --cui-form-multi-select-padding-x: 1rem;
  --cui-form-multi-select-font-size: 1.25rem;
  --cui-form-multi-select-border-radius: 0.5rem;
  --cui-form-multi-select-selection-tags-padding-x: 0.125rem;
  --cui-form-multi-select-tag-padding-y: calc(0.25rem - 1px);
  --cui-form-multi-select-tag-padding-x: 0.5rem;
  --cui-form-multi-select-tag-margin-y: 0.125rem;
  --cui-form-multi-select-tag-margin-x: 0.125rem;
  --cui-form-multi-select-tag-border-radius: 0.375rem;
}
.form-multi-select-lg.form-multi-select-selection-tags {
  min-height: calc(2.875rem + 2px);
}
.form-multi-select-lg.form-multi-select-selection-tags .form-multi-select-search {
  height: 2.625rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  margin-bottom: 0.125rem;
}
html:not([dir=rtl]) .form-check {
  padding-left: 1.5em;
}
*[dir=rtl] .form-check {
  padding-right: 1.5em;
}
html:not([dir=rtl]) .form-check .form-check-input {
  float: left;
}
*[dir=rtl] .form-check .form-check-input {
  float: right;
}
html:not([dir=rtl]) .form-check .form-check-input {
  margin-left: -1.5em;
}
*[dir=rtl] .form-check .form-check-input {
  margin-right: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--cui-form-check-input-bg, #fff);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 21, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #a8a3f2;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
}
.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #5046e5);
  border-color: var(--cui-form-check-input-checked-border-color, #5046e5);
}
.form-check-input:checked[type=checkbox] {
  background-image: var(--cui-form-check-input-checked-bg-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.87%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"));
}
.form-check-input:checked[type=radio] {
  background-image: var(--cui-form-check-radio-checked-bg-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='rgba%28255, 255, 255, 0.87%29'/%3e%3c/svg%3e"));
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #5046e5;
  border-color: #5046e5;
  background-image: var(--cui-form-check-input-indeterminate-bg-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.87%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"));
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-check-label {
  color: var(--cui-form-check-label-color, unset);
}

html:not([dir=rtl]) .form-switch {
  padding-left: 2.5em;
}
*[dir=rtl] .form-switch {
  padding-right: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  background-image: var(--cui-form-switch-bg-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 21, 0.25%29'/%3e%3c/svg%3e"));
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
html:not([dir=rtl]) .form-switch .form-check-input {
  margin-left: -2.5em;
}
*[dir=rtl] .form-switch .form-check-input {
  margin-right: -2.5em;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: var(--cui-form-switch-focus-bg-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a8a3f2'/%3e%3c/svg%3e"));
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: var(--cui-form-switch-checked-bg-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.87%29'/%3e%3c/svg%3e"));
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-switch-lg {
  min-height: 1.25em;
}
html:not([dir=rtl]) .form-switch-lg {
  padding-left: 2.25em;
}
*[dir=rtl] .form-switch-lg {
  padding-right: 2.25em;
}
.form-switch-lg .form-check-input {
  width: 1.75em;
  height: 1.25em;
}
html:not([dir=rtl]) .form-switch-lg .form-check-input {
  margin-left: -2.25em;
}
*[dir=rtl] .form-switch-lg .form-check-input {
  margin-right: -2.25em;
}
.form-switch-lg .form-check-label {
  padding-top: calc((1.25em - 0.9375rem) / 2);
}

.form-switch-xl {
  min-height: 1.5em;
}
html:not([dir=rtl]) .form-switch-xl {
  padding-left: 2.5em;
}
*[dir=rtl] .form-switch-xl {
  padding-right: 2.5em;
}
.form-switch-xl .form-check-input {
  width: 2em;
  height: 1.5em;
}
html:not([dir=rtl]) .form-switch-xl .form-check-input {
  margin-left: -2.5em;
}
*[dir=rtl] .form-switch-xl .form-check-input {
  margin-right: -2.5em;
}
.form-switch-xl .form-check-label {
  padding-top: calc((1.5em - 0.9375rem) / 2);
}

.form-check-inline {
  display: inline-block;
}
html:not([dir=rtl]) .form-check-inline {
  margin-right: 1rem;
}
*[dir=rtl] .form-check-inline {
  margin-left: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-check-primary {
  --cui-form-check-input-checked-bg-color: #5046e5;
  --cui-form-check-input-checked-border-color: #5046e5;
  --cui-form-check-input-indeterminate-bg-color: #5046e5;
  --cui-form-check-input-indeterminate-border-color: #5046e5;
}

.form-check-secondary {
  --cui-form-check-input-checked-bg-color: #ebedef;
  --cui-form-check-input-checked-border-color: #ebedef;
  --cui-form-check-input-indeterminate-bg-color: #ebedef;
  --cui-form-check-input-indeterminate-border-color: #ebedef;
}

.form-check-success {
  --cui-form-check-input-checked-bg-color: #51cc8a;
  --cui-form-check-input-checked-border-color: #51cc8a;
  --cui-form-check-input-indeterminate-bg-color: #51cc8a;
  --cui-form-check-input-indeterminate-border-color: #51cc8a;
}

.form-check-info {
  --cui-form-check-input-checked-bg-color: #747af2;
  --cui-form-check-input-checked-border-color: #747af2;
  --cui-form-check-input-indeterminate-bg-color: #747af2;
  --cui-form-check-input-indeterminate-border-color: #747af2;
}

.form-check-warning {
  --cui-form-check-input-checked-bg-color: #fc0;
  --cui-form-check-input-checked-border-color: #fc0;
  --cui-form-check-input-indeterminate-bg-color: #fc0;
  --cui-form-check-input-indeterminate-border-color: #fc0;
}

.form-check-danger {
  --cui-form-check-input-checked-bg-color: #ef376e;
  --cui-form-check-input-checked-border-color: #ef376e;
  --cui-form-check-input-indeterminate-bg-color: #ef376e;
  --cui-form-check-input-indeterminate-border-color: #ef376e;
}

.form-check-light {
  --cui-form-check-input-checked-bg-color: #f0f4f7;
  --cui-form-check-input-checked-border-color: #f0f4f7;
  --cui-form-check-input-indeterminate-bg-color: #f0f4f7;
  --cui-form-check-input-indeterminate-border-color: #f0f4f7;
}

.form-check-dark {
  --cui-form-check-input-checked-bg-color: #636f83;
  --cui-form-check-input-checked-border-color: #636f83;
  --cui-form-check-input-indeterminate-bg-color: #636f83;
  --cui-form-check-input-indeterminate-border-color: #636f83;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--cui-form-range-thumb-bg, #5046e5);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: var(--cui-form-range-thumb-active-bg, #cbc8f7);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--cui-form-range-track-bg, #d8dbe0);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--cui-form-range-thumb-bg, #5046e5);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: var(--cui-form-range-thumb-active-bg, #cbc8f7);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--cui-form-range-track-bg, #d8dbe0);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--cui-form-range-thumb-disabled-bg, #b1b7c1);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--cui-form-range-thumb-disabled-bg, #b1b7c1);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
html:not([dir=rtl]) .form-floating > label {
  left: 0;
}
*[dir=rtl] .form-floating > label {
  right: 0;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-group-addon-color, rgba(44, 56, 74, 0.95));
  text-align: center;
  white-space: nowrap;
  background-color: var(--cui-input-group-addon-bg, #ebedef);
  border: 1px solid var(--cui-input-group-addon-border-color, #c4c9d0);
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

html:not([dir=rtl]) .input-group-lg > .form-select,
html:not([dir=rtl]) .input-group-sm > .form-select {
  padding-right: 3rem;
}
*[dir=rtl] .input-group-lg > .form-select,
*[dir=rtl] .input-group-sm > .form-select {
  padding-left: 3rem;
}

html:not([dir=rtl]) .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
html:not([dir=rtl]) .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
html:not([dir=rtl]) .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
html:not([dir=rtl]) .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
}
*[dir=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
*[dir=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
*[dir=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
*[dir=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-left-radius: 0;
}
html:not([dir=rtl]) .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
html:not([dir=rtl]) .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
html:not([dir=rtl]) .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
html:not([dir=rtl]) .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-bottom-right-radius: 0;
}
*[dir=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
*[dir=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
*[dir=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
*[dir=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-bottom-left-radius: 0;
}
html:not([dir=rtl]) .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
html:not([dir=rtl]) .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
html:not([dir=rtl]) .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
html:not([dir=rtl]) .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
}
*[dir=rtl] .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
*[dir=rtl] .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
*[dir=rtl] .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
*[dir=rtl] .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-left-radius: 0;
}
html:not([dir=rtl]) .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
html:not([dir=rtl]) .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
html:not([dir=rtl]) .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
html:not([dir=rtl]) .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-bottom-right-radius: 0;
}
*[dir=rtl] .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
*[dir=rtl] .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
*[dir=rtl] .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
*[dir=rtl] .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-bottom-left-radius: 0;
}
html:not([dir=rtl]) .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
}
*[dir=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: -1px;
}
html:not([dir=rtl]) .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
}
*[dir=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-right-radius: 0;
}
html:not([dir=rtl]) .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0;
}
*[dir=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-right-radius: 0;
}
html:not([dir=rtl]) .input-group > .form-floating:not(:first-child) > .form-control,
html:not([dir=rtl]) .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
}
*[dir=rtl] .input-group > .form-floating:not(:first-child) > .form-control,
*[dir=rtl] .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-right-radius: 0;
}
html:not([dir=rtl]) .input-group > .form-floating:not(:first-child) > .form-control,
html:not([dir=rtl]) .input-group > .form-floating:not(:first-child) > .form-select {
  border-bottom-left-radius: 0;
}
*[dir=rtl] .input-group > .form-floating:not(:first-child) > .form-control,
*[dir=rtl] .input-group > .form-floating:not(:first-child) > .form-select {
  border-bottom-right-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #51cc8a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: rgba(44, 56, 74, 0.95);
  background-color: rgba(81, 204, 138, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #51cc8a;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2351cc8a' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
html:not([dir=rtl]) .was-validated .form-control:valid, html:not([dir=rtl]) .form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
}
*[dir=rtl] .was-validated .form-control:valid, *[dir=rtl] .form-control.is-valid {
  padding-left: calc(1.5em + 0.75rem);
}
html:not([dir=rtl]) .was-validated .form-control:valid, html:not([dir=rtl]) .form-control.is-valid {
  background-position: right calc(0.375em + 0.1875rem) center;
}
*[dir=rtl] .was-validated .form-control:valid, *[dir=rtl] .form-control.is-valid {
  background-position: left calc(0.375em + 0.1875rem) center;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #51cc8a;
  box-shadow: 0 0 0 0.25rem rgba(81, 204, 138, 0.25);
}

html:not([dir=rtl]) .was-validated textarea.form-control:valid, html:not([dir=rtl]) textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
}
*[dir=rtl] .was-validated textarea.form-control:valid, *[dir=rtl] textarea.form-control.is-valid {
  padding-left: calc(1.5em + 0.75rem);
}
html:not([dir=rtl]) .was-validated textarea.form-control:valid, html:not([dir=rtl]) textarea.form-control.is-valid {
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
*[dir=rtl] .was-validated textarea.form-control:valid, *[dir=rtl] textarea.form-control.is-valid {
  background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #51cc8a;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23768192' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2351cc8a' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
html:not([dir=rtl]) .was-validated .form-select:valid:not([multiple]):not([size]), html:not([dir=rtl]) .was-validated .form-select:valid:not([multiple])[size="1"], html:not([dir=rtl]) .form-select.is-valid:not([multiple]):not([size]), html:not([dir=rtl]) .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
}
*[dir=rtl] .was-validated .form-select:valid:not([multiple]):not([size]), *[dir=rtl] .was-validated .form-select:valid:not([multiple])[size="1"], *[dir=rtl] .form-select.is-valid:not([multiple]):not([size]), *[dir=rtl] .form-select.is-valid:not([multiple])[size="1"] {
  padding-left: 4.125rem;
}
html:not([dir=rtl]) .was-validated .form-select:valid:not([multiple]):not([size]), html:not([dir=rtl]) .was-validated .form-select:valid:not([multiple])[size="1"], html:not([dir=rtl]) .form-select.is-valid:not([multiple]):not([size]), html:not([dir=rtl]) .form-select.is-valid:not([multiple])[size="1"] {
  background-position: right 0.75rem center, center right 2.25rem;
}
*[dir=rtl] .was-validated .form-select:valid:not([multiple]):not([size]), *[dir=rtl] .was-validated .form-select:valid:not([multiple])[size="1"], *[dir=rtl] .form-select.is-valid:not([multiple]):not([size]), *[dir=rtl] .form-select.is-valid:not([multiple])[size="1"] {
  background-position: left 0.75rem center, center left 2.25rem;
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #51cc8a;
  box-shadow: 0 0 0 0.25rem rgba(81, 204, 138, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #51cc8a;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #51cc8a;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(81, 204, 138, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #51cc8a;
}

html:not([dir=rtl]) .form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
*[dir=rtl] .form-check-inline .form-check-input ~ .valid-feedback {
  margin-right: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ef376e;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000015;
  background-color: rgba(239, 55, 110, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef376e;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ef376e'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef376e' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
html:not([dir=rtl]) .was-validated .form-control:invalid, html:not([dir=rtl]) .form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
}
*[dir=rtl] .was-validated .form-control:invalid, *[dir=rtl] .form-control.is-invalid {
  padding-left: calc(1.5em + 0.75rem);
}
html:not([dir=rtl]) .was-validated .form-control:invalid, html:not([dir=rtl]) .form-control.is-invalid {
  background-position: right calc(0.375em + 0.1875rem) center;
}
*[dir=rtl] .was-validated .form-control:invalid, *[dir=rtl] .form-control.is-invalid {
  background-position: left calc(0.375em + 0.1875rem) center;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ef376e;
  box-shadow: 0 0 0 0.25rem rgba(239, 55, 110, 0.25);
}

html:not([dir=rtl]) .was-validated textarea.form-control:invalid, html:not([dir=rtl]) textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
}
*[dir=rtl] .was-validated textarea.form-control:invalid, *[dir=rtl] textarea.form-control.is-invalid {
  padding-left: calc(1.5em + 0.75rem);
}
html:not([dir=rtl]) .was-validated textarea.form-control:invalid, html:not([dir=rtl]) textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
*[dir=rtl] .was-validated textarea.form-control:invalid, *[dir=rtl] textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ef376e;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23768192' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ef376e'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef376e' stroke='none'/%3e%3c/svg%3e");
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
html:not([dir=rtl]) .was-validated .form-select:invalid:not([multiple]):not([size]), html:not([dir=rtl]) .was-validated .form-select:invalid:not([multiple])[size="1"], html:not([dir=rtl]) .form-select.is-invalid:not([multiple]):not([size]), html:not([dir=rtl]) .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
}
*[dir=rtl] .was-validated .form-select:invalid:not([multiple]):not([size]), *[dir=rtl] .was-validated .form-select:invalid:not([multiple])[size="1"], *[dir=rtl] .form-select.is-invalid:not([multiple]):not([size]), *[dir=rtl] .form-select.is-invalid:not([multiple])[size="1"] {
  padding-left: 4.125rem;
}
html:not([dir=rtl]) .was-validated .form-select:invalid:not([multiple]):not([size]), html:not([dir=rtl]) .was-validated .form-select:invalid:not([multiple])[size="1"], html:not([dir=rtl]) .form-select.is-invalid:not([multiple]):not([size]), html:not([dir=rtl]) .form-select.is-invalid:not([multiple])[size="1"] {
  background-position: right 0.75rem center, center right 2.25rem;
}
*[dir=rtl] .was-validated .form-select:invalid:not([multiple]):not([size]), *[dir=rtl] .was-validated .form-select:invalid:not([multiple])[size="1"], *[dir=rtl] .form-select.is-invalid:not([multiple]):not([size]), *[dir=rtl] .form-select.is-invalid:not([multiple])[size="1"] {
  background-position: left 0.75rem center, center left 2.25rem;
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ef376e;
  box-shadow: 0 0 0 0.25rem rgba(239, 55, 110, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ef376e;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ef376e;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(239, 55, 110, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef376e;
}

html:not([dir=rtl]) .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
*[dir=rtl] .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-right: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --cui-btn-padding-x: 0.75rem;
  --cui-btn-padding-y: 0.375rem;
  --cui-btn-font-family: ;
  --cui-btn-font-size: 1rem;
  --cui-btn-font-weight: 400;
  --cui-btn-line-height: 1.5;
  --cui-btn-color: rgba(44, 56, 74, 0.95);
  --cui-btn-bg: transparent;
  --cui-btn-border-width: 1px;
  --cui-btn-border-color: transparent;
  --cui-btn-border-radius: 0.375rem;
  --cui-btn-hover-border-color: transparent;
  --cui-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 21, 0.075);
  --cui-btn-disabled-opacity: 0.65;
  --cui-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--cui-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--cui-btn-padding-y) var(--cui-btn-padding-x);
  font-family: var(--cui-btn-font-family);
  font-size: var(--cui-btn-font-size);
  font-weight: var(--cui-btn-font-weight);
  line-height: var(--cui-btn-line-height);
  color: var(--cui-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--cui-btn-border-width) solid var(--cui-btn-border-color);
  border-radius: var(--cui-btn-border-radius);
  background-color: var(--cui-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--cui-btn-hover-color);
  background-color: var(--cui-btn-hover-bg);
  border-color: var(--cui-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--cui-btn-color);
  background-color: var(--cui-btn-bg);
  border-color: var(--cui-btn-border-color);
}
.btn:focus-visible {
  color: var(--cui-btn-hover-color);
  background-color: var(--cui-btn-hover-bg);
  border-color: var(--cui-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--cui-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--cui-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--cui-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--cui-btn-active-color);
  background-color: var(--cui-btn-active-bg);
  border-color: var(--cui-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--cui-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--cui-btn-disabled-color);
  pointer-events: none;
  background-color: var(--cui-btn-disabled-bg);
  border-color: var(--cui-btn-disabled-border-color);
  opacity: var(--cui-btn-disabled-opacity);
}

.btn-transparent {
  --cui-btn-active-border-color: transparent;
  --cui-btn-disabled-border-color: transparent;
  --cui-btn-hover-border-color: transparent;
}

.btn-primary {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #5046e5;
  --cui-btn-border-color: #5046e5;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #6a62e9;
  --cui-btn-hover-border-color: #6259e8;
  --cui-btn-focus-shadow-rgb: 101, 92, 232;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #736bea;
  --cui-btn-active-border-color: #6259e8;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #5046e5;
  --cui-btn-disabled-border-color: #5046e5;
}

.btn-secondary {
  --cui-btn-color: rgba(44, 56, 74, 0.95);
  --cui-btn-bg: #ebedef;
  --cui-btn-border-color: #ebedef;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-hover-bg: #c8c9cb;
  --cui-btn-hover-border-color: #bcbebf;
  --cui-btn-focus-shadow-rgb: 209, 212, 216;
  --cui-btn-active-color: rgba(44, 56, 74, 0.95);
  --cui-btn-active-bg: #bcbebf;
  --cui-btn-active-border-color: #b0b2b3;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(44, 56, 74, 0.95);
  --cui-btn-disabled-bg: #ebedef;
  --cui-btn-disabled-border-color: #ebedef;
}

.btn-success {
  --cui-btn-color: rgba(44, 56, 74, 0.95);
  --cui-btn-bg: #51cc8a;
  --cui-btn-border-color: #51cc8a;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #45ad75;
  --cui-btn-hover-border-color: #41a36e;
  --cui-btn-focus-shadow-rgb: 76, 184, 129;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #41a36e;
  --cui-btn-active-border-color: #3d9968;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(44, 56, 74, 0.95);
  --cui-btn-disabled-bg: #51cc8a;
  --cui-btn-disabled-border-color: #51cc8a;
}

.btn-danger {
  --cui-btn-color: #000015;
  --cui-btn-bg: #ef376e;
  --cui-btn-border-color: #ef376e;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #f15584;
  --cui-btn-hover-border-color: #f14b7d;
  --cui-btn-focus-shadow-rgb: 203, 47, 97;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #f25f8b;
  --cui-btn-active-border-color: #f14b7d;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #000015;
  --cui-btn-disabled-bg: #ef376e;
  --cui-btn-disabled-border-color: #ef376e;
}

.btn-warning {
  --cui-btn-color: rgba(44, 56, 74, 0.95);
  --cui-btn-bg: #fc0;
  --cui-btn-border-color: #fc0;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-hover-bg: #d9ad00;
  --cui-btn-hover-border-color: #cca300;
  --cui-btn-focus-shadow-rgb: 226, 184, 10;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #cca300;
  --cui-btn-active-border-color: #bf9900;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(44, 56, 74, 0.95);
  --cui-btn-disabled-bg: #fc0;
  --cui-btn-disabled-border-color: #fc0;
}

.btn-info {
  --cui-btn-color: #000015;
  --cui-btn-bg: #747af2;
  --cui-btn-border-color: #747af2;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #898ef4;
  --cui-btn-hover-border-color: #8287f3;
  --cui-btn-focus-shadow-rgb: 99, 104, 209;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #9095f5;
  --cui-btn-active-border-color: #8287f3;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #000015;
  --cui-btn-disabled-bg: #747af2;
  --cui-btn-disabled-border-color: #747af2;
}

.btn-light {
  --cui-btn-color: rgba(44, 56, 74, 0.95);
  --cui-btn-bg: #f0f4f7;
  --cui-btn-border-color: #f0f4f7;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-hover-bg: #cccfd2;
  --cui-btn-hover-border-color: #c0c3c6;
  --cui-btn-focus-shadow-rgb: 213, 218, 223;
  --cui-btn-active-color: rgba(44, 56, 74, 0.95);
  --cui-btn-active-bg: #c0c3c6;
  --cui-btn-active-border-color: #b4b7b9;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(44, 56, 74, 0.95);
  --cui-btn-disabled-bg: #f0f4f7;
  --cui-btn-disabled-border-color: #f0f4f7;
}

.btn-dark {
  --cui-btn-color: #fff;
  --cui-btn-bg: #636f83;
  --cui-btn-border-color: #636f83;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #7a8596;
  --cui-btn-hover-border-color: #737d8f;
  --cui-btn-focus-shadow-rgb: 122, 133, 150;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #828c9c;
  --cui-btn-active-border-color: #737d8f;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #fff;
  --cui-btn-disabled-bg: #636f83;
  --cui-btn-disabled-border-color: #636f83;
}

.btn-outline-primary {
  --cui-btn-color: #5046e5;
  --cui-btn-border-color: #5046e5;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #736bea;
  --cui-btn-hover-border-color: #6259e8;
  --cui-btn-focus-shadow-rgb: 80, 70, 229;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #736bea;
  --cui-btn-active-border-color: #6259e8;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #5046e5;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-outline-secondary {
  --cui-btn-color: #ebedef;
  --cui-btn-border-color: #ebedef;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-hover-bg: #bcbebf;
  --cui-btn-hover-border-color: #b0b2b3;
  --cui-btn-focus-shadow-rgb: 235, 237, 239;
  --cui-btn-active-color: rgba(44, 56, 74, 0.95);
  --cui-btn-active-bg: #bcbebf;
  --cui-btn-active-border-color: #b0b2b3;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #ebedef;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-outline-success {
  --cui-btn-color: #51cc8a;
  --cui-btn-border-color: #51cc8a;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #41a36e;
  --cui-btn-hover-border-color: #3d9968;
  --cui-btn-focus-shadow-rgb: 81, 204, 138;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #41a36e;
  --cui-btn-active-border-color: #3d9968;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #51cc8a;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-outline-danger {
  --cui-btn-color: #ef376e;
  --cui-btn-border-color: #ef376e;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #f25f8b;
  --cui-btn-hover-border-color: #f14b7d;
  --cui-btn-focus-shadow-rgb: 239, 55, 110;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #f25f8b;
  --cui-btn-active-border-color: #f14b7d;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #ef376e;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-outline-warning {
  --cui-btn-color: #fc0;
  --cui-btn-border-color: #fc0;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-hover-bg: #cca300;
  --cui-btn-hover-border-color: #bf9900;
  --cui-btn-focus-shadow-rgb: 255, 204, 0;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #cca300;
  --cui-btn-active-border-color: #bf9900;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #fc0;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-outline-info {
  --cui-btn-color: #747af2;
  --cui-btn-border-color: #747af2;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #9095f5;
  --cui-btn-hover-border-color: #8287f3;
  --cui-btn-focus-shadow-rgb: 116, 122, 242;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #9095f5;
  --cui-btn-active-border-color: #8287f3;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #747af2;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-outline-light {
  --cui-btn-color: #f0f4f7;
  --cui-btn-border-color: #f0f4f7;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-hover-bg: #c0c3c6;
  --cui-btn-hover-border-color: #b4b7b9;
  --cui-btn-focus-shadow-rgb: 240, 244, 247;
  --cui-btn-active-color: rgba(44, 56, 74, 0.95);
  --cui-btn-active-bg: #c0c3c6;
  --cui-btn-active-border-color: #b4b7b9;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #f0f4f7;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-outline-dark {
  --cui-btn-color: #636f83;
  --cui-btn-border-color: #636f83;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #828c9c;
  --cui-btn-hover-border-color: #737d8f;
  --cui-btn-focus-shadow-rgb: 99, 111, 131;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #828c9c;
  --cui-btn-active-border-color: #737d8f;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #636f83;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-ghost-primary {
  --cui-btn-color: #5046e5;
  --cui-btn-hover-bg: #6a62e9;
  --cui-btn-hover-border-color: #6259e8;
  --cui-btn-hover-color: #fff;
  --cui-btn-active-bg: #736bea;
  --cui-btn-active-border-color: #6259e8;
  --cui-btn-active-color: #fff;
  --cui-btn-disabled-color: #5046e5;
  --cui-btn-shadow: rgba(80, 70, 229, 0.5);
}

.btn-ghost-secondary {
  --cui-btn-color: #ebedef;
  --cui-btn-hover-bg: #c8c9cb;
  --cui-btn-hover-border-color: #bcbebf;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-active-bg: #bcbebf;
  --cui-btn-active-border-color: #b0b2b3;
  --cui-btn-active-color: rgba(44, 56, 74, 0.95);
  --cui-btn-disabled-color: #ebedef;
  --cui-btn-shadow: rgba(235, 237, 239, 0.5);
}

.btn-ghost-success {
  --cui-btn-color: #51cc8a;
  --cui-btn-hover-bg: #45ad75;
  --cui-btn-hover-border-color: #41a36e;
  --cui-btn-hover-color: #000015;
  --cui-btn-active-bg: #41a36e;
  --cui-btn-active-border-color: #3d9968;
  --cui-btn-active-color: #000015;
  --cui-btn-disabled-color: #51cc8a;
  --cui-btn-shadow: rgba(81, 204, 138, 0.5);
}

.btn-ghost-danger {
  --cui-btn-color: #ef376e;
  --cui-btn-hover-bg: #f15584;
  --cui-btn-hover-border-color: #f14b7d;
  --cui-btn-hover-color: #000015;
  --cui-btn-active-bg: #f25f8b;
  --cui-btn-active-border-color: #f14b7d;
  --cui-btn-active-color: #000015;
  --cui-btn-disabled-color: #ef376e;
  --cui-btn-shadow: rgba(239, 55, 110, 0.5);
}

.btn-ghost-warning {
  --cui-btn-color: #fc0;
  --cui-btn-hover-bg: #d9ad00;
  --cui-btn-hover-border-color: #cca300;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-active-bg: #cca300;
  --cui-btn-active-border-color: #bf9900;
  --cui-btn-active-color: #000015;
  --cui-btn-disabled-color: #fc0;
  --cui-btn-shadow: rgba(255, 204, 0, 0.5);
}

.btn-ghost-info {
  --cui-btn-color: #747af2;
  --cui-btn-hover-bg: #898ef4;
  --cui-btn-hover-border-color: #8287f3;
  --cui-btn-hover-color: #000015;
  --cui-btn-active-bg: #9095f5;
  --cui-btn-active-border-color: #8287f3;
  --cui-btn-active-color: #000015;
  --cui-btn-disabled-color: #747af2;
  --cui-btn-shadow: rgba(116, 122, 242, 0.5);
}

.btn-ghost-light {
  --cui-btn-color: #f0f4f7;
  --cui-btn-hover-bg: #cccfd2;
  --cui-btn-hover-border-color: #c0c3c6;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-active-bg: #c0c3c6;
  --cui-btn-active-border-color: #b4b7b9;
  --cui-btn-active-color: rgba(44, 56, 74, 0.95);
  --cui-btn-disabled-color: #f0f4f7;
  --cui-btn-shadow: rgba(240, 244, 247, 0.5);
}

.btn-ghost-dark {
  --cui-btn-color: #636f83;
  --cui-btn-hover-bg: #7a8596;
  --cui-btn-hover-border-color: #737d8f;
  --cui-btn-hover-color: #000015;
  --cui-btn-active-bg: #828c9c;
  --cui-btn-active-border-color: #737d8f;
  --cui-btn-active-color: #000015;
  --cui-btn-disabled-color: #636f83;
  --cui-btn-shadow: rgba(99, 111, 131, 0.5);
}

.btn-link {
  --cui-btn-font-weight: 400;
  --cui-btn-color: var(--cui-link-color);
  --cui-btn-bg: transparent;
  --cui-btn-border-color: transparent;
  --cui-btn-hover-color: var(--cui-link-hover-color);
  --cui-btn-hover-border-color: transparent;
  --cui-btn-active-border-color: transparent;
  --cui-btn-disabled-color: #9da5b1;
  --cui-btn-disabled-border-color: transparent;
  --cui-btn-box-shadow: none;
  --cui-btn-focus-shadow-rgb: 101, 92, 232;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--cui-btn-color);
}

.btn-lg, .btn-group-lg > .btn {
  --cui-btn-padding-y: 0.5rem;
  --cui-btn-padding-x: 1rem;
  --cui-btn-font-size: 1.25rem;
  --cui-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --cui-btn-padding-y: 0.25rem;
  --cui-btn-padding-x: 0.5rem;
  --cui-btn-font-size: 0.875rem;
  --cui-btn-border-radius: 0.25rem;
}

.btn-loading {
  position: relative;
  overflow: hidden;
}

.btn-loading-spinner {
  opacity: 0;
  transition: margin 0.15s, opacity 0.15s, border 0.15s;
}
html:not([dir=rtl]) .btn-loading-spinner {
  margin-right: 1rem;
}
*[dir=rtl] .btn-loading-spinner {
  margin-left: 1rem;
}
html:not([dir=rtl]) .btn-loading-spinner {
  margin-left: -2rem;
}
*[dir=rtl] .btn-loading-spinner {
  margin-right: -2rem;
}
@media (prefers-reduced-motion: reduce) {
  .btn-loading-spinner {
    transition: none;
  }
}

.btn-loading.is-loading .btn-loading-spinner {
  width: 1rem;
  opacity: 1;
}
html:not([dir=rtl]) .btn-loading.is-loading .btn-loading-spinner {
  margin-left: 0;
}
*[dir=rtl] .btn-loading.is-loading .btn-loading-spinner {
  margin-right: 0;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
html:not([dir=rtl]) .dropdown-toggle::after {
  margin-left: 0.255em;
}
*[dir=rtl] .dropdown-toggle::after {
  margin-right: 0.255em;
}
html:not([dir=rtl]) .dropdown-toggle:empty::after {
  margin-left: 0;
}
*[dir=rtl] .dropdown-toggle:empty::after {
  margin-right: 0;
}

.dropdown-menu {
  --cui-dropdown-zindex: 1000;
  --cui-dropdown-min-width: 10rem;
  --cui-dropdown-padding-x: 0;
  --cui-dropdown-padding-y: 0.75rem;
  --cui-dropdown-spacer: 0.125rem;
  --cui-dropdown-font-size: 1rem;
  --cui-dropdown-color: rgba(44, 56, 74, 0.95);
  --cui-dropdown-bg: #fff;
  --cui-dropdown-border-color: var(--cui-border-color-translucent);
  --cui-dropdown-border-radius: 0.375rem;
  --cui-dropdown-border-width: 1px;
  --cui-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --cui-dropdown-divider-bg: var(--cui-border-color-translucent);
  --cui-dropdown-divider-margin-y: 0.5rem;
  --cui-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15);
  --cui-dropdown-link-color: #636f83;
  --cui-dropdown-link-hover-color: #596476;
  --cui-dropdown-link-hover-bg: #f0f4f7;
  --cui-dropdown-link-active-color: rgba(255, 255, 255, 0.87);
  --cui-dropdown-link-active-bg: #5046e5;
  --cui-dropdown-link-disabled-color: #b1b7c1;
  --cui-dropdown-item-padding-x: 1rem;
  --cui-dropdown-item-padding-y: 0.5rem;
  --cui-dropdown-header-color: #9da5b1;
  --cui-dropdown-header-padding-x: 1rem;
  --cui-dropdown-header-padding-y: 0.75rem;
  position: absolute;
  z-index: var(--cui-dropdown-zindex);
  display: none;
  min-width: var(--cui-dropdown-min-width);
  padding: var(--cui-dropdown-padding-y) var(--cui-dropdown-padding-x);
  margin: 0;
  font-size: var(--cui-dropdown-font-size);
  color: var(--cui-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--cui-dropdown-bg);
  background-clip: padding-box;
  border: var(--cui-dropdown-border-width) solid var(--cui-dropdown-border-color);
  border-radius: var(--cui-dropdown-border-radius);
}
.dropdown-menu[data-coreui-popper] {
  top: 100%;
  margin-top: var(--cui-dropdown-spacer);
}
html:not([dir=rtl]) .dropdown-menu[data-coreui-popper] {
  left: 0;
}
*[dir=rtl] .dropdown-menu[data-coreui-popper] {
  right: 0;
}

.dropdown-menu-start {
  --cui-position: start;
}
.dropdown-menu-start[data-coreui-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --cui-position: end;
}
.dropdown-menu-end[data-coreui-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --cui-position: start;
  }
  .dropdown-menu-sm-start[data-coreui-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --cui-position: end;
  }
  .dropdown-menu-sm-end[data-coreui-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --cui-position: start;
  }
  .dropdown-menu-md-start[data-coreui-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --cui-position: end;
  }
  .dropdown-menu-md-end[data-coreui-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --cui-position: start;
  }
  .dropdown-menu-lg-start[data-coreui-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --cui-position: end;
  }
  .dropdown-menu-lg-end[data-coreui-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --cui-position: start;
  }
  .dropdown-menu-xl-start[data-coreui-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --cui-position: end;
  }
  .dropdown-menu-xl-end[data-coreui-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --cui-position: start;
  }
  .dropdown-menu-xxl-start[data-coreui-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --cui-position: end;
  }
  .dropdown-menu-xxl-end[data-coreui-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-coreui-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--cui-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
html:not([dir=rtl]) .dropup .dropdown-toggle::after {
  margin-left: 0.255em;
}
*[dir=rtl] .dropup .dropdown-toggle::after {
  margin-right: 0.255em;
}
html:not([dir=rtl]) .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
*[dir=rtl] .dropup .dropdown-toggle:empty::after {
  margin-right: 0;
}

.dropend .dropdown-menu[data-coreui-popper] {
  top: 0;
  margin-top: 0;
}
html:not([dir=rtl]) .dropend .dropdown-menu[data-coreui-popper] {
  right: auto;
}
*[dir=rtl] .dropend .dropdown-menu[data-coreui-popper] {
  left: auto;
}
html:not([dir=rtl]) .dropend .dropdown-menu[data-coreui-popper] {
  left: 100%;
}
*[dir=rtl] .dropend .dropdown-menu[data-coreui-popper] {
  right: 100%;
}
html:not([dir=rtl]) .dropend .dropdown-menu[data-coreui-popper] {
  margin-left: var(--cui-dropdown-spacer);
}
*[dir=rtl] .dropend .dropdown-menu[data-coreui-popper] {
  margin-right: var(--cui-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
html:not([dir=rtl]) .dropend .dropdown-toggle::after {
  margin-left: 0.255em;
}
*[dir=rtl] .dropend .dropdown-toggle::after {
  margin-right: 0.255em;
}
html:not([dir=rtl]) .dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
*[dir=rtl] .dropend .dropdown-toggle:empty::after {
  margin-right: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-coreui-popper] {
  top: 0;
  margin-top: 0;
}
html:not([dir=rtl]) .dropstart .dropdown-menu[data-coreui-popper] {
  right: 100%;
}
*[dir=rtl] .dropstart .dropdown-menu[data-coreui-popper] {
  left: 100%;
}
html:not([dir=rtl]) .dropstart .dropdown-menu[data-coreui-popper] {
  left: auto;
}
*[dir=rtl] .dropstart .dropdown-menu[data-coreui-popper] {
  right: auto;
}
html:not([dir=rtl]) .dropstart .dropdown-menu[data-coreui-popper] {
  margin-right: var(--cui-dropdown-spacer);
}
*[dir=rtl] .dropstart .dropdown-menu[data-coreui-popper] {
  margin-left: var(--cui-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
}
html:not([dir=rtl]) .dropstart .dropdown-toggle::after {
  margin-left: 0.255em;
}
*[dir=rtl] .dropstart .dropdown-toggle::after {
  margin-right: 0.255em;
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
html:not([dir=rtl]) .dropstart .dropdown-toggle::before {
  margin-right: 0.255em;
}
*[dir=rtl] .dropstart .dropdown-toggle::before {
  margin-left: 0.255em;
}
html:not([dir=rtl]) .dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
*[dir=rtl] .dropstart .dropdown-toggle:empty::after {
  margin-right: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--cui-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--cui-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--cui-dropdown-item-padding-y) var(--cui-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--cui-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--cui-dropdown-link-hover-color);
  background-color: var(--cui-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--cui-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--cui-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--cui-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--cui-dropdown-header-padding-y) var(--cui-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--cui-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--cui-dropdown-item-padding-y) var(--cui-dropdown-item-padding-x);
  color: var(--cui-dropdown-link-color);
}

.dropdown-menu-dark {
  --cui-dropdown-color: #d8dbe0;
  --cui-dropdown-bg: #768192;
  --cui-dropdown-border-color: var(--cui-border-color-translucent);
  --cui-dropdown-box-shadow: ;
  --cui-dropdown-link-color: #d8dbe0;
  --cui-dropdown-link-hover-color: rgba(255, 255, 255, 0.87);
  --cui-dropdown-divider-bg: var(--cui-border-color-translucent);
  --cui-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --cui-dropdown-link-active-color: rgba(255, 255, 255, 0.87);
  --cui-dropdown-link-active-bg: #5046e5;
  --cui-dropdown-link-disabled-color: #b1b7c1;
  --cui-dropdown-header-color: #b1b7c1;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
html:not([dir=rtl]) .btn-group > :not(.btn-check:first-child) + .btn,
html:not([dir=rtl]) .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
*[dir=rtl] .btn-group > :not(.btn-check:first-child) + .btn,
*[dir=rtl] .btn-group > .btn-group:not(:first-child) {
  margin-right: -1px;
}
html:not([dir=rtl]) .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
html:not([dir=rtl]) .btn-group > .btn.dropdown-toggle-split:first-child,
html:not([dir=rtl]) .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
}
*[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
*[dir=rtl] .btn-group > .btn.dropdown-toggle-split:first-child,
*[dir=rtl] .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-left-radius: 0;
}
html:not([dir=rtl]) .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
html:not([dir=rtl]) .btn-group > .btn.dropdown-toggle-split:first-child,
html:not([dir=rtl]) .btn-group > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
}
*[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
*[dir=rtl] .btn-group > .btn.dropdown-toggle-split:first-child,
*[dir=rtl] .btn-group > .btn-group:not(:last-child) > .btn {
  border-bottom-left-radius: 0;
}
html:not([dir=rtl]) .btn-group > .btn:nth-child(n+3),
html:not([dir=rtl]) .btn-group > :not(.btn-check) + .btn,
html:not([dir=rtl]) .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
}
*[dir=rtl] .btn-group > .btn:nth-child(n+3),
*[dir=rtl] .btn-group > :not(.btn-check) + .btn,
*[dir=rtl] .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 0;
}
html:not([dir=rtl]) .btn-group > .btn:nth-child(n+3),
html:not([dir=rtl]) .btn-group > :not(.btn-check) + .btn,
html:not([dir=rtl]) .btn-group > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
}
*[dir=rtl] .btn-group > .btn:nth-child(n+3),
*[dir=rtl] .btn-group > :not(.btn-check) + .btn,
*[dir=rtl] .btn-group > .btn-group:not(:first-child) > .btn {
  border-bottom-right-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
html:not([dir=rtl]) .dropdown-toggle-split::after, html:not([dir=rtl]) .dropup .dropdown-toggle-split::after, html:not([dir=rtl]) .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
*[dir=rtl] .dropdown-toggle-split::after, *[dir=rtl] .dropup .dropdown-toggle-split::after, *[dir=rtl] .dropend .dropdown-toggle-split::after {
  margin-right: 0;
}
html:not([dir=rtl]) .dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
*[dir=rtl] .dropstart .dropdown-toggle-split::before {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --cui-nav-link-padding-x: 1rem;
  --cui-nav-link-padding-y: 0.5rem;
  --cui-nav-link-font-weight: ;
  --cui-nav-link-color: var(--cui-link-color);
  --cui-nav-link-hover-color: var(--cui-link-hover-color);
  --cui-nav-link-disabled-color: #9da5b1;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
}
html:not([dir=rtl]) .nav {
  padding-left: 0;
}
*[dir=rtl] .nav {
  padding-right: 0;
}

.nav-link {
  display: block;
  padding: var(--cui-nav-link-padding-y) var(--cui-nav-link-padding-x);
  font-size: var(--cui-nav-link-font-size);
  font-weight: var(--cui-nav-link-font-weight);
  color: var(--cui-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--cui-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--cui-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --cui-nav-tabs-border-width: 1px;
  --cui-nav-tabs-border-color: #d8dbe0;
  --cui-nav-tabs-border-radius: 0.375rem;
  --cui-nav-tabs-link-hover-border-color: #ebedef #ebedef #d8dbe0;
  --cui-nav-tabs-link-active-color: #8a93a2;
  --cui-nav-tabs-link-active-bg: #fff;
  --cui-nav-tabs-link-active-border-color: #d8dbe0 #d8dbe0 #fff;
  border-bottom: var(--cui-nav-tabs-border-width) solid var(--cui-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--cui-nav-tabs-border-width));
  background: none;
  border: var(--cui-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--cui-nav-tabs-border-radius);
  border-top-right-radius: var(--cui-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--cui-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--cui-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--cui-nav-tabs-link-active-color);
  background-color: var(--cui-nav-tabs-link-active-bg);
  border-color: var(--cui-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--cui-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --cui-nav-pills-border-radius: 0.375rem;
  --cui-nav-pills-link-active-color: rgba(255, 255, 255, 0.87);
  --cui-nav-pills-link-active-bg: #5046e5;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--cui-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--cui-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--cui-nav-pills-link-active-color);
  background-color: var(--cui-nav-pills-link-active-bg);
}

.nav-underline {
  border-bottom: 2px solid var(--cui-nav-underline-border-color, #d8dbe0);
}
.nav-underline .nav-item {
  margin-bottom: -2px;
}
.nav-underline .nav-link {
  color: var(--cui-nav-underline-link-color, #8a93a2);
  border-bottom: 2px solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-color: var(--cui-nav-underline-link-active-border-color, #5046e5);
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  color: var(--cui-nav-underline-link-active-color, #5046e5);
  background: transparent;
  border-color: var(--cui-nav-underline-link-active-border-color, #5046e5);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --cui-navbar-padding-x: 0;
  --cui-navbar-padding-y: 0.5rem;
  --cui-navbar-color: rgba(44, 56, 74, 0.681);
  --cui-navbar-hover-color: rgba(44, 56, 74, 0.95);
  --cui-navbar-disabled-color: rgba(44, 56, 74, 0.38);
  --cui-navbar-active-color: rgba(44, 56, 74, 0.95);
  --cui-navbar-brand-padding-y: 0.3125rem;
  --cui-navbar-brand-margin-end: 1rem;
  --cui-navbar-brand-font-size: 1.25rem;
  --cui-navbar-brand-color: rgba(44, 56, 74, 0.95);
  --cui-navbar-brand-hover-color: rgba(44, 56, 74, 0.95);
  --cui-navbar-nav-link-padding-x: 0.5rem;
  --cui-navbar-toggler-padding-y: 0.25rem;
  --cui-navbar-toggler-padding-x: 0.75rem;
  --cui-navbar-toggler-font-size: 1.25rem;
  --cui-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2844, 56, 74, 0.681%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --cui-navbar-toggler-border-color: rgba(0, 0, 21, 0.1);
  --cui-navbar-toggler-border-radius: 0.375rem;
  --cui-navbar-toggler-focus-width: 0.25rem;
  --cui-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--cui-navbar-padding-y) var(--cui-navbar-padding-x);
}
.navbar .header > .container,
.navbar .header > .container-fluid,
.navbar .header > .container-sm,
.navbar .header > .container-md,
.navbar .header > .container-lg,
.navbar .header > .container-xl,
.navbar .header > .container-xxl, .navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--cui-navbar-brand-padding-y);
  padding-bottom: var(--cui-navbar-brand-padding-y);
  font-size: var(--cui-navbar-brand-font-size);
  color: var(--cui-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
html:not([dir=rtl]) .navbar-brand {
  margin-right: var(--cui-navbar-brand-margin-end);
}
*[dir=rtl] .navbar-brand {
  margin-left: var(--cui-navbar-brand-margin-end);
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--cui-navbar-brand-hover-color);
}

.navbar-nav {
  --cui-nav-link-padding-x: 0;
  --cui-nav-link-padding-y: 0.5rem;
  --cui-nav-link-color: var(--cui-navbar-color);
  --cui-nav-link-hover-color: var(--cui-navbar-hover-color);
  --cui-nav-link-disabled-color: var(--cui-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  list-style: none;
}
html:not([dir=rtl]) .navbar-nav {
  padding-left: 0;
}
*[dir=rtl] .navbar-nav {
  padding-right: 0;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--cui-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--cui-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--cui-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--cui-navbar-toggler-padding-y) var(--cui-navbar-toggler-padding-x);
  font-size: var(--cui-navbar-toggler-font-size);
  line-height: 1;
  color: var(--cui-navbar-color);
  background-color: transparent;
  border: var(--cui-border-width) solid var(--cui-navbar-toggler-border-color);
  border-radius: var(--cui-navbar-toggler-border-radius);
  transition: var(--cui-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--cui-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--cui-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--cui-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--cui-navbar-nav-link-padding-x);
    padding-left: var(--cui-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--cui-navbar-nav-link-padding-x);
    padding-left: var(--cui-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--cui-navbar-nav-link-padding-x);
    padding-left: var(--cui-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--cui-navbar-nav-link-padding-x);
    padding-left: var(--cui-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--cui-navbar-nav-link-padding-x);
    padding-left: var(--cui-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--cui-navbar-nav-link-padding-x);
  padding-left: var(--cui-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --cui-navbar-color: rgba(255, 255, 255, 0.6);
  --cui-navbar-hover-color: rgba(255, 255, 255, 0.87);
  --cui-navbar-disabled-color: rgba(255, 255, 255, 0.38);
  --cui-navbar-active-color: rgba(255, 255, 255, 0.87);
  --cui-navbar-brand-color: rgba(255, 255, 255, 0.87);
  --cui-navbar-brand-hover-color: rgba(255, 255, 255, 0.87);
  --cui-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --cui-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.6%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --cui-card-spacer-y: 1rem;
  --cui-card-spacer-x: 1rem;
  --cui-card-title-spacer-y: 0.5rem;
  --cui-card-border-width: 0;
  --cui-card-border-color: var(--cui-border-color-translucent);
  --cui-card-border-radius: 0.375rem;
  --cui-card-box-shadow: ;
  --cui-card-inner-border-radius: calc(0.375rem - 1px);
  --cui-card-cap-padding-y: 0.75rem;
  --cui-card-cap-padding-x: 1rem;
  --cui-card-cap-bg: transparent;
  --cui-card-cap-color: unset;
  --cui-card-height: ;
  --cui-card-color: unset;
  --cui-card-bg: #fff;
  --cui-card-img-overlay-padding: 1rem;
  --cui-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--cui-card-height);
  word-wrap: break-word;
  background-color: var(--cui-card-bg);
  background-clip: border-box;
  border: var(--cui-card-border-width) solid var(--cui-card-border-color);
  border-radius: var(--cui-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--cui-card-inner-border-radius);
  border-top-right-radius: var(--cui-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--cui-card-inner-border-radius);
  border-bottom-left-radius: var(--cui-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--cui-card-spacer-y) var(--cui-card-spacer-x);
  color: var(--cui-card-color);
}

.card-title {
  margin-bottom: var(--cui-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--cui-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

html:not([dir=rtl]) .card-link + .card-link {
  margin-left: var(--cui-card-spacer-x);
}
*[dir=rtl] .card-link + .card-link {
  margin-right: var(--cui-card-spacer-x);
}

.card-header {
  padding: var(--cui-card-cap-padding-y) var(--cui-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--cui-card-cap-color);
  background-color: var(--cui-card-cap-bg);
  border-bottom: var(--cui-card-border-width) solid var(--cui-card-border-color);
}
.card-header:first-child {
  border-radius: var(--cui-card-inner-border-radius) var(--cui-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--cui-card-cap-padding-y) var(--cui-card-cap-padding-x);
  color: var(--cui-card-cap-color);
  background-color: var(--cui-card-cap-bg);
  border-top: var(--cui-card-border-width) solid var(--cui-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--cui-card-inner-border-radius) var(--cui-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--cui-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--cui-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--cui-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--cui-card-bg);
  border-bottom-color: var(--cui-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--cui-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--cui-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--cui-card-img-overlay-padding);
  border-radius: var(--cui-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--cui-card-inner-border-radius);
  border-top-right-radius: var(--cui-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--cui-card-inner-border-radius);
  border-bottom-left-radius: var(--cui-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--cui-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  html:not([dir=rtl]) .card-group > .card + .card {
    margin-left: 0;
  }
  *[dir=rtl] .card-group > .card + .card {
    margin-right: 0;
  }
  html:not([dir=rtl]) .card-group > .card + .card {
    border-left: 0;
  }
  *[dir=rtl] .card-group > .card + .card {
    border-right: 0;
  }
  html:not([dir=rtl]) .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
  }
  *[dir=rtl] .card-group > .card:not(:last-child) {
    border-top-left-radius: 0;
  }
  html:not([dir=rtl]) .card-group > .card:not(:last-child) {
    border-bottom-right-radius: 0;
  }
  *[dir=rtl] .card-group > .card:not(:last-child) {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  html:not([dir=rtl]) .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
  }
  *[dir=rtl] .card-group > .card:not(:first-child) {
    border-top-right-radius: 0;
  }
  html:not([dir=rtl]) .card-group > .card:not(:first-child) {
    border-bottom-left-radius: 0;
  }
  *[dir=rtl] .card-group > .card:not(:first-child) {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --cui-accordion-color: rgba(44, 56, 74, 0.95);
  --cui-accordion-bg: #fff;
  --cui-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --cui-accordion-border-color: var(--cui-border-color);
  --cui-accordion-border-width: 1px;
  --cui-accordion-border-radius: 0.375rem;
  --cui-accordion-inner-border-radius: calc(0.375rem - 1px);
  --cui-accordion-btn-padding-x: 1.25rem;
  --cui-accordion-btn-padding-y: 1rem;
  --cui-accordion-btn-color: rgba(44, 56, 74, 0.95);
  --cui-accordion-btn-color: rgba(44, 56, 74, 0.95);
  --cui-accordion-btn-bg: var(--cui-accordion-bg);
  --cui-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%2844, 56, 74, 0.95%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --cui-accordion-btn-icon-width: 1.25rem;
  --cui-accordion-btn-icon-transform: rotate(-180deg);
  --cui-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --cui-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23483fce'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --cui-accordion-btn-focus-border-color: #a8a3f2;
  --cui-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
  --cui-accordion-body-padding-x: 1.25rem;
  --cui-accordion-body-padding-y: 1rem;
  --cui-accordion-active-color: #483fce;
  --cui-accordion-active-bg: #eeedfc;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--cui-accordion-btn-padding-y) var(--cui-accordion-btn-padding-x);
  font-size: 0.9375rem;
  color: var(--cui-accordion-btn-color);
  text-align: left;
  background-color: var(--cui-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--cui-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--cui-accordion-active-color);
  background-color: var(--cui-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--cui-accordion-border-width)) 0 var(--cui-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--cui-accordion-btn-active-icon);
  transform: var(--cui-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--cui-accordion-btn-icon-width);
  height: var(--cui-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--cui-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--cui-accordion-btn-icon-width);
  transition: var(--cui-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--cui-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--cui-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--cui-accordion-color);
  background-color: var(--cui-accordion-bg);
  border: var(--cui-accordion-border-width) solid var(--cui-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--cui-accordion-border-radius);
  border-top-right-radius: var(--cui-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--cui-accordion-inner-border-radius);
  border-top-right-radius: var(--cui-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--cui-accordion-border-radius);
  border-bottom-left-radius: var(--cui-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--cui-accordion-inner-border-radius);
  border-bottom-left-radius: var(--cui-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--cui-accordion-border-radius);
  border-bottom-left-radius: var(--cui-accordion-border-radius);
}

.accordion-body {
  padding: var(--cui-accordion-body-padding-y) var(--cui-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --cui-breadcrumb-padding-x: 0;
  --cui-breadcrumb-padding-y: 0;
  --cui-breadcrumb-margin-bottom: 1rem;
  --cui-breadcrumb-bg: unset;
  --cui-breadcrumb-border-radius: ;
  --cui-breadcrumb-divider-color: rgba(44, 56, 74, 0.38);
  --cui-breadcrumb-item-padding-x: 0.5rem;
  --cui-breadcrumb-item-active-color: rgba(44, 56, 74, 0.38);
  display: flex;
  flex-wrap: wrap;
  padding: var(--cui-breadcrumb-padding-y) var(--cui-breadcrumb-padding-x);
  margin-bottom: var(--cui-breadcrumb-margin-bottom);
  font-size: var(--cui-breadcrumb-font-size);
  list-style: none;
  background-color: var(--cui-breadcrumb-bg);
  border-radius: var(--cui-breadcrumb-border-radius);
}

html:not([dir=rtl]) .breadcrumb-item + .breadcrumb-item {
  padding-left: var(--cui-breadcrumb-item-padding-x);
}
*[dir=rtl] .breadcrumb-item + .breadcrumb-item {
  padding-right: var(--cui-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  color: var(--cui-breadcrumb-divider-color);
}
html:not([dir=rtl]) .breadcrumb-item + .breadcrumb-item::before {
  float: left;
}
*[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
  float: right;
}
html:not([dir=rtl]) .breadcrumb-item + .breadcrumb-item::before {
  padding-right: var(--cui-breadcrumb-item-padding-x);
}
*[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
  padding-left: var(--cui-breadcrumb-item-padding-x);
}
html:not([dir=rtl]) .breadcrumb-item + .breadcrumb-item::before {
  content: var(--cui-breadcrumb-divider, "/");
}
*[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
  content: var(--cui-breadcrumb-divider-flipped, "/");
}
.breadcrumb-item.active {
  color: var(--cui-breadcrumb-item-active-color);
}

.pagination {
  --cui-pagination-padding-x: 0.75rem;
  --cui-pagination-padding-y: 0.375rem;
  --cui-pagination-font-size: 1rem;
  --cui-pagination-color: var(--cui-link-color);
  --cui-pagination-bg: #fff;
  --cui-pagination-border-width: 1px;
  --cui-pagination-border-color: #d8dbe0;
  --cui-pagination-border-radius: 0.375rem;
  --cui-pagination-hover-color: var(--cui-link-hover-color);
  --cui-pagination-hover-bg: #ebedef;
  --cui-pagination-hover-border-color: #d8dbe0;
  --cui-pagination-focus-color: var(--cui-link-hover-color);
  --cui-pagination-focus-bg: #ebedef;
  --cui-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
  --cui-pagination-active-color: rgba(255, 255, 255, 0.87);
  --cui-pagination-active-bg: #5046e5;
  --cui-pagination-active-border-color: #5046e5;
  --cui-pagination-disabled-color: #9da5b1;
  --cui-pagination-disabled-bg: #fff;
  --cui-pagination-disabled-border-color: #d8dbe0;
  display: flex;
  list-style: none;
}
html:not([dir=rtl]) .pagination {
  padding-left: 0;
}
*[dir=rtl] .pagination {
  padding-right: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--cui-pagination-padding-y) var(--cui-pagination-padding-x);
  font-size: var(--cui-pagination-font-size);
  color: var(--cui-pagination-color);
  text-decoration: none;
  background-color: var(--cui-pagination-bg);
  border: var(--cui-pagination-border-width) solid var(--cui-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--cui-pagination-hover-color);
  background-color: var(--cui-pagination-hover-bg);
  border-color: var(--cui-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--cui-pagination-focus-color);
  background-color: var(--cui-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--cui-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--cui-pagination-active-color);
  background-color: var(--cui-pagination-active-bg);
  border-color: var(--cui-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--cui-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--cui-pagination-disabled-bg);
  border-color: var(--cui-pagination-disabled-border-color);
}

html:not([dir=rtl]) .page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
*[dir=rtl] .page-item:not(:first-child) .page-link {
  margin-right: -1px;
}
html:not([dir=rtl]) .page-item:first-child .page-link {
  border-top-left-radius: var(--cui-pagination-border-radius);
}
*[dir=rtl] .page-item:first-child .page-link {
  border-top-right-radius: var(--cui-pagination-border-radius);
}
html:not([dir=rtl]) .page-item:first-child .page-link {
  border-bottom-left-radius: var(--cui-pagination-border-radius);
}
*[dir=rtl] .page-item:first-child .page-link {
  border-bottom-right-radius: var(--cui-pagination-border-radius);
}
html:not([dir=rtl]) .page-item:last-child .page-link {
  border-top-right-radius: var(--cui-pagination-border-radius);
}
*[dir=rtl] .page-item:last-child .page-link {
  border-top-left-radius: var(--cui-pagination-border-radius);
}
html:not([dir=rtl]) .page-item:last-child .page-link {
  border-bottom-right-radius: var(--cui-pagination-border-radius);
}
*[dir=rtl] .page-item:last-child .page-link {
  border-bottom-left-radius: var(--cui-pagination-border-radius);
}

.pagination-lg {
  --cui-pagination-padding-x: 1.5rem;
  --cui-pagination-padding-y: 0.75rem;
  --cui-pagination-font-size: 1.25rem;
  --cui-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --cui-pagination-padding-x: 0.5rem;
  --cui-pagination-padding-y: 0.25rem;
  --cui-pagination-font-size: 0.875rem;
  --cui-pagination-border-radius: 0.25rem;
}

.badge {
  --cui-badge-padding-x: 0.65em;
  --cui-badge-padding-y: 0.35em;
  --cui-badge-font-size: 0.75em;
  --cui-badge-font-weight: 700;
  --cui-badge-color: rgba(255, 255, 255, 0.87);
  --cui-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--cui-badge-padding-y) var(--cui-badge-padding-x);
  font-size: var(--cui-badge-font-size);
  font-weight: var(--cui-badge-font-weight);
  line-height: 1;
  color: var(--cui-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--cui-badge-border-radius, 0);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-sm {
  padding: 0.3em 0.5em;
  font-size: 0.65em;
}

.alert {
  --cui-alert-bg: transparent;
  --cui-alert-padding-x: 1rem;
  --cui-alert-padding-y: 1rem;
  --cui-alert-margin-bottom: 1rem;
  --cui-alert-color: inherit;
  --cui-alert-border-color: transparent;
  --cui-alert-border: 1px solid var(--cui-alert-border-color);
  --cui-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--cui-alert-padding-y) var(--cui-alert-padding-x);
  margin-bottom: var(--cui-alert-margin-bottom);
  color: var(--cui-alert-color);
  background-color: var(--cui-alert-bg);
  border: var(--cui-alert-border);
  border-radius: var(--cui-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--cui-alert-link-color);
}

html:not([dir=rtl]) .alert-dismissible {
  padding-right: 3rem;
}
*[dir=rtl] .alert-dismissible {
  padding-left: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}
html:not([dir=rtl]) .alert-dismissible .btn-close {
  right: 0;
}
*[dir=rtl] .alert-dismissible .btn-close {
  left: 0;
}

.alert-primary {
  --cui-alert-color: #302a89;
  --cui-alert-bg: #dcdafa;
  --cui-alert-border-color: #cbc8f7;
}
.alert-primary .alert-link {
  color: #26226e;
}

.alert-secondary {
  --cui-alert-color: rgba(125, 133, 144, 0.97);
  --cui-alert-bg: #fbfbfc;
  --cui-alert-border-color: #f9fafa;
}
.alert-secondary .alert-link {
  color: rgba(99, 105, 114, 0.976);
}

.alert-success {
  --cui-alert-color: #317a53;
  --cui-alert-bg: #dcf5e8;
  --cui-alert-border-color: #cbf0dc;
}
.alert-success .alert-link {
  color: #276242;
}

.alert-info {
  --cui-alert-color: #464991;
  --cui-alert-bg: #e3e4fc;
  --cui-alert-border-color: #d5d7fb;
}
.alert-info .alert-link {
  color: #383a74;
}

.alert-warning {
  --cui-alert-color: rgba(134, 119, 43, 0.97);
  --cui-alert-bg: #fff5cc;
  --cui-alert-border-color: #fff0b3;
}
.alert-warning .alert-link {
  color: rgba(106, 94, 34, 0.976);
}

.alert-danger {
  --cui-alert-color: #8f2142;
  --cui-alert-bg: #fcd7e2;
  --cui-alert-border-color: #fac3d4;
}
.alert-danger .alert-link {
  color: #721a35;
}

.alert-light {
  --cui-alert-color: rgba(127, 136, 147, 0.97);
  --cui-alert-bg: #fcfdfd;
  --cui-alert-border-color: #fbfcfd;
}
.alert-light .alert-link {
  color: rgba(100, 107, 116, 0.976);
}

.alert-dark {
  --cui-alert-color: #3b434f;
  --cui-alert-bg: #e0e2e6;
  --cui-alert-border-color: #d0d4da;
}
.alert-dark .alert-link {
  color: #2f363f;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --cui-progress-height: 1rem;
  --cui-progress-font-size: 0.75rem;
  --cui-progress-bg: #ebedef;
  --cui-progress-border-radius: 0.375rem;
  --cui-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 21, 0.075);
  --cui-progress-bar-color: rgba(255, 255, 255, 0.87);
  --cui-progress-bar-bg: #5046e5;
  --cui-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--cui-progress-height);
  overflow: hidden;
  font-size: var(--cui-progress-font-size);
  background-color: var(--cui-progress-bg);
  border-radius: var(--cui-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--cui-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--cui-progress-bar-bg);
  transition: var(--cui-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--cui-progress-height) var(--cui-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.progress-thin {
  height: 4px;
}

.progress.progress-white {
  background-color: rgba(255, 255, 255, 0.2);
}
.progress.progress-white .progress-bar {
  background-color: #fff;
}

.progress-group {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;
}

.progress-group-prepend {
  flex: 0 0 100px;
  align-self: center;
}

.progress-group-header {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin-bottom: 0.25rem;
}

.progress-group-bars {
  flex-grow: 1;
  align-self: center;
}
.progress-group-bars .progress:not(:last-child) {
  margin-bottom: 2px;
}

.progress-group-header + .progress-group-bars {
  flex-basis: 100%;
}

.list-group {
  --cui-list-group-color: unset;
  --cui-list-group-bg: #fff;
  --cui-list-group-border-color: rgba(0, 0, 21, 0.125);
  --cui-list-group-border-width: 1px;
  --cui-list-group-border-radius: 0.375rem;
  --cui-list-group-item-padding-x: 1rem;
  --cui-list-group-item-padding-y: 0.5rem;
  --cui-list-group-action-color: #8a93a2;
  --cui-list-group-action-hover-color: #8a93a2;
  --cui-list-group-action-hover-bg: #f0f4f7;
  --cui-list-group-action-active-color: rgba(44, 56, 74, 0.95);
  --cui-list-group-action-active-bg: #ebedef;
  --cui-list-group-disabled-color: #9da5b1;
  --cui-list-group-disabled-bg: #fff;
  --cui-list-group-active-color: rgba(255, 255, 255, 0.87);
  --cui-list-group-active-bg: #5046e5;
  --cui-list-group-active-border-color: #5046e5;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  border-radius: var(--cui-list-group-border-radius);
}
html:not([dir=rtl]) .list-group {
  padding-left: 0;
}
*[dir=rtl] .list-group {
  padding-right: 0;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--cui-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--cui-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--cui-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--cui-list-group-action-active-color);
  background-color: var(--cui-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--cui-list-group-item-padding-y) var(--cui-list-group-item-padding-x);
  color: var(--cui-list-group-color);
  text-decoration: none;
  background-color: var(--cui-list-group-bg);
  border: var(--cui-list-group-border-width) solid var(--cui-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--cui-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--cui-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--cui-list-group-active-color);
  background-color: var(--cui-list-group-active-bg);
  border-color: var(--cui-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--cui-list-group-border-width));
  border-top-width: var(--cui-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
html:not([dir=rtl]) .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--cui-list-group-border-radius);
}
*[dir=rtl] .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-right-radius: var(--cui-list-group-border-radius);
}
html:not([dir=rtl]) .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-top-right-radius: 0;
}
*[dir=rtl] .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-top-left-radius: 0;
}
html:not([dir=rtl]) .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--cui-list-group-border-radius);
}
*[dir=rtl] .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-left-radius: var(--cui-list-group-border-radius);
}
html:not([dir=rtl]) .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-bottom-left-radius: 0;
}
*[dir=rtl] .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-bottom-right-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--cui-list-group-border-width);
}
html:not([dir=rtl]) .list-group-horizontal > .list-group-item + .list-group-item {
  border-left-width: 0;
}
*[dir=rtl] .list-group-horizontal > .list-group-item + .list-group-item {
  border-right-width: 0;
}
html:not([dir=rtl]) .list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--cui-list-group-border-width));
}
*[dir=rtl] .list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-right: calc(-1 * var(--cui-list-group-border-width));
}
html:not([dir=rtl]) .list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: var(--cui-list-group-border-width);
}
*[dir=rtl] .list-group-horizontal > .list-group-item + .list-group-item.active {
  border-right-width: var(--cui-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  html:not([dir=rtl]) .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-right-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-top-right-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-top-left-radius: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-left-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-bottom-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--cui-list-group-border-width);
  }
  html:not([dir=rtl]) .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-left-width: 0;
  }
  *[dir=rtl] .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-right-width: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cui-list-group-border-width));
  }
  *[dir=rtl] .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-right: calc(-1 * var(--cui-list-group-border-width));
  }
  html:not([dir=rtl]) .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: var(--cui-list-group-border-width);
  }
  *[dir=rtl] .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-right-width: var(--cui-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  html:not([dir=rtl]) .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-right-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-top-right-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-top-left-radius: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-left-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-bottom-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--cui-list-group-border-width);
  }
  html:not([dir=rtl]) .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-left-width: 0;
  }
  *[dir=rtl] .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-right-width: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cui-list-group-border-width));
  }
  *[dir=rtl] .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-right: calc(-1 * var(--cui-list-group-border-width));
  }
  html:not([dir=rtl]) .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: var(--cui-list-group-border-width);
  }
  *[dir=rtl] .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-right-width: var(--cui-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  html:not([dir=rtl]) .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-right-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-top-right-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-top-left-radius: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-left-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-bottom-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--cui-list-group-border-width);
  }
  html:not([dir=rtl]) .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-left-width: 0;
  }
  *[dir=rtl] .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-right-width: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cui-list-group-border-width));
  }
  *[dir=rtl] .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-right: calc(-1 * var(--cui-list-group-border-width));
  }
  html:not([dir=rtl]) .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: var(--cui-list-group-border-width);
  }
  *[dir=rtl] .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-right-width: var(--cui-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  html:not([dir=rtl]) .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-right-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-top-right-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-top-left-radius: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-left-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-bottom-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--cui-list-group-border-width);
  }
  html:not([dir=rtl]) .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-left-width: 0;
  }
  *[dir=rtl] .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-right-width: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cui-list-group-border-width));
  }
  *[dir=rtl] .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-right: calc(-1 * var(--cui-list-group-border-width));
  }
  html:not([dir=rtl]) .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: var(--cui-list-group-border-width);
  }
  *[dir=rtl] .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-right-width: var(--cui-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  html:not([dir=rtl]) .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-right-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-top-right-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-top-left-radius: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cui-list-group-border-radius);
  }
  *[dir=rtl] .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-left-radius: var(--cui-list-group-border-radius);
  }
  html:not([dir=rtl]) .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
  }
  *[dir=rtl] .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-bottom-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--cui-list-group-border-width);
  }
  html:not([dir=rtl]) .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-left-width: 0;
  }
  *[dir=rtl] .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-right-width: 0;
  }
  html:not([dir=rtl]) .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cui-list-group-border-width));
  }
  *[dir=rtl] .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-right: calc(-1 * var(--cui-list-group-border-width));
  }
  html:not([dir=rtl]) .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: var(--cui-list-group-border-width);
  }
  *[dir=rtl] .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-right-width: var(--cui-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--cui-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --cui-list-group-color: contrast-ratio-correction(#302a89, #dcdafa, 40%, "primary");
  --cui-list-group-bg: #dcdafa;
  --cui-list-group-hover-bg: #483fce;
  --cui-list-group-action-hover-color: contrast-ratio-correction(#302a89, #dcdafa, 40%, "primary");
  --cui-list-group-action-active-color: #fff;
  --cui-list-group-action-active-bg: contrast-ratio-correction(#302a89, #dcdafa, 40%, "primary");
  --cui-list-group-action-active-border-color: contrast-ratio-correction(#302a89, #dcdafa, 40%, "primary");
}

.list-group-item-secondary {
  --cui-list-group-color: contrast-ratio-correction(#8d8e8f, #fbfbfc, 40%, "secondary");
  --cui-list-group-bg: #fbfbfc;
  --cui-list-group-hover-bg: #d4d5d7;
  --cui-list-group-action-hover-color: contrast-ratio-correction(#8d8e8f, #fbfbfc, 40%, "secondary");
  --cui-list-group-action-active-color: #fff;
  --cui-list-group-action-active-bg: contrast-ratio-correction(#8d8e8f, #fbfbfc, 40%, "secondary");
  --cui-list-group-action-active-border-color: contrast-ratio-correction(#8d8e8f, #fbfbfc, 40%, "secondary");
}

.list-group-item-success {
  --cui-list-group-color: contrast-ratio-correction(#317a53, #dcf5e8, 40%, "success");
  --cui-list-group-bg: #dcf5e8;
  --cui-list-group-hover-bg: #49b87c;
  --cui-list-group-action-hover-color: contrast-ratio-correction(#317a53, #dcf5e8, 40%, "success");
  --cui-list-group-action-active-color: #fff;
  --cui-list-group-action-active-bg: contrast-ratio-correction(#317a53, #dcf5e8, 40%, "success");
  --cui-list-group-action-active-border-color: contrast-ratio-correction(#317a53, #dcf5e8, 40%, "success");
}

.list-group-item-danger {
  --cui-list-group-color: contrast-ratio-correction(#8f2142, #fcd7e2, 40%, "danger");
  --cui-list-group-bg: #fcd7e2;
  --cui-list-group-hover-bg: #d73263;
  --cui-list-group-action-hover-color: contrast-ratio-correction(#8f2142, #fcd7e2, 40%, "danger");
  --cui-list-group-action-active-color: #fff;
  --cui-list-group-action-active-bg: contrast-ratio-correction(#8f2142, #fcd7e2, 40%, "danger");
  --cui-list-group-action-active-border-color: contrast-ratio-correction(#8f2142, #fcd7e2, 40%, "danger");
}

.list-group-item-warning {
  --cui-list-group-color: contrast-ratio-correction(#997a00, #fff5cc, 40%, "warning");
  --cui-list-group-bg: #fff5cc;
  --cui-list-group-hover-bg: #e6b800;
  --cui-list-group-action-hover-color: contrast-ratio-correction(#997a00, #fff5cc, 40%, "warning");
  --cui-list-group-action-active-color: #fff;
  --cui-list-group-action-active-bg: contrast-ratio-correction(#997a00, #fff5cc, 40%, "warning");
  --cui-list-group-action-active-border-color: contrast-ratio-correction(#997a00, #fff5cc, 40%, "warning");
}

.list-group-item-info {
  --cui-list-group-color: contrast-ratio-correction(#464991, #e3e4fc, 40%, "info");
  --cui-list-group-bg: #e3e4fc;
  --cui-list-group-hover-bg: #686eda;
  --cui-list-group-action-hover-color: contrast-ratio-correction(#464991, #e3e4fc, 40%, "info");
  --cui-list-group-action-active-color: #fff;
  --cui-list-group-action-active-bg: contrast-ratio-correction(#464991, #e3e4fc, 40%, "info");
  --cui-list-group-action-active-border-color: contrast-ratio-correction(#464991, #e3e4fc, 40%, "info");
}

.list-group-item-light {
  --cui-list-group-color: contrast-ratio-correction(#909294, #fcfdfd, 40%, "light");
  --cui-list-group-bg: #fcfdfd;
  --cui-list-group-hover-bg: #d8dcde;
  --cui-list-group-action-hover-color: contrast-ratio-correction(#909294, #fcfdfd, 40%, "light");
  --cui-list-group-action-active-color: #fff;
  --cui-list-group-action-active-bg: contrast-ratio-correction(#909294, #fcfdfd, 40%, "light");
  --cui-list-group-action-active-border-color: contrast-ratio-correction(#909294, #fcfdfd, 40%, "light");
}

.list-group-item-dark {
  --cui-list-group-color: contrast-ratio-correction(#3b434f, #e0e2e6, 40%, "dark");
  --cui-list-group-bg: #e0e2e6;
  --cui-list-group-hover-bg: #596476;
  --cui-list-group-action-hover-color: contrast-ratio-correction(#3b434f, #e0e2e6, 40%, "dark");
  --cui-list-group-action-active-color: #fff;
  --cui-list-group-action-active-bg: contrast-ratio-correction(#3b434f, #e0e2e6, 40%, "dark");
  --cui-list-group-action-active-border-color: contrast-ratio-correction(#3b434f, #e0e2e6, 40%, "dark");
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--cui-btn-close-color, rgba(44, 56, 74, 0.95));
  background: transparent var(--cui-btn-close-bg, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%2844, 56, 74, 0.95%29'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: var(--cui-btn-close-color, rgba(44, 56, 74, 0.95));
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --cui-toast-zindex: 1090;
  --cui-toast-padding-x: 0.75rem;
  --cui-toast-padding-y: 0.5rem;
  --cui-toast-spacing: 1.5rem;
  --cui-toast-max-width: 350px;
  --cui-toast-font-size: 0.875rem;
  --cui-toast-color: unset;
  --cui-toast-bg: rgba(255, 255, 255, 0.85);
  --cui-toast-border-width: 1px;
  --cui-toast-border-color: var(--cui-border-color-translucent);
  --cui-toast-border-radius: 0.375rem;
  --cui-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15);
  --cui-toast-header-color: #9da5b1;
  --cui-toast-header-bg: rgba(255, 255, 255, 0.85);
  --cui-toast-header-border-color: rgba(0, 0, 21, 0.05);
  width: var(--cui-toast-max-width);
  max-width: 100%;
  font-size: var(--cui-toast-font-size);
  color: var(--cui-toast-color);
  pointer-events: auto;
  background-color: var(--cui-toast-bg);
  background-clip: padding-box;
  border: var(--cui-toast-border-width) solid var(--cui-toast-border-color);
  box-shadow: var(--cui-toast-box-shadow);
  border-radius: var(--cui-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --cui-toast-zindex: 1090;
  position: absolute;
  z-index: var(--cui-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--cui-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--cui-toast-padding-y) var(--cui-toast-padding-x);
  color: var(--cui-toast-header-color);
  background-color: var(--cui-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--cui-toast-border-width) solid var(--cui-toast-header-border-color);
  border-top-left-radius: calc(var(--cui-toast-border-radius) - var(--cui-toast-border-width));
  border-top-right-radius: calc(var(--cui-toast-border-radius) - var(--cui-toast-border-width));
}
html:not([dir=rtl]) .toast-header .btn-close {
  margin-right: calc(-0.5 * var(--cui-toast-padding-x));
}
*[dir=rtl] .toast-header .btn-close {
  margin-left: calc(-0.5 * var(--cui-toast-padding-x));
}
html:not([dir=rtl]) .toast-header .btn-close {
  margin-left: var(--cui-toast-padding-x);
}
*[dir=rtl] .toast-header .btn-close {
  margin-right: var(--cui-toast-padding-x);
}

.toast-body {
  padding: var(--cui-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --cui-modal-zindex: 1055;
  --cui-modal-width: 500px;
  --cui-modal-padding: 1rem;
  --cui-modal-margin: 0.5rem;
  --cui-modal-color: unset;
  --cui-modal-bg: #fff;
  --cui-modal-border-color: var(--cui-border-color-translucent);
  --cui-modal-border-width: 1px;
  --cui-modal-border-radius: 0.5rem;
  --cui-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 21, 0.075);
  --cui-modal-inner-border-radius: calc(0.5rem - 1px);
  --cui-modal-header-padding-x: 1rem;
  --cui-modal-header-padding-y: 1rem;
  --cui-modal-header-padding: 1rem 1rem;
  --cui-modal-header-border-color: var(--cui-border-color);
  --cui-modal-header-border-width: 1px;
  --cui-modal-title-line-height: 1.5;
  --cui-modal-footer-gap: 0.5rem;
  --cui-modal-footer-bg: ;
  --cui-modal-footer-border-color: var(--cui-border-color);
  --cui-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  z-index: var(--cui-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
html:not([dir=rtl]) .modal {
  left: 0;
}
*[dir=rtl] .modal {
  right: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--cui-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--cui-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--cui-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--cui-modal-color);
  pointer-events: auto;
  background-color: var(--cui-modal-bg);
  background-clip: padding-box;
  border: var(--cui-modal-border-width) solid var(--cui-modal-border-color);
  border-radius: var(--cui-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --cui-backdrop-zindex: 1050;
  --cui-backdrop-bg: #000015;
  --cui-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--cui-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--cui-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--cui-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--cui-modal-header-padding);
  border-bottom: var(--cui-modal-header-border-width) solid var(--cui-modal-header-border-color);
  border-top-left-radius: var(--cui-modal-inner-border-radius);
  border-top-right-radius: var(--cui-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--cui-modal-header-padding-y) * 0.5) calc(var(--cui-modal-header-padding-x) * 0.5);
}
html:not([dir=rtl]) .modal-header .btn-close {
  margin: calc(-0.5 * var(--cui-modal-header-padding-y)) calc(-0.5 * var(--cui-modal-header-padding-x)) calc(-0.5 * var(--cui-modal-header-padding-y)) auto;
}
*[dir=rtl] .modal-header .btn-close {
  margin: calc(-0.5 * var(--cui-modal-header-padding-y)) auto calc(-0.5 * var(--cui-modal-header-padding-y)) calc(-0.5 * var(--cui-modal-header-padding-x));
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--cui-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--cui-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--cui-modal-padding) - var(--cui-modal-footer-gap) * 0.5);
  background-color: var(--cui-modal-footer-bg);
  border-top: var(--cui-modal-footer-border-width) solid var(--cui-modal-footer-border-color);
  border-bottom-right-radius: var(--cui-modal-inner-border-radius);
  border-bottom-left-radius: var(--cui-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--cui-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --cui-modal-margin: 1.75rem;
    --cui-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15);
  }
  .modal-dialog {
    max-width: var(--cui-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --cui-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --cui-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --cui-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --cui-tooltip-zindex: 1080;
  --cui-tooltip-max-width: 200px;
  --cui-tooltip-padding-x: 0.5rem;
  --cui-tooltip-padding-y: 0.25rem;
  --cui-tooltip-margin: ;
  --cui-tooltip-font-size: 0.875rem;
  --cui-tooltip-color: rgba(255, 255, 255, 0.87);
  --cui-tooltip-bg: #000015;
  --cui-tooltip-border-radius: 0.375rem;
  --cui-tooltip-opacity: 0.9;
  --cui-tooltip-arrow-width: 0.8rem;
  --cui-tooltip-arrow-height: 0.4rem;
  z-index: var(--cui-tooltip-zindex);
  display: block;
  padding: var(--cui-tooltip-arrow-height);
  margin: var(--cui-tooltip-margin);
  font-family: var(--cui-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--cui-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--cui-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--cui-tooltip-arrow-width);
  height: var(--cui-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--cui-tooltip-arrow-height) calc(var(--cui-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--cui-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--cui-tooltip-arrow-height);
  height: var(--cui-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--cui-tooltip-arrow-width) * 0.5) var(--cui-tooltip-arrow-height) calc(var(--cui-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--cui-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--cui-tooltip-arrow-width) * 0.5) var(--cui-tooltip-arrow-height);
  border-bottom-color: var(--cui-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--cui-tooltip-arrow-height);
  height: var(--cui-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--cui-tooltip-arrow-width) * 0.5) 0 calc(var(--cui-tooltip-arrow-width) * 0.5) var(--cui-tooltip-arrow-height);
  border-left-color: var(--cui-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--cui-tooltip-max-width);
  padding: var(--cui-tooltip-padding-y) var(--cui-tooltip-padding-x);
  color: var(--cui-tooltip-color);
  text-align: center;
  background-color: var(--cui-tooltip-bg);
  border-radius: var(--cui-tooltip-border-radius, 0);
}

.popover {
  --cui-popover-zindex: 1070;
  --cui-popover-max-width: 276px;
  --cui-popover-font-size: 0.875rem;
  --cui-popover-bg: #fff;
  --cui-popover-border-width: 1px;
  --cui-popover-border-color: var(--cui-border-color-translucent);
  --cui-popover-border-radius: 0.5rem;
  --cui-popover-inner-border-radius: calc(0.5rem - 1px);
  --cui-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15);
  --cui-popover-header-padding-x: 1rem;
  --cui-popover-header-padding-y: 0.5rem;
  --cui-popover-header-font-size: 1rem;
  --cui-popover-header-color: unset;
  --cui-popover-header-bg: #f0f0f0;
  --cui-popover-body-padding-x: 1rem;
  --cui-popover-body-padding-y: 1rem;
  --cui-popover-body-color: rgba(44, 56, 74, 0.95);
  --cui-popover-arrow-width: 1rem;
  --cui-popover-arrow-height: 0.5rem;
  --cui-popover-arrow-border: var(--cui-popover-border-color);
  z-index: var(--cui-popover-zindex);
  display: block;
  max-width: var(--cui-popover-max-width);
  font-family: var(--cui-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--cui-popover-font-size);
  word-wrap: break-word;
  background-color: var(--cui-popover-bg);
  background-clip: padding-box;
  border: var(--cui-popover-border-width) solid var(--cui-popover-border-color);
  border-radius: var(--cui-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--cui-popover-arrow-width);
  height: var(--cui-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--cui-popover-arrow-height)) - var(--cui-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--cui-popover-arrow-height) calc(var(--cui-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--cui-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--cui-popover-border-width);
  border-top-color: var(--cui-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--cui-popover-arrow-height)) - var(--cui-popover-border-width));
  width: var(--cui-popover-arrow-height);
  height: var(--cui-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--cui-popover-arrow-width) * 0.5) var(--cui-popover-arrow-height) calc(var(--cui-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--cui-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--cui-popover-border-width);
  border-right-color: var(--cui-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--cui-popover-arrow-height)) - var(--cui-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--cui-popover-arrow-width) * 0.5) var(--cui-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--cui-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--cui-popover-border-width);
  border-bottom-color: var(--cui-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--cui-popover-arrow-width);
  margin-left: calc(-0.5 * var(--cui-popover-arrow-width));
  content: "";
  border-bottom: var(--cui-popover-border-width) solid var(--cui-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--cui-popover-arrow-height)) - var(--cui-popover-border-width));
  width: var(--cui-popover-arrow-height);
  height: var(--cui-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--cui-popover-arrow-width) * 0.5) 0 calc(var(--cui-popover-arrow-width) * 0.5) var(--cui-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--cui-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--cui-popover-border-width);
  border-left-color: var(--cui-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--cui-popover-header-padding-y) var(--cui-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--cui-popover-header-font-size);
  color: var(--cui-popover-header-color);
  background-color: var(--cui-popover-header-bg);
  border-bottom: var(--cui-popover-border-width) solid var(--cui-popover-border-color);
  border-top-left-radius: var(--cui-popover-inner-border-radius);
  border-top-right-radius: var(--cui-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--cui-popover-body-padding-y) var(--cui-popover-body-padding-x);
  color: var(--cui-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: var(--cui-carousel-control-color, rgba(255, 255, 255, 0.87));
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: var(--cui-carousel-control-color, rgba(255, 255, 255, 0.87));
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.87%29'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.87%29'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-coreui-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--cui-carousel-indicator-active-bg, #fff);
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-coreui-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--cui-carousel-caption-color, rgba(255, 255, 255, 0.87));
  text-align: center;
}

.carousel-dark {
  --cui-carousel-indicator-active-bg: #000015;
  --cui-carousel-caption-color: rgba(44, 56, 74, 0.95);
}
.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-coreui-target] {
  background-color: #000015;
}
.carousel-dark .carousel-caption {
  color: rgba(44, 56, 74, 0.95);
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--cui-spinner-width);
  height: var(--cui-spinner-height);
  vertical-align: var(--cui-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--cui-spinner-animation-speed) linear infinite var(--cui-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --cui-spinner-width: 2rem;
  --cui-spinner-height: 2rem;
  --cui-spinner-vertical-align: -0.125em;
  --cui-spinner-border-width: 0.25em;
  --cui-spinner-animation-speed: 0.75s;
  --cui-spinner-animation-name: spinner-border;
  border: var(--cui-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --cui-spinner-width: 1rem;
  --cui-spinner-height: 1rem;
  --cui-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --cui-spinner-width: 2rem;
  --cui-spinner-height: 2rem;
  --cui-spinner-vertical-align: -0.125em;
  --cui-spinner-animation-speed: 0.75s;
  --cui-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --cui-spinner-width: 1rem;
  --cui-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --cui-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --cui-offcanvas-zindex: 1045;
  --cui-offcanvas-width: 400px;
  --cui-offcanvas-height: 30vh;
  --cui-offcanvas-padding-x: 1rem;
  --cui-offcanvas-padding-y: 1rem;
  --cui-offcanvas-color: unset;
  --cui-offcanvas-bg: #fff;
  --cui-offcanvas-border-width: 1px;
  --cui-offcanvas-border-color: var(--cui-border-color-translucent);
  --cui-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 21, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--cui-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cui-offcanvas-color);
    visibility: hidden;
    background-color: var(--cui-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    width: var(--cui-offcanvas-width);
  }
  html:not([dir=rtl]) .offcanvas-sm.offcanvas-start {
    left: 0;
  }
  *[dir=rtl] .offcanvas-sm.offcanvas-start {
    right: 0;
  }
  html:not([dir=rtl]) .offcanvas-sm.offcanvas-start {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-sm.offcanvas-start {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  html:not([dir=rtl]) .offcanvas-sm.offcanvas-start {
    transform: translateX(-100%);
  }
  *[dir=rtl] .offcanvas-sm.offcanvas-start {
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    width: var(--cui-offcanvas-width);
    transform: translateX(100%);
  }
  html:not([dir=rtl]) .offcanvas-sm.offcanvas-end {
    right: 0;
  }
  *[dir=rtl] .offcanvas-sm.offcanvas-end {
    left: 0;
  }
  html:not([dir=rtl]) .offcanvas-sm.offcanvas-end {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-sm.offcanvas-end {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-top: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none !important;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --cui-offcanvas-height: auto;
    --cui-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--cui-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cui-offcanvas-color);
    visibility: hidden;
    background-color: var(--cui-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    width: var(--cui-offcanvas-width);
  }
  html:not([dir=rtl]) .offcanvas-md.offcanvas-start {
    left: 0;
  }
  *[dir=rtl] .offcanvas-md.offcanvas-start {
    right: 0;
  }
  html:not([dir=rtl]) .offcanvas-md.offcanvas-start {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-md.offcanvas-start {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  html:not([dir=rtl]) .offcanvas-md.offcanvas-start {
    transform: translateX(-100%);
  }
  *[dir=rtl] .offcanvas-md.offcanvas-start {
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    width: var(--cui-offcanvas-width);
    transform: translateX(100%);
  }
  html:not([dir=rtl]) .offcanvas-md.offcanvas-end {
    right: 0;
  }
  *[dir=rtl] .offcanvas-md.offcanvas-end {
    left: 0;
  }
  html:not([dir=rtl]) .offcanvas-md.offcanvas-end {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-md.offcanvas-end {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-top: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none !important;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --cui-offcanvas-height: auto;
    --cui-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--cui-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cui-offcanvas-color);
    visibility: hidden;
    background-color: var(--cui-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    width: var(--cui-offcanvas-width);
  }
  html:not([dir=rtl]) .offcanvas-lg.offcanvas-start {
    left: 0;
  }
  *[dir=rtl] .offcanvas-lg.offcanvas-start {
    right: 0;
  }
  html:not([dir=rtl]) .offcanvas-lg.offcanvas-start {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-lg.offcanvas-start {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  html:not([dir=rtl]) .offcanvas-lg.offcanvas-start {
    transform: translateX(-100%);
  }
  *[dir=rtl] .offcanvas-lg.offcanvas-start {
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    width: var(--cui-offcanvas-width);
    transform: translateX(100%);
  }
  html:not([dir=rtl]) .offcanvas-lg.offcanvas-end {
    right: 0;
  }
  *[dir=rtl] .offcanvas-lg.offcanvas-end {
    left: 0;
  }
  html:not([dir=rtl]) .offcanvas-lg.offcanvas-end {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-lg.offcanvas-end {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-top: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none !important;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --cui-offcanvas-height: auto;
    --cui-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--cui-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cui-offcanvas-color);
    visibility: hidden;
    background-color: var(--cui-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    width: var(--cui-offcanvas-width);
  }
  html:not([dir=rtl]) .offcanvas-xl.offcanvas-start {
    left: 0;
  }
  *[dir=rtl] .offcanvas-xl.offcanvas-start {
    right: 0;
  }
  html:not([dir=rtl]) .offcanvas-xl.offcanvas-start {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-xl.offcanvas-start {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  html:not([dir=rtl]) .offcanvas-xl.offcanvas-start {
    transform: translateX(-100%);
  }
  *[dir=rtl] .offcanvas-xl.offcanvas-start {
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    width: var(--cui-offcanvas-width);
    transform: translateX(100%);
  }
  html:not([dir=rtl]) .offcanvas-xl.offcanvas-end {
    right: 0;
  }
  *[dir=rtl] .offcanvas-xl.offcanvas-end {
    left: 0;
  }
  html:not([dir=rtl]) .offcanvas-xl.offcanvas-end {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-xl.offcanvas-end {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-top: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none !important;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --cui-offcanvas-height: auto;
    --cui-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--cui-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cui-offcanvas-color);
    visibility: hidden;
    background-color: var(--cui-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    width: var(--cui-offcanvas-width);
  }
  html:not([dir=rtl]) .offcanvas-xxl.offcanvas-start {
    left: 0;
  }
  *[dir=rtl] .offcanvas-xxl.offcanvas-start {
    right: 0;
  }
  html:not([dir=rtl]) .offcanvas-xxl.offcanvas-start {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-xxl.offcanvas-start {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  html:not([dir=rtl]) .offcanvas-xxl.offcanvas-start {
    transform: translateX(-100%);
  }
  *[dir=rtl] .offcanvas-xxl.offcanvas-start {
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    width: var(--cui-offcanvas-width);
    transform: translateX(100%);
  }
  html:not([dir=rtl]) .offcanvas-xxl.offcanvas-end {
    right: 0;
  }
  *[dir=rtl] .offcanvas-xxl.offcanvas-end {
    left: 0;
  }
  html:not([dir=rtl]) .offcanvas-xxl.offcanvas-end {
    border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
  *[dir=rtl] .offcanvas-xxl.offcanvas-end {
    border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cui-offcanvas-height);
    max-height: 100%;
    border-top: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none !important;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --cui-offcanvas-height: auto;
    --cui-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--cui-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--cui-offcanvas-color);
  visibility: hidden;
  background-color: var(--cui-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  width: var(--cui-offcanvas-width);
}
html:not([dir=rtl]) .offcanvas.offcanvas-start {
  left: 0;
}
*[dir=rtl] .offcanvas.offcanvas-start {
  right: 0;
}
html:not([dir=rtl]) .offcanvas.offcanvas-start {
  border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
}
*[dir=rtl] .offcanvas.offcanvas-start {
  border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
}
html:not([dir=rtl]) .offcanvas.offcanvas-start {
  transform: translateX(-100%);
}
*[dir=rtl] .offcanvas.offcanvas-start {
  transform: translateX(100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  width: var(--cui-offcanvas-width);
  transform: translateX(100%);
}
html:not([dir=rtl]) .offcanvas.offcanvas-end {
  right: 0;
}
*[dir=rtl] .offcanvas.offcanvas-end {
  left: 0;
}
html:not([dir=rtl]) .offcanvas.offcanvas-end {
  border-left: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
}
*[dir=rtl] .offcanvas.offcanvas-end {
  border-right: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--cui-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--cui-offcanvas-height);
  max-height: 100%;
  border-top: var(--cui-offcanvas-border-width) solid var(--cui-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none !important;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--cui-offcanvas-backdrop-bg, #000015);
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--cui-offcanvas-padding-y) var(--cui-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--cui-offcanvas-padding-y) * 0.5) calc(var(--cui-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--cui-offcanvas-padding-y));
  margin-bottom: calc(-0.5 * var(--cui-offcanvas-padding-y));
}
html:not([dir=rtl]) .offcanvas-header .btn-close {
  margin-right: calc(-0.5 * var(--cui-offcanvas-padding-x));
}
*[dir=rtl] .offcanvas-header .btn-close {
  margin-left: calc(-0.5 * var(--cui-offcanvas-padding-x));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--cui-offcanvas-padding-y) var(--cui-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000015 55%, rgba(0, 0, 0, 0.8) 75%, #000015 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 50em;
  transition: margin 0.15s;
  width: 2rem;
  height: 2rem;
  font-size: 0.8rem;
}
@media (prefers-reduced-motion: reduce) {
  .avatar {
    transition: none;
  }
}
.avatar .avatar-status {
  width: 0.5333333333rem;
  height: 0.5333333333rem;
}

.avatar-img {
  width: 100%;
  height: auto;
  border-radius: 50em;
}

.avatar-status {
  position: absolute;
  bottom: 0;
  display: block;
  border: 1px solid #fff;
  border-radius: 50em;
}
html:not([dir=rtl]) .avatar-status {
  right: 0;
}
*[dir=rtl] .avatar-status {
  left: 0;
}

.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.6rem;
}
.avatar-sm .avatar-status {
  width: 0.4rem;
  height: 0.4rem;
}

.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
}
.avatar-md .avatar-status {
  width: 0.6666666667rem;
  height: 0.6666666667rem;
}

.avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.2rem;
}
.avatar-lg .avatar-status {
  width: 0.8rem;
  height: 0.8rem;
}

.avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 1.6rem;
}
.avatar-xl .avatar-status {
  width: 1.0666666667rem;
  height: 1.0666666667rem;
}

.avatars-stack {
  display: flex;
}
html:not([dir=rtl]) .avatars-stack .avatar {
  margin-right: -0.8rem;
}
*[dir=rtl] .avatars-stack .avatar {
  margin-left: -0.8rem;
}
html:not([dir=rtl]) .avatars-stack .avatar:hover {
  margin-right: 0;
}
*[dir=rtl] .avatars-stack .avatar:hover {
  margin-left: 0;
}
html:not([dir=rtl]) .avatars-stack .avatar-sm {
  margin-right: -0.6rem;
}
*[dir=rtl] .avatars-stack .avatar-sm {
  margin-left: -0.6rem;
}
html:not([dir=rtl]) .avatars-stack .avatar-md {
  margin-right: -1rem;
}
*[dir=rtl] .avatars-stack .avatar-md {
  margin-left: -1rem;
}
html:not([dir=rtl]) .avatars-stack .avatar-lg {
  margin-right: -1.2rem;
}
*[dir=rtl] .avatars-stack .avatar-lg {
  margin-left: -1.2rem;
}
html:not([dir=rtl]) .avatars-stack .avatar-xl {
  margin-right: -1.6rem;
}
*[dir=rtl] .avatars-stack .avatar-xl {
  margin-left: -1.6rem;
}

.calendar {
  --cui-calendar-table-margin: 0.5rem;
  --cui-calendar-table-cell-size: 2.75rem;
  --cui-calendar-nav-padding: 0.5rem;
  --cui-calendar-nav-border: 1px solid #ebedef;
  --cui-calendar-nav-date-color: rgba(44, 56, 74, 0.95);
  --cui-calendar-nav-date-hover-color: #5046e5;
  --cui-calendar-nav-icon-width: 1rem;
  --cui-calendar-nav-icon-height: 1rem;
  --cui-calendar-cell-header-inner-color: rgba(44, 56, 74, 0.681);
  --cui-calendar-cell-hover-bg: #ebedef;
  --cui-calendar-cell-disabled-color: rgba(44, 56, 74, 0.38);
  --cui-calendar-cell-selected-color: #fff;
  --cui-calendar-cell-selected-bg: #5046e5;
  --cui-calendar-cell-range-bg: rgba(80, 70, 229, 0.125);
  --cui-calendar-cell-range-hover-bg: rgba(80, 70, 229, 0.25);
  --cui-calendar-cell-range-hover-border-color: #5046e5;
  --cui-calendar-cell-today-color: #ef376e;
  font-weight: initial;
}
.calendar table {
  width: calc(var(--cui-calendar-table-cell-size) * 7);
  margin: var(--cui-calendar-table-margin);
}
.calendar table th,
.calendar table td {
  width: var(--cui-calendar-table-cell-size);
}
.calendar.months ~ .time-picker, .calendar.years ~ .time-picker {
  display: none;
}

.calendars {
  display: flex;
}

.calendar-nav {
  display: flex;
  align-items: baseline;
  padding: var(--cui-calendar-nav-padding);
  border-bottom: var(--cui-calendar-nav-border);
}

.calendar-nav-date {
  flex: 1;
  text-align: center;
}
.calendar-nav-date .btn {
  font-weight: 600;
  color: var(--cui-calendar-nav-date-color);
}
.calendar-nav-date .btn:hover {
  color: var(--cui-calendar-nav-date-hover-color);
}

.calendar-nav-icon {
  display: block;
  width: var(--cui-calendar-nav-icon-width);
  height: var(--cui-calendar-nav-icon-height);
  transition: background-image 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .calendar-nav-icon {
    transition: none;
  }
}

html:not([dir=rtl]) .calendar-nav-icon-double-next {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='95.314 447.313 72.686 424.687 245.373 252 72.686 79.313 95.314 56.687 290.627 252 95.314 447.313'%3e%3c/polygon%3e%3cpolygon fill='%239da5b1' points='255.314 447.313 232.686 424.687 405.373 252 232.686 79.313 255.314 56.687 450.627 252 255.314 447.313'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .calendar-nav-icon-double-next {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='416.686 447.313 221.373 252 416.686 56.687 439.314 79.313 266.627 252 439.314 424.687 416.686 447.313'%3e%3c/polygon%3e%3cpolygon fill='%239da5b1' points='256.686 447.313 61.373 252 256.686 56.687 279.314 79.313 106.627 252 279.314 424.687 256.686 447.313'%3e%3c/polygon%3e%3c/svg%3e");
}
html:not([dir=rtl]) .calendar-nav-icon-double-next:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='95.314 447.313 72.686 424.687 245.373 252 72.686 79.313 95.314 56.687 290.627 252 95.314 447.313'%3e%3c/polygon%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='255.314 447.313 232.686 424.687 405.373 252 232.686 79.313 255.314 56.687 450.627 252 255.314 447.313'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .calendar-nav-icon-double-next:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='416.686 447.313 221.373 252 416.686 56.687 439.314 79.313 266.627 252 439.314 424.687 416.686 447.313'%3e%3c/polygon%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='256.686 447.313 61.373 252 256.686 56.687 279.314 79.313 106.627 252 279.314 424.687 256.686 447.313'%3e%3c/polygon%3e%3c/svg%3e");
}

html:not([dir=rtl]) .calendar-nav-icon-double-prev {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='416.686 447.313 221.373 252 416.686 56.687 439.314 79.313 266.627 252 439.314 424.687 416.686 447.313'%3e%3c/polygon%3e%3cpolygon fill='%239da5b1' points='256.686 447.313 61.373 252 256.686 56.687 279.314 79.313 106.627 252 279.314 424.687 256.686 447.313'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .calendar-nav-icon-double-prev {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='95.314 447.313 72.686 424.687 245.373 252 72.686 79.313 95.314 56.687 290.627 252 95.314 447.313'%3e%3c/polygon%3e%3cpolygon fill='%239da5b1' points='255.314 447.313 232.686 424.687 405.373 252 232.686 79.313 255.314 56.687 450.627 252 255.314 447.313'%3e%3c/polygon%3e%3c/svg%3e");
}
html:not([dir=rtl]) .calendar-nav-icon-double-prev:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='416.686 447.313 221.373 252 416.686 56.687 439.314 79.313 266.627 252 439.314 424.687 416.686 447.313'%3e%3c/polygon%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='256.686 447.313 61.373 252 256.686 56.687 279.314 79.313 106.627 252 279.314 424.687 256.686 447.313'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .calendar-nav-icon-double-prev:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='95.314 447.313 72.686 424.687 245.373 252 72.686 79.313 95.314 56.687 290.627 252 95.314 447.313'%3e%3c/polygon%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='255.314 447.313 232.686 424.687 405.373 252 232.686 79.313 255.314 56.687 450.627 252 255.314 447.313'%3e%3c/polygon%3e%3c/svg%3e");
}

html:not([dir=rtl]) .calendar-nav-icon-next {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='179.313 451.313 156.687 428.687 329.372 256 156.687 83.313 179.313 60.687 374.627 256 179.313 451.313'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .calendar-nav-icon-next {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='324.687 451.313 129.373 256 324.687 60.687 347.313 83.313 174.628 256 347.313 428.687 324.687 451.313'%3e%3c/polygon%3e%3c/svg%3e");
}
html:not([dir=rtl]) .calendar-nav-icon-next:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='179.313 451.313 156.687 428.687 329.372 256 156.687 83.313 179.313 60.687 374.627 256 179.313 451.313'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .calendar-nav-icon-next:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='324.687 451.313 129.373 256 324.687 60.687 347.313 83.313 174.628 256 347.313 428.687 324.687 451.313'%3e%3c/polygon%3e%3c/svg%3e");
}

html:not([dir=rtl]) .calendar-nav-icon-prev {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='324.687 451.313 129.373 256 324.687 60.687 347.313 83.313 174.628 256 347.313 428.687 324.687 451.313'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .calendar-nav-icon-prev {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='179.313 451.313 156.687 428.687 329.372 256 156.687 83.313 179.313 60.687 374.627 256 179.313 451.313'%3e%3c/polygon%3e%3c/svg%3e");
}
html:not([dir=rtl]) .calendar-nav-icon-prev:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='324.687 451.313 129.373 256 324.687 60.687 347.313 83.313 174.628 256 347.313 428.687 324.687 451.313'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .calendar-nav-icon-prev:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='179.313 451.313 156.687 428.687 329.372 256 156.687 83.313 179.313 60.687 374.627 256 179.313 451.313'%3e%3c/polygon%3e%3c/svg%3e");
}

.calendar-header-cell-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--cui-calendar-table-cell-size);
  font-weight: 600;
  color: var(--cui-calendar-cell-header-inner-color);
}

.calendar-cell {
  padding: 1px 0;
  text-align: center;
}
.calendar-cell:not(.disabled) .calendar-cell-inner {
  cursor: pointer;
}
.calendar-cell:hover:not(.disabled) .calendar-cell-inner {
  background-color: var(--cui-calendar-cell-hover-bg);
}
.calendar-cell.today .calendar-cell-inner {
  color: var(--cui-calendar-cell-today-color);
}
.calendar-cell.disabled, .calendar-cell.next, .calendar-cell.previous .calendar-cell-inner {
  color: var(--cui-calendar-cell-disabled-color);
}
.calendar-cell.disabled .calendar-cell-inner {
  cursor: not-allowed;
}
.calendar-cell.range:not(.selected) .calendar-cell-inner {
  background: var(--cui-calendar-cell-range-bg);
  border-radius: 0;
}
.calendar-cell.range:not(.selected):hover .calendar-cell-inner {
  position: relative;
}
.calendar-cell.range:not(.selected):hover .calendar-cell-inner::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: var(--cui-calendar-cell-range-hover-bg);
  border-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.range:not(.selected):first-child .calendar-cell-inner, html:not([dir=rtl]) .calendar-cell:not(.range) + .range .calendar-cell-inner {
  border-top-left-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.range:not(.selected):first-child .calendar-cell-inner, *[dir=rtl] .calendar-cell:not(.range) + .range .calendar-cell-inner {
  border-top-right-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.range:not(.selected):first-child .calendar-cell-inner, html:not([dir=rtl]) .calendar-cell:not(.range) + .range .calendar-cell-inner {
  border-bottom-left-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.range:not(.selected):first-child .calendar-cell-inner, *[dir=rtl] .calendar-cell:not(.range) + .range .calendar-cell-inner {
  border-bottom-right-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.range:not(.selected):last-child .calendar-cell-inner, html:not([dir=rtl]) .calendar-cell.range:not(.selected).last .calendar-cell-inner {
  border-top-right-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.range:not(.selected):last-child .calendar-cell-inner, *[dir=rtl] .calendar-cell.range:not(.selected).last .calendar-cell-inner {
  border-top-left-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.range:not(.selected):last-child .calendar-cell-inner, html:not([dir=rtl]) .calendar-cell.range:not(.selected).last .calendar-cell-inner {
  border-bottom-right-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.range:not(.selected):last-child .calendar-cell-inner, *[dir=rtl] .calendar-cell.range:not(.selected).last .calendar-cell-inner {
  border-bottom-left-radius: 0.375rem;
}
.calendar-cell.range-hover .calendar-cell-inner {
  position: relative;
}
.calendar-cell.range-hover .calendar-cell-inner::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
}
.calendar-cell.range-hover .calendar-cell-inner::before, .calendar-cell.range-hover.selected .calendar-cell-inner::before {
  border-top: 1px dashed var(--cui-calendar-cell-selected-bg);
  border-bottom: 1px dashed var(--cui-calendar-cell-selected-bg);
  border-radius: 0;
}
.calendar-cell.range-hover:first-child .calendar-cell-inner::before, .calendar-cell:not(.range-hover) + .range-hover .calendar-cell-inner::before {
  border-left: 1px dashed var(--cui-calendar-cell-selected-bg);
}
html:not([dir=rtl]) .calendar-cell.range-hover:first-child .calendar-cell-inner::before, html:not([dir=rtl]) .calendar-cell:not(.range-hover) + .range-hover .calendar-cell-inner::before {
  border-top-left-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.range-hover:first-child .calendar-cell-inner::before, *[dir=rtl] .calendar-cell:not(.range-hover) + .range-hover .calendar-cell-inner::before {
  border-top-right-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.range-hover:first-child .calendar-cell-inner::before, html:not([dir=rtl]) .calendar-cell:not(.range-hover) + .range-hover .calendar-cell-inner::before {
  border-bottom-left-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.range-hover:first-child .calendar-cell-inner::before, *[dir=rtl] .calendar-cell:not(.range-hover) + .range-hover .calendar-cell-inner::before {
  border-bottom-right-radius: 0.375rem;
}
.calendar-cell.range-hover:last-child .calendar-cell-inner::before, .calendar-cell.range-hover.last .calendar-cell-inner::before, .calendar-cell.range-hover + .range-hover:hover .calendar-cell-inner::before, .calendar-cell.range-hover:first-child:hover .calendar-cell-inner::before {
  border-right: 1px dashed var(--cui-calendar-cell-selected-bg);
}
html:not([dir=rtl]) .calendar-cell.range-hover:last-child .calendar-cell-inner::before, html:not([dir=rtl]) .calendar-cell.range-hover.last .calendar-cell-inner::before, html:not([dir=rtl]) .calendar-cell.range-hover + .range-hover:hover .calendar-cell-inner::before, html:not([dir=rtl]) .calendar-cell.range-hover:first-child:hover .calendar-cell-inner::before {
  border-top-right-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.range-hover:last-child .calendar-cell-inner::before, *[dir=rtl] .calendar-cell.range-hover.last .calendar-cell-inner::before, *[dir=rtl] .calendar-cell.range-hover + .range-hover:hover .calendar-cell-inner::before, *[dir=rtl] .calendar-cell.range-hover:first-child:hover .calendar-cell-inner::before {
  border-top-left-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.range-hover:last-child .calendar-cell-inner::before, html:not([dir=rtl]) .calendar-cell.range-hover.last .calendar-cell-inner::before, html:not([dir=rtl]) .calendar-cell.range-hover + .range-hover:hover .calendar-cell-inner::before, html:not([dir=rtl]) .calendar-cell.range-hover:first-child:hover .calendar-cell-inner::before {
  border-bottom-right-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.range-hover:last-child .calendar-cell-inner::before, *[dir=rtl] .calendar-cell.range-hover.last .calendar-cell-inner::before, *[dir=rtl] .calendar-cell.range-hover + .range-hover:hover .calendar-cell-inner::before, *[dir=rtl] .calendar-cell.range-hover:first-child:hover .calendar-cell-inner::before {
  border-bottom-left-radius: 0.375rem;
}
.calendar-cell.range-hover.selected:hover .calendar-cell-inner::before {
  border: 0;
}
.calendar-cell.selected:not(.previous):not(.next) .calendar-cell-inner, .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner, .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner {
  position: relative;
  color: var(--cui-calendar-cell-selected-color);
  background: var(--cui-calendar-cell-selected-bg);
}
.calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before, .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background: var(--cui-calendar-cell-range-bg);
}
html:not([dir=rtl]) .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before {
  border-top-left-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before {
  border-top-right-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before {
  border-bottom-left-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before {
  border-bottom-right-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before {
  border-top-right-radius: 0;
}
*[dir=rtl] .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before {
  border-top-left-radius: 0;
}
html:not([dir=rtl]) .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before {
  border-bottom-right-radius: 0;
}
*[dir=rtl] .calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner::before {
  border-bottom-left-radius: 0;
}
html:not([dir=rtl]) .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  border-top-left-radius: 0;
}
*[dir=rtl] .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  border-top-right-radius: 0;
}
html:not([dir=rtl]) .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  border-bottom-left-radius: 0;
}
*[dir=rtl] .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  border-bottom-right-radius: 0;
}
html:not([dir=rtl]) .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  border-top-right-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  border-top-left-radius: 0.375rem;
}
html:not([dir=rtl]) .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  border-bottom-right-radius: 0.375rem;
}
*[dir=rtl] .calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner::before {
  border-bottom-left-radius: 0.375rem;
}

.calendar-cell-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--cui-calendar-table-cell-size);
  border-radius: 0.375rem;
}

.callout {
  --cui-callout-padding-x: 1rem;
  --cui-callout-padding-y: 1rem;
  --cui-callout-margin-x: 0;
  --cui-callout-margin-y: 1rem;
  --cui-callout-border-width: 1px;
  --cui-callout-border-color: #ebedef;
  --cui-callout-border-left-width: 4px;
  --cui-callout-border-radius: 0.375rem;
  padding: var(--cui-callout-padding-y) var(--cui-callout-padding-x);
  margin: var(--cui-callout-margin-y) var(--cui-callout-margin-x);
  border: var(--cui-callout-border-width) solid var(--cui-callout-border-color);
  border-radius: var(--cui-callout-border-radius);
}
html:not([dir=rtl]) .callout {
  border-left-width: var(--cui-callout-border-left-width);
}
*[dir=rtl] .callout {
  border-right-width: var(--cui-callout-border-right-width);
}
html:not([dir=rtl]) .callout {
  border-left-color: var(--cui-callout-border-left-color);
}
*[dir=rtl] .callout {
  border-right-color: var(--cui-callout-border-right-color);
}

.callout-primary {
  --cui-callout-border-left-color: #5046e5;
}

.callout-secondary {
  --cui-callout-border-left-color: #ebedef;
}

.callout-success {
  --cui-callout-border-left-color: #51cc8a;
}

.callout-danger {
  --cui-callout-border-left-color: #ef376e;
}

.callout-warning {
  --cui-callout-border-left-color: #fc0;
}

.callout-info {
  --cui-callout-border-left-color: #747af2;
}

.callout-light {
  --cui-callout-border-left-color: #f0f4f7;
}

.callout-dark {
  --cui-callout-border-left-color: #636f83;
}

.picker {
  --cui-picker-footer-border-top: 1px solid #ebedef;
  --cui-picker-footer-padding: 0.5rem;
}
.picker .dropdown-menu {
  padding: 0;
}
.picker.show .input-group {
  color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-focus-bg, #fff);
  border-color: var(--cui-input-focus-border-color, #a8a3f2);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(80, 70, 229, 0.25);
}

.picker-input-group:hover .form-control:not(:placeholder-shown) ~ .input-group-text .picker-input-group-indicator:not(:last-child) {
  display: none;
}
.picker-input-group:hover .form-control:not(:placeholder-shown) ~ .input-group-text .picker-input-group-cleaner {
  display: initial;
}
.picker-input-group .form-control.hover {
  color: var(--cui-gray-500);
}
.picker-input-group .form-control:focus {
  border-color: var(--cui-input-border-color, #c4c9d0);
  outline: 0;
  box-shadow: none;
}
html:not([dir=rtl]) .picker-input-group .form-control:not(:first-child) {
  border-left: 0;
}
*[dir=rtl] .picker-input-group .form-control:not(:first-child) {
  border-right: 0;
}
html:not([dir=rtl]) .picker-input-group .form-control:not(:last-child) {
  border-right: 0;
}
*[dir=rtl] .picker-input-group .form-control:not(:last-child) {
  border-left: 0;
}
.picker-input-group .form-control[readonly] {
  background-color: var(--cui-input-bg, #fff);
}
.picker-input-group .form-control.form-control:disabled + .input-group-text {
  background-color: var(--cui-input-disabled-bg, #ebedef);
}
.picker-input-group.input-group {
  border-radius: 0.375rem;
}
.picker-input-group.input-group-lg {
  border-radius: 0.5rem;
}
.picker-input-group.input-group-lg .picker-input-group-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.picker-input-group.input-group-sm {
  border-radius: 0.25rem;
}
.picker-input-group.input-group-sm .picker-input-group-icon {
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0.875rem;
}
.picker-input-group .input-group-text {
  color: var(--cui-gray-400);
  background-color: var(--cui-input-bg, #fff);
}

.picker-input-group-cleaner {
  display: none;
}

.picker-input-group-icon {
  display: block;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  transition: background-image 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .picker-input-group-icon {
    transition: none;
  }
}

.picker-footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--cui-picker-footer-padding);
  border-top: var(--cui-picker-footer-border-top);
}
html:not([dir=rtl]) .picker-footer .btn + .btn {
  margin-left: 0.5rem;
}
*[dir=rtl] .picker-footer .btn + .btn {
  margin-right: 0.5rem;
}

.date-picker {
  --cui-date-picker-ranges-width: 10rem;
  --cui-date-picker-ranges-padding: 0.5rem;
  --cui-date-picker-ranges-border: 1px solid #ebedef;
  --cui-date-picker-timepicker-width: 20.25rem;
  --cui-date-picker-timepicker-border-top: 1px solid #ebedef;
}
.date-picker .dropdown-menu {
  width: min-content;
}
.date-picker.is-invalid .form-control {
  --cui-input-border-color: #ef376e;
}
.date-picker.is-invalid .input-group-text {
  --cui-input-group-addon-border-color: #ef376e;
}
.date-picker.is-invalid .picker-input-group-indicator {
  --cui-input-group-addon-color: #ef376e;
}
.date-picker.is-invalid .date-picker-input-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpath fill='%23ef376e' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'%3e%3c/path%3e%3crect width='32' height='32' x='112' y='224' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='224' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='224' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='224' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='112' y='296' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='296' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='296' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='296' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='112' y='368' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='368' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='368' fill='%23ef376e'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='368' fill='%23ef376e'%3e%3c/rect%3e%3c/svg%3e");
}
.date-picker.is-valid .form-control {
  --cui-input-border-color: #51cc8a;
}
.date-picker.is-valid .input-group-text {
  --cui-input-group-addon-border-color: #51cc8a;
}
.date-picker.is-valid .picker-input-group-indicator {
  --cui-input-group-addon-color: #51cc8a;
}
.date-picker.is-valid .date-picker-input-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpath fill='%2351cc8a' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'%3e%3c/path%3e%3crect width='32' height='32' x='112' y='224' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='224' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='224' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='224' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='112' y='296' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='296' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='296' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='296' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='112' y='368' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='368' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='368' fill='%2351cc8a'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='368' fill='%2351cc8a'%3e%3c/rect%3e%3c/svg%3e");
}

.date-picker-body {
  display: grid;
  grid-template-areas: "ranges calendars" "ranges timepickers";
  grid-template-rows: auto;
  grid-template-columns: auto auto;
}

.date-picker-ranges {
  grid-area: ranges;
  width: var(--cui-date-picker-ranges-width);
  padding: var(--cui-date-picker-ranges-padding);
  border-right: var(--cui-date-picker-ranges-border);
}

.date-picker-calendars {
  display: flex;
  grid-area: calendars;
}
.date-picker-calendars .date-picker-calendar:not(:last-child) .calendar-navigation-next {
  visibility: hidden;
}
.date-picker-calendars .date-picker-calendar:not(:first-child) .calendar-navigation-prev {
  visibility: hidden;
}

html:not([dir=rtl]) .date-picker-arrow-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='359.873 121.377 337.246 144.004 433.243 240.001 16 240.001 16 240.002 16 272.001 16 272.002 433.24 272.002 337.246 367.996 359.873 390.623 494.498 256 359.873 121.377'%3e%3c/polygon%3e%3c/svg%3e");
}
*[dir=rtl] .date-picker-arrow-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='497.333 239.999 80.092 239.999 176.087 144.004 153.46 121.377 18.837 256 153.46 390.623 176.087 367.996 80.09 271.999 497.333 271.999 497.333 239.999'%3e%3c/polygon%3e%3c/svg%3e");
}

.date-picker-cleaner-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='306.912 214.461 256 265.373 205.088 214.461 182.461 237.088 233.373 288 182.461 338.912 205.088 361.539 256 310.627 306.912 361.539 329.539 338.912 278.627 288 329.539 237.088 306.912 214.461'%3e%3c/polygon%3e%3cpath fill='%239da5b1' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'%3e%3c/path%3e%3c/svg%3e");
}
.date-picker-cleaner-icon:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='306.912 214.461 256 265.373 205.088 214.461 182.461 237.088 233.373 288 182.461 338.912 205.088 361.539 256 310.627 306.912 361.539 329.539 338.912 278.627 288 329.539 237.088 306.912 214.461'%3e%3c/polygon%3e%3cpath fill='rgba%2844, 56, 74, 0.95%29%29' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'%3e%3c/path%3e%3c/svg%3e");
}

.date-picker-input-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpath fill='%239da5b1' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'%3e%3c/path%3e%3crect width='32' height='32' x='112' y='224' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='224' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='224' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='224' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='112' y='296' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='296' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='296' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='296' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='112' y='368' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='200' y='368' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='280' y='368' fill='%239da5b1'%3e%3c/rect%3e%3crect width='32' height='32' x='368' y='368' fill='%239da5b1'%3e%3c/rect%3e%3c/svg%3e");
}

.date-picker-timepickers {
  display: flex;
  flex-wrap: wrap;
  grid-area: timepickers;
  justify-content: space-between;
  border-top: var(--cui-date-picker-timepicker-border-top);
}
.date-picker-timepickers .time-picker {
  width: var(--cui-date-picker-timepicker-width);
}
.date-picker-timepickers .time-picker .form-select {
  width: initial;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  text-align: right;
  background: none;
  border: 0;
}
.date-picker-timepickers .time-picker .form-select:disabled {
  color: rgba(44, 56, 74, 0.38);
  background-color: transparent;
}
@media (max-width: 767.98px) {
  .date-picker-timepickers {
    display: block;
  }
  .date-picker-timepickers .time-picker {
    width: 100%;
  }
  .date-picker-timepickers .time-picker + .time-picker {
    border-top: var(--cui-date-picker-timepicker-border-top);
  }
}

.time-picker {
  --cui-time-picker-body-padding: 0.5rem;
  --cui-time-picker-roll-col-border: 1px solid #ebedef;
}
.time-picker.is-invalid .form-control {
  --cui-input-border-color: #ef376e;
}
.time-picker.is-invalid .input-group-text {
  --cui-input-group-addon-border-color: #ef376e;
}
.time-picker.is-invalid .picker-input-group-indicator {
  --cui-input-group-addon-color: #ef376e;
}
.time-picker.is-invalid .time-picker-input-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%23ef376e' points='271.514 95.5 239.514 95.5 239.514 273.611 355.127 328.559 368.864 299.657 271.514 253.389 271.514 95.5' class='ci-primary'%3e%3c/polygon%3e%3cpath fill='%23ef376e' d='M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm0,448C141.125,464,48,370.875,48,256S141.125,48,256,48s208,93.125,208,208S370.875,464,256,464Z' class='ci-primary'%3e%3c/path%3e%3c/svg%3e");
}
.time-picker.is-valid .form-control {
  --cui-input-border-color: #51cc8a;
}
.time-picker.is-valid .input-group-text {
  --cui-input-group-addon-border-color: #51cc8a;
}
.time-picker.is-valid .picker-input-group-indicator {
  --cui-input-group-addon-color: #51cc8a;
}
.time-picker.is-valid .time-picker-input-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%2351cc8a' points='271.514 95.5 239.514 95.5 239.514 273.611 355.127 328.559 368.864 299.657 271.514 253.389 271.514 95.5' class='ci-primary'%3e%3c/polygon%3e%3cpath fill='%2351cc8a' d='M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm0,448C141.125,464,48,370.875,48,256S141.125,48,256,48s208,93.125,208,208S370.875,464,256,464Z' class='ci-primary'%3e%3c/path%3e%3c/svg%3e");
}

.time-picker-body {
  display: flex;
  align-items: center;
  padding: var(--cui-time-picker-body-padding);
}
.time-picker-body .form-select {
  padding-right: 1.25rem;
  background-position: right 0.5rem center;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.time-picker-body .form-select + .form-select {
  margin-left: 0.5rem;
}
.time-picker-body .form-select::-webkit-scrollbar {
  width: 10px;
}
.time-picker-body .time-picker-inline-icon {
  display: block;
  width: 40px;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='271.514 95.5 239.514 95.5 239.514 273.611 355.127 328.559 368.864 299.657 271.514 253.389 271.514 95.5' class='ci-primary'%3e%3c/polygon%3e%3cpath fill='%239da5b1' d='M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm0,448C141.125,464,48,370.875,48,256S141.125,48,256,48s208,93.125,208,208S370.875,464,256,464Z' class='ci-primary'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
}

.time-picker-cleaner-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='348.071 141.302 260.308 229.065 172.545 141.302 149.917 163.929 237.681 251.692 149.917 339.456 172.545 362.083 260.308 274.32 348.071 362.083 370.699 339.456 282.935 251.692 370.699 163.929 348.071 141.302' class='ci-primary'%3e%3c/polygon%3e%3cpath fill='%239da5b1' d='M425.706,86.294A240,240,0,0,0,86.294,425.706,240,240,0,0,0,425.706,86.294ZM256,464C141.309,464,48,370.691,48,256S141.309,48,256,48s208,93.309,208,208S370.691,464,256,464Z' class='ci-primary'%3e%3c/path%3e%3c/svg%3e");
}
.time-picker-cleaner-icon:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='rgba%2844, 56, 74, 0.95%29' points='348.071 141.302 260.308 229.065 172.545 141.302 149.917 163.929 237.681 251.692 149.917 339.456 172.545 362.083 260.308 274.32 348.071 362.083 370.699 339.456 282.935 251.692 370.699 163.929 348.071 141.302' class='ci-primary'%3e%3c/polygon%3e%3cpath fill='rgba%2844, 56, 74, 0.95%29' d='M425.706,86.294A240,240,0,0,0,86.294,425.706,240,240,0,0,0,425.706,86.294ZM256,464C141.309,464,48,370.691,48,256S141.309,48,256,48s208,93.309,208,208S370.691,464,256,464Z' class='ci-primary'%3e%3c/path%3e%3c/svg%3e");
}

.time-picker-input-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'%3e%3cpolygon fill='%239da5b1' points='271.514 95.5 239.514 95.5 239.514 273.611 355.127 328.559 368.864 299.657 271.514 253.389 271.514 95.5' class='ci-primary'%3e%3c/polygon%3e%3cpath fill='%239da5b1' d='M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm0,448C141.125,464,48,370.875,48,256S141.125,48,256,48s208,93.125,208,208S370.875,464,256,464Z' class='ci-primary'%3e%3c/path%3e%3c/svg%3e");
}

.time-picker-roll {
  padding: 0;
  overflow: hidden;
  border-radius: inherit;
}

.time-picker-roll-col {
  height: 256px;
  overflow: scroll;
  border-right: var(--cui-time-picker-roll-col-border);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.time-picker-roll-col::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.time-picker-roll-cell {
  width: 50px;
  height: 32px;
  padding: 0 1rem 0 0.5rem;
  line-height: 32px;
}
.time-picker-roll-cell.selected {
  color: var(--cui-white);
  background: var(--cui-primary);
}
.time-picker-roll-cell:last-child::after {
  display: block;
  height: 224px;
  content: "";
}

.footer {
  --cui-footer-min-height: 3rem;
  --cui-footer-padding-x: 1rem;
  --cui-footer-padding-y: 0.5rem;
  --cui-footer-color: rgba(44, 56, 74, 0.95);
  --cui-footer-bg: #fff;
  --cui-footer-border-color: #ebedef;
  --cui-footer-border: 1px solid var(--cui-footer-border-color);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: var(--cui-footer-min-height);
  padding: var(--cui-footer-padding-y) var(--cui-footer-padding-x);
  color: var(--cui-footer-color);
  background: var(--cui-footer-bg);
  border-top: var(--cui-footer-border);
}

.footer-sticky {
  position: sticky;
  bottom: 0;
  z-index: 1030;
}

.header {
  --cui-header-min-height: 4rem;
  --cui-header-padding-x: 0.5rem;
  --cui-header-padding-y: 0.5rem;
  --cui-header-bg: #fff;
  --cui-header-color: rgba(255, 255, 255, 0.6);
  --cui-header-border-color: #ebedef;
  --cui-header-border: 1px solid var(--cui-header-border-color);
  --cui-header-hover-color: rgba(255, 255, 255, 0.87);
  --cui-header-disabled-color: rgba(44, 56, 74, 0.38);
  --cui-header-active-color: rgba(255, 255, 255, 0.87);
  --cui-header-brand-padding-y: 0.3125rem;
  --cui-header-brand-color: #636f83;
  --cui-header-brand-hover-color: #596476;
  --cui-header-toggler-padding-x: 0.75rem;
  --cui-header-toggler-padding-y: 0.25rem;
  --cui-header-toggler-bg: transparent;
  --cui-header-toggler-color: rgba(255, 255, 255, 0.6);
  --cui-header-toggler-border-radius: 0.375rem;
  --cui-header-toggler-hover-color: rgba(255, 255, 255, 0.87);
  --cui-header-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2844, 56, 74, 0.681%29' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --cui-header-toggler-hover-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2844, 56, 74, 0.95%29' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --cui-header-nav-link-padding-x: 0.5rem;
  --cui-header-nav-link-padding-y: 0.5rem;
  --cui-header-divider-border-color: #ebedef;
  --cui-header-divider-border: 1px solid var(--cui-header-divider-border-color);
  --cui-subheader-min-height: 3rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: var(--cui-header-min-height);
  padding: var(--cui-header-padding-y) var(--cui-header-padding-x);
  background: var(--cui-header-bg);
  border-bottom: var(--cui-header-border);
}
.header > .container,
.header > .container-fluid,
.header > .container-sm,
.header > .container-md,
.header > .container-lg,
.header > .container-xl,
.header > .container-xxl, .header .navbar > .container,
.header .navbar > .container-fluid,
.header .navbar > .container-sm,
.header .navbar > .container-md,
.header .navbar > .container-lg,
.header .navbar > .container-xl,
.header .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.header .container:first-child,
.header .container-fluid:first-child,
.header .container-sm:first-child,
.header .container-md:first-child,
.header .container-lg:first-child,
.header .container-xl:first-child,
.header .container-xxl:first-child {
  min-height: calc(var(--cui-header-min-height) - 2 * var(--cui-header-padding-y));
}
.header .container:nth-child(n+2),
.header .container-fluid:nth-child(n+2),
.header .container-sm:nth-child(n+2),
.header .container-md:nth-child(n+2),
.header .container-lg:nth-child(n+2),
.header .container-xl:nth-child(n+2),
.header .container-xxl:nth-child(n+2) {
  min-height: calc(var(--cui-subheader-min-height) - 2 * var(--cui-header-padding-y));
}
.header.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1029;
}

.header-divider {
  flex-basis: calc(100% + 2 * var(--cui-header-padding-x));
  height: 0;
  margin: var(--cui-header-padding-y) calc(var(--cui-header-padding-x) * -1);
  border-top: var(--cui-header-divider-border);
}

.header-brand {
  padding-top: var(--cui-header-brand-padding-y);
  padding-bottom: var(--cui-header-brand-padding-y);
  font-size: 1.25rem;
  color: var(--cui-header-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
html:not([dir=rtl]) .header-brand {
  margin-right: 1rem;
}
*[dir=rtl] .header-brand {
  margin-left: 1rem;
}
.header-brand:hover, .header-brand:focus {
  color: var(--cui-header-brand-hover-color);
}

.header-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  list-style: none;
}
html:not([dir=rtl]) .header-nav {
  padding-left: 0;
}
*[dir=rtl] .header-nav {
  padding-right: 0;
}
.header-nav .nav-link {
  padding: var(--cui-header-nav-link-padding-y) var(--cui-header-nav-link-padding-x);
  color: var(--cui-header-color);
}
.header-nav .nav-link:hover, .header-nav .nav-link:focus {
  color: var(--cui-header-hover-color);
}
.header-nav .nav-link.disabled {
  color: var(--cui-header-disabled-color);
}
.header-nav .show > .nav-link,
.header-nav .nav-link.active {
  color: var(--cui-header-active-color);
}
.header-nav .dropdown-menu {
  position: absolute;
}

.header-text {
  padding-top: var(--cui-header-nav-link-padding-y);
  padding-bottom: var(--cui-header-nav-link-padding-y);
  color: var(--cui-header-color);
}
.header-text a {
  color: var(--cui-header-active-color);
}
.header-text a:hover, .header-text a:focus {
  color: var(--cui-header-active-color);
}

.header-toggler {
  padding: var(--cui-header-toggler-padding-y) var(--cui-header-toggler-padding-x);
  font-size: 1.25rem;
  color: var(--cui-header-toggler-color);
  background-color: var(--cui-header-toggler-bg);
  border: 0;
  border-radius: var(--cui-header-toggler-border-radius);
}
.header-toggler:hover {
  color: var(--cui-header-toggler-hover-color);
  text-decoration: none;
}
.header-toggler:focus {
  outline: 0;
}
.header-toggler:not(:disabled) {
  cursor: pointer;
}
.header-toggler.prevent-hide {
  display: block !important;
}

.header-toggler-icon {
  display: block;
  height: 1.5625rem;
  background-image: var(--cui-header-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
.header-toggler-icon:hover {
  background-image: var(--cui-header-toggler-hover-icon-bg);
}

.icon {
  display: inline-block;
  color: inherit;
  text-align: center;
  vertical-align: -0.125rem;
  fill: currentcolor;
}
.icon:not(.icon-c-s):not(.icon-custom-size) {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-xxl {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-xl {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-lg {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-sm {
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0.875rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-3xl {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-4xl {
  width: 4rem;
  height: 4rem;
  font-size: 4rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-5xl {
  width: 5rem;
  height: 5rem;
  font-size: 5rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-6xl {
  width: 6rem;
  height: 6rem;
  font-size: 6rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-7xl {
  width: 7rem;
  height: 7rem;
  font-size: 7rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-8xl {
  width: 8rem;
  height: 8rem;
  font-size: 8rem;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-9xl {
  width: 9rem;
  height: 9rem;
  font-size: 9rem;
}

.sidebar {
  --cui-sidebar-width: 18rem;
  --cui-sidebar-bg: #3c4b64;
  --cui-sidebar-padding-x: 0;
  --cui-sidebar-padding-y: 0;
  --cui-sidebar-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-border-width: 0;
  --cui-sidebar-border-color: transparent;
  --cui-sidebar-brand-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-brand-height: 4rem;
  --cui-sidebar-brand-bg: rgba(0, 0, 21, 0.15);
  --cui-sidebar-close-button-width: 2rem;
  --cui-sidebar-close-button-height: 4rem;
  --cui-sidebar-header-height: 4rem;
  --cui-sidebar-header-bg: rgba(0, 0, 21, 0.2);
  --cui-sidebar-header-padding-x: 1rem;
  --cui-sidebar-header-padding-y: 0.75rem;
  --cui-sidebar-footer-bg: rgba(0, 0, 21, 0.2);
  --cui-sidebar-footer-height: auto;
  --cui-sidebar-footer-padding-x: 1rem;
  --cui-sidebar-footer-padding-y: 0.75rem;
  --cui-sidebar-toggler-bg: rgba(0, 0, 21, 0.2);
  --cui-sidebar-toggler-height: 3rem;
  --cui-sidebar-toggler-indicator: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%239da5b1' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  --cui-sidebar-toggler-indicator-width: 4rem;
  --cui-sidebar-toggler-indicator-height: 3rem;
  --cui-sidebar-toggler-hover-bg: rgba(0, 0, 0, 0.3);
  --cui-sidebar-toggler-indicator-hover: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba%28255, 255, 255, 0.87%29' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  --cui-sidebar-narrow-width: 4rem;
  --cui-sidebar-nav-title-padding-x: 1rem;
  --cui-sidebar-nav-title-padding-y: 0.75rem;
  --cui-sidebar-nav-title-margin-top: 1rem;
  --cui-sidebar-nav-title-color: rgba(255, 255, 255, 0.6);
  --cui-sidebar-nav-link-padding-x: 1.5rem;
  --cui-sidebar-nav-link-padding-y: 0.75rem;
  --cui-sidebar-nav-link-color: rgba(255, 255, 255, 0.6);
  --cui-sidebar-nav-link-bg: transparent;
  --cui-sidebar-nav-link-border-color: transparent;
  --cui-sidebar-nav-link-border: 0 solid var(--cui-sidebar-nav-link-border-color);
  --cui-sidebar-nav-link-border-radius: 0;
  --cui-sidebar-nav-link-active-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-nav-link-active-bg: rgba(0, 0, 21, 0.05);
  --cui-sidebar-nav-link-active-icon-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-nav-link-disabled-color: rgba(255, 255, 255, 0.38);
  --cui-sidebar-nav-link-disabled-icon-color: rgba(255, 255, 255, 0.6);
  --cui-sidebar-nav-link-hover-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-nav-link-hover-bg: rgba(0, 0, 21, 0.05);
  --cui-sidebar-nav-link-hover-icon-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-nav-icon-width: 4rem;
  --cui-sidebar-nav-icon-height: 1.25rem;
  --cui-sidebar-nav-icon-font-size: 1.25rem;
  --cui-sidebar-nav-link-icon-color: rgba(255, 255, 255, 0.38);
  --cui-sidebar-nav-group-bg: rgba(0, 0, 0, 0.2);
  --cui-sidebar-nav-group-items-padding-y: 0;
  --cui-sidebar-nav-group-items-padding-x: 0;
  --cui-sidebar-nav-group-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.6%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --cui-sidebar-nav-group-indicator-hover: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.87%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --cui-sidebar-nav-group-toggle-show-color: rgba(255, 255, 255, 0.6);
  position: relative;
  display: flex;
  flex: 0 0 var(--cui-sidebar-width);
  flex-direction: column;
  order: -1;
  width: var(--cui-sidebar-width);
  padding: var(--cui-sidebar-padding-y) var(--cui-sidebar-padding-x);
  color: var(--cui-sidebar-color);
  background: var(--cui-sidebar-bg);
  box-shadow: none;
  transition: margin-left 0.15s, margin-right 0.15s, box-shadow 0.075s, transform 0.15s, width 0.15s, z-index 0s ease 0.15s;
}
html:not([dir=rtl]) .sidebar {
  border-right: var(--cui-sidebar-border-width) solid var(--cui-sidebar-border-color);
}
*[dir=rtl] .sidebar {
  border-left: var(--cui-sidebar-border-width) solid var(--cui-sidebar-border-color);
}
@media (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}
html:not([dir=rtl]) .sidebar:not(.sidebar-end) {
  margin-left: 0;
}
*[dir=rtl] .sidebar:not(.sidebar-end) {
  margin-right: 0;
}
.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 18rem;
}
.sidebar.sidebar-end {
  order: 99;
}
html:not([dir=rtl]) .sidebar.sidebar-end {
  margin-right: 0;
}
*[dir=rtl] .sidebar.sidebar-end {
  margin-left: 0;
}
.sidebar.sidebar-end ~ * {
  --cui-sidebar-occupy-end: 18rem;
}
.sidebar[class*=bg-] {
  border-color: rgba(0, 0, 21, 0.1);
}
.sidebar.sidebar-sm {
  --cui-sidebar-width: 14rem;
}
@media (min-width: 768px) {
  .sidebar.sidebar-sm:not(.sidebar-end):not(.hide) ~ * {
    --cui-sidebar-occupy-start: 14rem;
  }
  .sidebar.sidebar-sm.sidebar-end:not(.hide) ~ * {
    --cui-sidebar-occupy-end: 14rem;
  }
}
.sidebar.sidebar-lg {
  --cui-sidebar-width: 24rem;
}
@media (min-width: 768px) {
  .sidebar.sidebar-lg:not(.sidebar-end):not(.hide) ~ * {
    --cui-sidebar-occupy-start: 24rem;
  }
  .sidebar.sidebar-lg.sidebar-end:not(.hide) ~ * {
    --cui-sidebar-occupy-end: 24rem;
  }
}
.sidebar.sidebar-xl {
  --cui-sidebar-width: 26rem;
}
@media (min-width: 768px) {
  .sidebar.sidebar-xl:not(.sidebar-end):not(.hide) ~ * {
    --cui-sidebar-occupy-start: 26rem;
  }
  .sidebar.sidebar-xl.sidebar-end:not(.hide) ~ * {
    --cui-sidebar-occupy-end: 26rem;
  }
}
@media (min-width: 768px) {
  html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end) {
    margin-left: calc(-1 * var(--cui-sidebar-width));
  }
  *[dir=rtl] .sidebar.hide:not(.sidebar-end) {
    margin-right: calc(-1 * var(--cui-sidebar-width));
  }
  .sidebar.hide:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 0;
  }
  html:not([dir=rtl]) .sidebar.hide.sidebar-end {
    margin-right: calc(-1 * var(--cui-sidebar-width));
  }
  *[dir=rtl] .sidebar.hide.sidebar-end {
    margin-left: calc(-1 * var(--cui-sidebar-width));
  }
  .sidebar.hide.sidebar-end ~ * {
    --cui-sidebar-occupy-end: 0;
  }
}
@media (min-width: 768px) {
  .sidebar.sidebar-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1030;
  }
  html:not([dir=rtl]) .sidebar.sidebar-fixed:not(.sidebar-end) {
    left: 0;
  }
  *[dir=rtl] .sidebar.sidebar-fixed:not(.sidebar-end) {
    right: 0;
  }
  html:not([dir=rtl]) .sidebar.sidebar-fixed.sidebar-end {
    right: 0;
  }
  *[dir=rtl] .sidebar.sidebar-fixed.sidebar-end {
    left: 0;
  }
}
@media (min-width: 768px) {
  .sidebar.sidebar-sticky {
    position: sticky;
    top: 0;
    height: 100vh;
  }
}
.sidebar.sidebar-overlaid {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1032;
}
html:not([dir=rtl]) .sidebar.sidebar-overlaid:not(.sidebar-end) {
  left: 0;
}
*[dir=rtl] .sidebar.sidebar-overlaid:not(.sidebar-end) {
  right: 0;
}
.sidebar.sidebar-overlaid:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 0 !important;
}
html:not([dir=rtl]) .sidebar.sidebar-overlaid.sidebar-end {
  right: 0;
}
*[dir=rtl] .sidebar.sidebar-overlaid.sidebar-end {
  left: 0;
}
.sidebar.sidebar-overlaid.sidebar-end ~ * {
  --cui-sidebar-occupy-end: 0 !important;
}
@media (max-width: 767.98px) {
  .sidebar {
    --cui-is-mobile: true;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1031;
  }
  html:not([dir=rtl]) .sidebar:not(.sidebar-end) {
    left: 0;
  }
  *[dir=rtl] .sidebar:not(.sidebar-end) {
    right: 0;
  }
  .sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 0 !important;
  }
  html:not([dir=rtl]) .sidebar:not(.sidebar-end):not(.show) {
    margin-left: calc(-1 * var(--cui-sidebar-width));
  }
  *[dir=rtl] .sidebar:not(.sidebar-end):not(.show) {
    margin-right: calc(-1 * var(--cui-sidebar-width));
  }
  html:not([dir=rtl]) .sidebar.sidebar-end {
    right: 0;
  }
  *[dir=rtl] .sidebar.sidebar-end {
    left: 0;
  }
  .sidebar.sidebar-end ~ * {
    --cui-sidebar-occupy-end: 0 !important;
  }
  html:not([dir=rtl]) .sidebar.sidebar-end:not(.show) {
    margin-right: calc(-1 * var(--cui-sidebar-width));
  }
  *[dir=rtl] .sidebar.sidebar-end:not(.show) {
    margin-left: calc(-1 * var(--cui-sidebar-width));
  }
}

.sidebar-close {
  position: absolute;
  top: 0;
  width: var(--cui-sidebar-close-button-width);
  height: var(--cui-sidebar-close-button-height);
  color: var(--cui-sidebar-color);
  background: transparent;
  border: 0;
}
html:not([dir=rtl]) .sidebar-close {
  right: 0;
}
*[dir=rtl] .sidebar-close {
  left: 0;
}
.sidebar-close:hover {
  text-decoration: none;
}
.sidebar-close:focus {
  outline: 0;
}

.sidebar-brand {
  display: flex;
  flex: 0 0 var(--cui-sidebar-brand-height);
  align-items: center;
  justify-content: center;
  color: var(--cui-sidebar-brand-color);
  background: var(--cui-sidebar-brand-bg);
}
.sidebar-brand .sidebar-brand-narrow {
  display: none;
}

.sidebar-header {
  flex: 0 0 var(--cui-sidebar-header-height);
  padding: var(--cui-sidebar-header-padding-y) var(--cui-sidebar-header-padding-x);
  text-align: center;
  background: var(--cui-sidebar-header-bg);
  transition: height 0.15s, padding 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-header {
    transition: none;
  }
}
.sidebar-header .nav-link {
  display: flex;
  align-items: center;
  min-height: var(--cui-sidebar-header-height);
}

.sidebar-footer {
  flex: 0 0 var(--cui-sidebar-footer-height);
  padding: var(--cui-sidebar-footer-padding-y) var(--cui-sidebar-footer-padding-x);
  background: var(--cui-sidebar-footer-bg);
  transition: height 0.15s, padding 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-footer {
    transition: none;
  }
}

.sidebar-toggler {
  display: flex;
  flex: 0 0 var(--cui-sidebar-toggler-height);
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  background-color: var(--cui-sidebar-toggler-bg);
  border: 0;
}
@media (max-width: 767.98px) {
  .sidebar-toggler {
    display: none;
  }
}
.sidebar-toggler::before {
  display: block;
  width: var(--cui-sidebar-toggler-indicator-width);
  height: var(--cui-sidebar-toggler-indicator-height);
  content: "";
  background-image: var(--cui-sidebar-toggler-indicator);
  background-repeat: no-repeat;
  background-position: center;
  background-size: calc(var(--cui-sidebar-toggler-indicator-height) * 0.25);
  transition: transform 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-toggler::before {
    transition: none;
  }
}
*[dir=rtl] .sidebar-toggler::before {
  transform: rotate(-180deg);
}
.sidebar-toggler:focus {
  outline: 0;
}
.sidebar-toggler:hover {
  background-color: var(--cui-sidebar-toggler-hover-bg);
}
.sidebar-toggler:hover::before {
  background-image: var(--cui-sidebar-toggler-indicator-hover);
}
.sidebar-end .sidebar-toggler {
  justify-content: flex-start;
}
.sidebar-end .sidebar-toggler::before {
  transform: rotate(-180deg);
}

.sidebar-backdrop {
  --cui-backdrop-zindex: 1030;
  --cui-backdrop-bg: #000015;
  --cui-backdrop-opacity: 0.5;
}
@media (max-width: 767.98px) {
  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--cui-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--cui-backdrop-bg);
  }
  .sidebar-backdrop.fade {
    opacity: 0;
  }
  .sidebar-backdrop.show {
    opacity: var(--cui-backdrop-opacity);
  }
}

.sidebar-light {
  --cui-sidebar-color: rgba(44, 56, 74, 0.95);
  --cui-sidebar-bg: #fff;
  --cui-sidebar-border-width: 0;
  --cui-sidebar-border-color: transparent;
  --cui-sidebar-brand-color: #fff;
  --cui-sidebar-brand-bg: #5046e5;
  --cui-sidebar-header-bg: rgba(0, 0, 21, 0.2);
  --cui-sidebar-nav-title-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-link-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-link-bg: transparent;
  --cui-sidebar-nav-link-icon-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-link-hover-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-nav-link-hover-bg: #5046e5;
  --cui-sidebar-nav-link-hover-icon-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-nav-link-active-color: rgba(44, 56, 74, 0.95);
  --cui-sidebar-nav-link-active-bg: rgba(255, 255, 255, 0.05);
  --cui-sidebar-nav-link-active-icon-color: #5046e5;
  --cui-sidebar-nav-link-disabled-color: rgba(44, 56, 74, 0.38);
  --cui-sidebar-nav-link-disabled-icon-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-group-bg: transparent;
  --cui-sidebar-nav-group-toggle-show-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-group-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(44, 56, 74, 0.681)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  --cui-sidebar-nav-group-indicator-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(255, 255, 255, 0.87)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  --cui-sidebar-footer-bg: rgba(0, 0, 21, 0.1);
  --cui-sidebar-toggler-bg: transparent;
  --cui-sidebar-toggler-hover-bg: transparent;
  --cui-sidebar-toggler-indicator: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.6)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  --cui-sidebar-toggler-indicator-hover: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.87)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.sidebar-nav {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
}
.sidebar-nav .nav-title {
  padding: var(--cui-sidebar-nav-title-padding-y) var(--cui-sidebar-nav-title-padding-x);
  margin-top: var(--cui-sidebar-nav-title-margin-top);
  font-size: 80%;
  font-weight: 700;
  color: var(--cui-sidebar-nav-title-color);
  text-transform: uppercase;
  transition: height 0.15s, margin 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-nav .nav-title {
    transition: none;
  }
}
.sidebar-nav .nav-link {
  display: flex;
  flex: 1;
  align-items: center;
  padding: var(--cui-sidebar-nav-link-padding-y) var(--cui-sidebar-nav-link-padding-x);
  color: var(--cui-sidebar-nav-link-color);
  text-decoration: none;
  white-space: nowrap;
  background: var(--cui-sidebar-nav-link-bg);
  border: var(--cui-sidebar-nav-link-border);
  border-radius: var(--cui-sidebar-nav-link-border-radius);
  transition: background 0.15s ease, color 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-nav .nav-link {
    transition: none;
  }
}
.sidebar-nav .nav-link.active {
  color: var(--cui-sidebar-nav-link-active-color);
  background: var(--cui-sidebar-nav-link-active-bg);
}
.sidebar-nav .nav-link.active .nav-icon {
  color: var(--cui-sidebar-nav-link-active-icon-color);
}
.sidebar-nav .nav-link.disabled {
  color: var(--cui-sidebar-nav-link-disabled-color);
  pointer-events: none;
  cursor: not-allowed;
  background: transparent;
}
.sidebar-nav .nav-link.disabled .nav-icon {
  color: var(--cui-sidebar-nav-link-disabled-icon-color);
}
.sidebar-nav .nav-link.disabled:hover {
  color: var(--cui-sidebar-nav-link-disabled-color);
}
.sidebar-nav .nav-link.disabled:hover .nav-icon {
  color: var(--cui-sidebar-nav-link-disabled-icon-color);
}
.sidebar-nav .nav-link.disabled:hover.nav-dropdown-toggle::after {
  background-image: var(--cui-sidebar-nav-group-indicator-hover);
}
@media (hover: hover), (-ms-high-contrast: none) {
  .sidebar-nav .nav-link:hover {
    color: var(--cui-sidebar-nav-link-hover-color);
    text-decoration: none;
    background: var(--cui-sidebar-nav-link-hover-bg);
  }
  .sidebar-nav .nav-link:hover .nav-icon {
    color: var(--cui-sidebar-nav-link-hover-icon-color);
  }
  .sidebar-nav .nav-link:hover.nav-group-toggle::after {
    background-image: var(--cui-sidebar-nav-group-indicator-hover);
  }
}
.sidebar-nav .nav-icon {
  flex: 0 0 var(--cui-sidebar-nav-icon-width);
  height: var(--cui-sidebar-nav-icon-height);
  font-size: var(--cui-sidebar-nav-icon-font-size);
  color: var(--cui-sidebar-nav-link-icon-color);
  text-align: center;
  pointer-events: none;
  fill: currentcolor;
  transition: inherit;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-nav .nav-icon {
    transition: none;
  }
}
html:not([dir=rtl]) .sidebar-nav .nav-icon:first-child {
  margin-left: calc(var(--cui-sidebar-nav-link-padding-x) * -1);
}
*[dir=rtl] .sidebar-nav .nav-icon:first-child {
  margin-right: calc(var(--cui-sidebar-nav-link-padding-x) * -1);
}
.sidebar-nav svg.nav-icon {
  overflow: hidden;
}
.sidebar-nav .nav-group {
  position: relative;
  transition: background 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-nav .nav-group {
    transition: none;
  }
}
.sidebar-nav .nav-group .nav-group-items {
  padding: var(--cui-sidebar-nav-group-items-padding-y) var(--cui-sidebar-nav-group-items-padding-x);
  overflow: hidden;
  transition: height 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-nav .nav-group .nav-group-items {
    transition: none;
  }
}
.sidebar-nav .nav-group:not(.show) .nav-group-items {
  display: none;
}
.sidebar-nav .nav-group.show {
  background: var(--cui-sidebar-nav-group-bg);
}
.sidebar-nav .nav-group.show .nav-group-toggle {
  color: var(--cui-sidebar-nav-group-toggle-show-color);
}
.sidebar-nav .nav-group.show > .nav-group-toggle::after {
  transform: rotate(180deg);
}
.sidebar-nav .nav-group.show + .show {
  margin-top: 1px;
}
.sidebar-nav .nav-group-toggle {
  cursor: pointer;
}
.sidebar-nav .nav-group-toggle::after {
  display: block;
  flex: 0 12px;
  height: 12px;
  content: "";
  background-image: var(--cui-sidebar-nav-group-indicator);
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.15s;
}
html:not([dir=rtl]) .sidebar-nav .nav-group-toggle::after {
  margin-left: auto;
}
*[dir=rtl] .sidebar-nav .nav-group-toggle::after {
  margin-right: auto;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-nav .nav-group-toggle::after {
    transition: none;
  }
}
.sidebar-nav .nav-group-items {
  padding: 0;
  list-style: none;
}
html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
  padding-left: var(--cui-sidebar-nav-icon-width);
}
*[dir=rtl] .sidebar-nav .nav-group-items .nav-link {
  padding-right: var(--cui-sidebar-nav-icon-width);
}
html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link .nav-icon {
  margin-left: calc(var(--cui-sidebar-nav-icon-width) * -1);
}
*[dir=rtl] .sidebar-nav .nav-group-items .nav-link .nav-icon {
  margin-right: calc(var(--cui-sidebar-nav-icon-width) * -1);
}
.sidebar-nav.compact .nav-link,
.sidebar-nav .compact .nav-link {
  padding-top: calc(var(--cui-sidebar-nav-link-padding-y) * 0.5);
  padding-bottom: calc(var(--cui-sidebar-nav-link-padding-y) * 0.5);
}

@media (min-width: 768px) {
  .sidebar-narrow-unfoldable:not(:hover), .sidebar-narrow {
    z-index: 1031;
    flex: 0 0 var(--cui-sidebar-narrow-width);
    width: var(--cui-sidebar-narrow-width);
    padding-bottom: var(--cui-sidebar-toggler-height);
    overflow: visible;
  }
  .sidebar-fixed.sidebar-narrow-unfoldable:not(:hover), .sidebar-fixed.sidebar-narrow {
    z-index: 1031;
    width: var(--cui-sidebar-narrow-width);
  }
  .sidebar-narrow-unfoldable:not(:hover) .sidebar-brand-full, .sidebar-narrow .sidebar-brand-full {
    display: none;
  }
  .sidebar-narrow-unfoldable:not(:hover) .sidebar-brand-narrow, .sidebar-narrow .sidebar-brand-narrow {
    display: block;
  }
  .sidebar-narrow-unfoldable:not(:hover) .d-narrow-none, .sidebar-narrow .d-narrow-none,
  .sidebar-narrow-unfoldable:not(:hover) .nav-label,
  .sidebar-narrow .nav-label,
  .sidebar-narrow-unfoldable:not(:hover) .nav-title,
  .sidebar-narrow .nav-title,
  .sidebar-narrow-unfoldable:not(:hover) .nav-group-items,
  .sidebar-narrow .nav-group-items,
  .sidebar-narrow-unfoldable:not(:hover) .sidebar-footer,
  .sidebar-narrow .sidebar-footer,
  .sidebar-narrow-unfoldable:not(:hover) .sidebar-form,
  .sidebar-narrow .sidebar-form,
  .sidebar-narrow-unfoldable:not(:hover) .sidebar-header,
  .sidebar-narrow .sidebar-header {
    height: 0 !important;
    padding: 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
  }
  .sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler, .sidebar-narrow .sidebar-toggler {
    position: fixed;
    bottom: 0;
  }
  html:not([dir=rtl]) .sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler::before, html:not([dir=rtl]) .sidebar-narrow .sidebar-toggler::before {
    transform: rotate(-180deg);
  }
  *[dir=rtl] .sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler::before, *[dir=rtl] .sidebar-narrow .sidebar-toggler::before {
    transform: rotate(0deg);
  }
  .sidebar-end.sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler::before, .sidebar-end.sidebar-narrow .sidebar-toggler::before {
    transform: rotate(0deg);
  }
}

.sidebar-narrow:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 4rem;
}
.sidebar-narrow.sidebar-end ~ * {
  --cui-sidebar-occupy-end: 4rem;
}

.sidebar-narrow-unfoldable {
  position: fixed;
  z-index: 1031;
}
.sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 4rem;
}
.sidebar-narrow-unfoldable.sidebar-end ~ * {
  --cui-sidebar-occupy-end: 4rem;
}
html:not([dir=rtl]) .sidebar-narrow-unfoldable:hover .sidebar-toggler::before {
  transform: rotate(-180deg);
}
*[dir=rtl] .sidebar-narrow-unfoldable:hover .sidebar-toggler::before {
  transform: rotate(0deg);
}
.sidebar-narrow-unfoldable:hover.sidebar-end .sidebar-toggler::before {
  transform: rotate(0deg);
}

html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow:not(.sidebar-end), html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow-unfoldable:not(.sidebar-end) {
  margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
}
*[dir=rtl] .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow:not(.sidebar-end), *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow-unfoldable:not(.sidebar-end) {
  margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
}
html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow.sidebar-end, html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow-unfoldable.sidebar-end {
  margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
}
*[dir=rtl] .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow.sidebar-end, *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow-unfoldable.sidebar-end {
  margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
}

@media (max-width: 575.98px) {
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow:not(.sidebar-end), html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow:not(.sidebar-end), *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow.sidebar-end, html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow.sidebar-end, *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow-unfoldable.sidebar-end {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
}
@media (max-width: 767.98px) {
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow:not(.sidebar-end), html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow:not(.sidebar-end), *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow.sidebar-end, html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow.sidebar-end, *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow-unfoldable.sidebar-end {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
}
@media (max-width: 991.98px) {
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow:not(.sidebar-end), html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow:not(.sidebar-end), *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow.sidebar-end, html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow.sidebar-end, *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow-unfoldable.sidebar-end {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
}
@media (max-width: 1199.98px) {
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow:not(.sidebar-end), html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow:not(.sidebar-end), *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow.sidebar-end, html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow.sidebar-end, *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow-unfoldable.sidebar-end {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
}
@media (max-width: 1399.98px) {
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-xxl.sidebar-narrow:not(.sidebar-end), html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-xxl.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-xxl.sidebar-narrow:not(.sidebar-end), *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-xxl.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-xxl.sidebar-narrow.sidebar-end, html:not([dir=rtl]) .sidebar:not(.show).sidebar-self-hiding-xxl.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: calc(var(--cui-sidebar-narrow-width) * -1);
  }
  *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-xxl.sidebar-narrow.sidebar-end, *[dir=rtl] .sidebar:not(.show).sidebar-self-hiding-xxl.sidebar-narrow-unfoldable.sidebar-end {
    margin-left: calc(var(--cui-sidebar-narrow-width) * -1);
  }
}
.subheader {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  background: var(--cui-subheader-bg, #fff);
  border-bottom: var(--cui-subheader-border-width, 1px) solid var(--cui-subheader-border-color, #ebedef);
}

.subheader-sticky {
  position: sticky;
  top: 0;
  z-index: 1029;
}

.subheader-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  list-style: none;
}
html:not([dir=rtl]) .subheader-nav {
  padding-left: 0;
}
*[dir=rtl] .subheader-nav {
  padding-right: 0;
}
.subheader-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: var(--cui-subheader-color, rgba(44, 56, 74, 0.681));
}
.subheader-nav .nav-link:hover, .subheader-nav .nav-link:focus {
  color: var(--cui-subheader-hover-color, rgba(44, 56, 74, 0.95));
}
.subheader-nav .nav-link.disabled {
  color: var(--cui-subheader-disabled-color, rgba(44, 56, 74, 0.38));
}
.subheader-nav .show > .nav-link,
.subheader-nav .nav-link.active {
  color: var(--cui-subheader-active-color, rgba(44, 56, 74, 0.95));
}
.subheader-nav .dropdown-menu {
  position: absolute;
}

.subheader-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--cui-subheader-color, rgba(44, 56, 74, 0.681));
}
.subheader-text a {
  color: var(--cui-subheader-active-color, rgba(44, 56, 74, 0.95));
}
.subheader-text a:hover, .subheader-text a:focus {
  color: var(--cui-subheader-active-color, rgba(44, 56, 74, 0.95));
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: RGBA(80, 70, 229, var(--cui-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: rgba(44, 56, 74, 0.95) !important;
  background-color: RGBA(235, 237, 239, var(--cui-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: rgba(44, 56, 74, 0.95) !important;
  background-color: RGBA(81, 204, 138, var(--cui-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000015 !important;
  background-color: RGBA(116, 122, 242, var(--cui-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: rgba(44, 56, 74, 0.95) !important;
  background-color: RGBA(255, 204, 0, var(--cui-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000015 !important;
  background-color: RGBA(239, 55, 110, var(--cui-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: rgba(44, 56, 74, 0.95) !important;
  background-color: RGBA(240, 244, 247, var(--cui-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(99, 111, 131, var(--cui-bg-opacity, 1)) !important;
}

.link-primary {
  color: #5046e5 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #736bea !important;
}

.link-secondary {
  color: #ebedef !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #bcbebf !important;
}

.link-success {
  color: #51cc8a !important;
}
.link-success:hover, .link-success:focus {
  color: #41a36e !important;
}

.link-info {
  color: #747af2 !important;
}
.link-info:hover, .link-info:focus {
  color: #9095f5 !important;
}

.link-warning {
  color: #fc0 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #cca300 !important;
}

.link-danger {
  color: #ef376e !important;
}
.link-danger:hover, .link-danger:focus {
  color: #f25f8b !important;
}

.link-light {
  color: #f0f4f7 !important;
}
.link-light:hover, .link-light:focus {
  color: #c0c3c6 !important;
}

.link-dark {
  color: #636f83 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #828c9c !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--cui-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
html:not([dir=rtl]) .ratio > * {
  left: 0;
}
*[dir=rtl] .ratio > * {
  right: 0;
}

.ratio-1x1 {
  --cui-aspect-ratio: 100%;
}

.ratio-4x3 {
  --cui-aspect-ratio: 75%;
}

.ratio-16x9 {
  --cui-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --cui-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  padding: 0;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

html:not([dir=rtl]) .float-start {
  float: left !important;
}
*[dir=rtl] .float-start {
  float: right !important;
}

html:not([dir=rtl]) .float-end {
  float: right !important;
}
*[dir=rtl] .float-end {
  float: left !important;
}

html:not([dir=rtl]) .float-none {
  float: none !important;
}
*[dir=rtl] .float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 21, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 21, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.elevation-0 {
  box-shadow: none !important;
}

.elevation-1 {
  box-shadow: 0 1px 1px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 2px 1px -1px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 1px 3px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-2 {
  box-shadow: 0 2px 2px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 3px 1px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 1px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-3 {
  box-shadow: 0 3px 4px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 3px 3px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 1px 8px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-4 {
  box-shadow: 0 4px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 1px 10px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 2px 4px -1px rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-6 {
  box-shadow: 0 6px 10px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 1px 18px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 3px 5px -1px rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-8 {
  box-shadow: 0 8px 10px 1px rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 3px 14px 2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 5px 5px -3px rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-9 {
  box-shadow: 0 9px 12px 1px rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 3px 16px 2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 5px 6px -3px rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-12 {
  box-shadow: 0 12px 17px 2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 5px 22px 4px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 7px 8px -4px rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-16 {
  box-shadow: 0 16px 24px 2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 6px 30px 5px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 8px 10px -5px rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.elevation-24 {
  box-shadow: 0 24px 38px 3px rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 9px 46px 8px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 11px 15px -7px rgba(var(--cui-elevation-base-color, 60, 75, 100), .20) !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--cui-border-width) var(--cui-border-style) var(--cui-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--cui-border-width) var(--cui-border-style) var(--cui-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

html:not([dir=rtl]) .border-end {
  border-right: var(--cui-border-width) var(--cui-border-style) var(--cui-border-color) !important;
}
*[dir=rtl] .border-end {
  border-left: var(--cui-border-width) var(--cui-border-style) var(--cui-border-color) !important;
}

html:not([dir=rtl]) .border-end-0 {
  border-right: 0 !important;
}
*[dir=rtl] .border-end-0 {
  border-left: 0 !important;
}

.border-bottom {
  border-bottom: var(--cui-border-width) var(--cui-border-style) var(--cui-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

html:not([dir=rtl]) .border-start {
  border-left: var(--cui-border-width) var(--cui-border-style) var(--cui-border-color) !important;
}
*[dir=rtl] .border-start {
  border-right: var(--cui-border-width) var(--cui-border-style) var(--cui-border-color) !important;
}

html:not([dir=rtl]) .border-start-0 {
  border-left: 0 !important;
}
*[dir=rtl] .border-start-0 {
  border-right: 0 !important;
}

.border-primary {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-primary-rgb), var(--cui-border-opacity)) !important;
}

.border-secondary {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-secondary-rgb), var(--cui-border-opacity)) !important;
}

.border-success {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-success-rgb), var(--cui-border-opacity)) !important;
}

.border-info {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-info-rgb), var(--cui-border-opacity)) !important;
}

.border-warning {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-warning-rgb), var(--cui-border-opacity)) !important;
}

.border-danger {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-danger-rgb), var(--cui-border-opacity)) !important;
}

.border-light {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-light-rgb), var(--cui-border-opacity)) !important;
}

.border-dark {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-dark-rgb), var(--cui-border-opacity)) !important;
}

.border-white {
  --cui-border-opacity: 1;
  border-color: rgba(var(--cui-white-rgb), var(--cui-border-opacity)) !important;
}

.border-top-primary {
  border-top-color: #5046e5 !important;
}

.border-top-secondary {
  border-top-color: #ebedef !important;
}

.border-top-success {
  border-top-color: #51cc8a !important;
}

.border-top-info {
  border-top-color: #747af2 !important;
}

.border-top-warning {
  border-top-color: #fc0 !important;
}

.border-top-danger {
  border-top-color: #ef376e !important;
}

.border-top-light {
  border-top-color: #f0f4f7 !important;
}

.border-top-dark {
  border-top-color: #636f83 !important;
}

.border-top-white {
  border-top-color: #fff !important;
}

html:not([dir=rtl]) .border-end-primary {
  border-right-color: #5046e5 !important;
}
*[dir=rtl] .border-end-primary {
  border-left-color: #5046e5 !important;
}

html:not([dir=rtl]) .border-end-secondary {
  border-right-color: #ebedef !important;
}
*[dir=rtl] .border-end-secondary {
  border-left-color: #ebedef !important;
}

html:not([dir=rtl]) .border-end-success {
  border-right-color: #51cc8a !important;
}
*[dir=rtl] .border-end-success {
  border-left-color: #51cc8a !important;
}

html:not([dir=rtl]) .border-end-info {
  border-right-color: #747af2 !important;
}
*[dir=rtl] .border-end-info {
  border-left-color: #747af2 !important;
}

html:not([dir=rtl]) .border-end-warning {
  border-right-color: #fc0 !important;
}
*[dir=rtl] .border-end-warning {
  border-left-color: #fc0 !important;
}

html:not([dir=rtl]) .border-end-danger {
  border-right-color: #ef376e !important;
}
*[dir=rtl] .border-end-danger {
  border-left-color: #ef376e !important;
}

html:not([dir=rtl]) .border-end-light {
  border-right-color: #f0f4f7 !important;
}
*[dir=rtl] .border-end-light {
  border-left-color: #f0f4f7 !important;
}

html:not([dir=rtl]) .border-end-dark {
  border-right-color: #636f83 !important;
}
*[dir=rtl] .border-end-dark {
  border-left-color: #636f83 !important;
}

html:not([dir=rtl]) .border-end-white {
  border-right-color: #fff !important;
}
*[dir=rtl] .border-end-white {
  border-left-color: #fff !important;
}

.border-bottom-primary {
  border-bottom-color: #5046e5 !important;
}

.border-bottom-secondary {
  border-bottom-color: #ebedef !important;
}

.border-bottom-success {
  border-bottom-color: #51cc8a !important;
}

.border-bottom-info {
  border-bottom-color: #747af2 !important;
}

.border-bottom-warning {
  border-bottom-color: #fc0 !important;
}

.border-bottom-danger {
  border-bottom-color: #ef376e !important;
}

.border-bottom-light {
  border-bottom-color: #f0f4f7 !important;
}

.border-bottom-dark {
  border-bottom-color: #636f83 !important;
}

.border-bottom-white {
  border-bottom-color: #fff !important;
}

html:not([dir=rtl]) .border-start-primary {
  border-left-color: #5046e5 !important;
}
*[dir=rtl] .border-start-primary {
  border-right-color: #5046e5 !important;
}

html:not([dir=rtl]) .border-start-secondary {
  border-left-color: #ebedef !important;
}
*[dir=rtl] .border-start-secondary {
  border-right-color: #ebedef !important;
}

html:not([dir=rtl]) .border-start-success {
  border-left-color: #51cc8a !important;
}
*[dir=rtl] .border-start-success {
  border-right-color: #51cc8a !important;
}

html:not([dir=rtl]) .border-start-info {
  border-left-color: #747af2 !important;
}
*[dir=rtl] .border-start-info {
  border-right-color: #747af2 !important;
}

html:not([dir=rtl]) .border-start-warning {
  border-left-color: #fc0 !important;
}
*[dir=rtl] .border-start-warning {
  border-right-color: #fc0 !important;
}

html:not([dir=rtl]) .border-start-danger {
  border-left-color: #ef376e !important;
}
*[dir=rtl] .border-start-danger {
  border-right-color: #ef376e !important;
}

html:not([dir=rtl]) .border-start-light {
  border-left-color: #f0f4f7 !important;
}
*[dir=rtl] .border-start-light {
  border-right-color: #f0f4f7 !important;
}

html:not([dir=rtl]) .border-start-dark {
  border-left-color: #636f83 !important;
}
*[dir=rtl] .border-start-dark {
  border-right-color: #636f83 !important;
}

html:not([dir=rtl]) .border-start-white {
  border-left-color: #fff !important;
}
*[dir=rtl] .border-start-white {
  border-right-color: #fff !important;
}

.border-1 {
  --cui-border-width: 1px;
}

.border-2 {
  --cui-border-width: 2px;
}

.border-3 {
  --cui-border-width: 3px;
}

.border-4 {
  --cui-border-width: 4px;
}

.border-5 {
  --cui-border-width: 5px;
}

.border-top-1 {
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-width: 3px !important;
}

.border-top-4 {
  border-top-width: 4px !important;
}

.border-top-5 {
  border-top-width: 5px !important;
}

html:not([dir=rtl]) .border-end-1 {
  border-right-width: 1px !important;
}
*[dir=rtl] .border-end-1 {
  border-left-width: 1px !important;
}

html:not([dir=rtl]) .border-end-2 {
  border-right-width: 2px !important;
}
*[dir=rtl] .border-end-2 {
  border-left-width: 2px !important;
}

html:not([dir=rtl]) .border-end-3 {
  border-right-width: 3px !important;
}
*[dir=rtl] .border-end-3 {
  border-left-width: 3px !important;
}

html:not([dir=rtl]) .border-end-4 {
  border-right-width: 4px !important;
}
*[dir=rtl] .border-end-4 {
  border-left-width: 4px !important;
}

html:not([dir=rtl]) .border-end-5 {
  border-right-width: 5px !important;
}
*[dir=rtl] .border-end-5 {
  border-left-width: 5px !important;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
}

html:not([dir=rtl]) .border-start-1 {
  border-left-width: 1px !important;
}
*[dir=rtl] .border-start-1 {
  border-right-width: 1px !important;
}

html:not([dir=rtl]) .border-start-2 {
  border-left-width: 2px !important;
}
*[dir=rtl] .border-start-2 {
  border-right-width: 2px !important;
}

html:not([dir=rtl]) .border-start-3 {
  border-left-width: 3px !important;
}
*[dir=rtl] .border-start-3 {
  border-right-width: 3px !important;
}

html:not([dir=rtl]) .border-start-4 {
  border-left-width: 4px !important;
}
*[dir=rtl] .border-start-4 {
  border-right-width: 4px !important;
}

html:not([dir=rtl]) .border-start-5 {
  border-left-width: 5px !important;
}
*[dir=rtl] .border-start-5 {
  border-right-width: 5px !important;
}

.border-opacity-10 {
  --cui-border-opacity: 0.1;
}

.border-opacity-25 {
  --cui-border-opacity: 0.25;
}

.border-opacity-50 {
  --cui-border-opacity: 0.5;
}

.border-opacity-75 {
  --cui-border-opacity: 0.75;
}

.border-opacity-100 {
  --cui-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

html:not([dir=rtl]) .me-0 {
  margin-right: 0 !important;
}
*[dir=rtl] .me-0 {
  margin-left: 0 !important;
}

html:not([dir=rtl]) .me-1 {
  margin-right: 0.25rem !important;
}
*[dir=rtl] .me-1 {
  margin-left: 0.25rem !important;
}

html:not([dir=rtl]) .me-2 {
  margin-right: 0.5rem !important;
}
*[dir=rtl] .me-2 {
  margin-left: 0.5rem !important;
}

html:not([dir=rtl]) .me-3 {
  margin-right: 1rem !important;
}
*[dir=rtl] .me-3 {
  margin-left: 1rem !important;
}

html:not([dir=rtl]) .me-4 {
  margin-right: 1.5rem !important;
}
*[dir=rtl] .me-4 {
  margin-left: 1.5rem !important;
}

html:not([dir=rtl]) .me-5 {
  margin-right: 3rem !important;
}
*[dir=rtl] .me-5 {
  margin-left: 3rem !important;
}

html:not([dir=rtl]) .me-auto {
  margin-right: auto !important;
}
*[dir=rtl] .me-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

html:not([dir=rtl]) .ms-0 {
  margin-left: 0 !important;
}
*[dir=rtl] .ms-0 {
  margin-right: 0 !important;
}

html:not([dir=rtl]) .ms-1 {
  margin-left: 0.25rem !important;
}
*[dir=rtl] .ms-1 {
  margin-right: 0.25rem !important;
}

html:not([dir=rtl]) .ms-2 {
  margin-left: 0.5rem !important;
}
*[dir=rtl] .ms-2 {
  margin-right: 0.5rem !important;
}

html:not([dir=rtl]) .ms-3 {
  margin-left: 1rem !important;
}
*[dir=rtl] .ms-3 {
  margin-right: 1rem !important;
}

html:not([dir=rtl]) .ms-4 {
  margin-left: 1.5rem !important;
}
*[dir=rtl] .ms-4 {
  margin-right: 1.5rem !important;
}

html:not([dir=rtl]) .ms-5 {
  margin-left: 3rem !important;
}
*[dir=rtl] .ms-5 {
  margin-right: 3rem !important;
}

html:not([dir=rtl]) .ms-auto {
  margin-left: auto !important;
}
*[dir=rtl] .ms-auto {
  margin-right: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

html:not([dir=rtl]) .pe-0 {
  padding-right: 0 !important;
}
*[dir=rtl] .pe-0 {
  padding-left: 0 !important;
}

html:not([dir=rtl]) .pe-1 {
  padding-right: 0.25rem !important;
}
*[dir=rtl] .pe-1 {
  padding-left: 0.25rem !important;
}

html:not([dir=rtl]) .pe-2 {
  padding-right: 0.5rem !important;
}
*[dir=rtl] .pe-2 {
  padding-left: 0.5rem !important;
}

html:not([dir=rtl]) .pe-3 {
  padding-right: 1rem !important;
}
*[dir=rtl] .pe-3 {
  padding-left: 1rem !important;
}

html:not([dir=rtl]) .pe-4 {
  padding-right: 1.5rem !important;
}
*[dir=rtl] .pe-4 {
  padding-left: 1.5rem !important;
}

html:not([dir=rtl]) .pe-5 {
  padding-right: 3rem !important;
}
*[dir=rtl] .pe-5 {
  padding-left: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

html:not([dir=rtl]) .ps-0 {
  padding-left: 0 !important;
}
*[dir=rtl] .ps-0 {
  padding-right: 0 !important;
}

html:not([dir=rtl]) .ps-1 {
  padding-left: 0.25rem !important;
}
*[dir=rtl] .ps-1 {
  padding-right: 0.25rem !important;
}

html:not([dir=rtl]) .ps-2 {
  padding-left: 0.5rem !important;
}
*[dir=rtl] .ps-2 {
  padding-right: 0.5rem !important;
}

html:not([dir=rtl]) .ps-3 {
  padding-left: 1rem !important;
}
*[dir=rtl] .ps-3 {
  padding-right: 1rem !important;
}

html:not([dir=rtl]) .ps-4 {
  padding-left: 1.5rem !important;
}
*[dir=rtl] .ps-4 {
  padding-right: 1.5rem !important;
}

html:not([dir=rtl]) .ps-5 {
  padding-left: 3rem !important;
}
*[dir=rtl] .ps-5 {
  padding-right: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--cui-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

html:not([dir=rtl]) .text-start {
  text-align: left !important;
}
*[dir=rtl] .text-start {
  text-align: right !important;
}

html:not([dir=rtl]) .text-end {
  text-align: right !important;
}
*[dir=rtl] .text-end {
  text-align: left !important;
}

html:not([dir=rtl]) .text-center {
  text-align: center !important;
}
*[dir=rtl] .text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-primary-rgb), var(--cui-text-opacity)) !important;
}

.text-secondary {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-secondary-rgb), var(--cui-text-opacity)) !important;
}

.text-success {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-success-rgb), var(--cui-text-opacity)) !important;
}

.text-info {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-info-rgb), var(--cui-text-opacity)) !important;
}

.text-warning {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-warning-rgb), var(--cui-text-opacity)) !important;
}

.text-danger {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-danger-rgb), var(--cui-text-opacity)) !important;
}

.text-light {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-light-rgb), var(--cui-text-opacity)) !important;
}

.text-dark {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-dark-rgb), var(--cui-text-opacity)) !important;
}

.text-black {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-black-rgb), var(--cui-text-opacity)) !important;
}

.text-white {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-white-rgb), var(--cui-text-opacity)) !important;
}

.text-body {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-body-color-rgb), var(--cui-text-opacity)) !important;
}

.text-muted {
  --cui-text-opacity: 1;
  color: rgba(44, 56, 74, 0.38) !important;
}

.text-black-50 {
  --cui-text-opacity: 1;
  color: rgba(0, 0, 21, 0.5) !important;
}

.text-white-50 {
  --cui-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --cui-text-opacity: 1;
  color: inherit !important;
}

.text-high-emphasis-inverse {
  --cui-text-opacity: 1;
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-medium-emphasis-inverse {
  --cui-text-opacity: 1;
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-disabled-inverse {
  --cui-text-opacity: 1;
  color: rgba(255, 255, 255, 0.38) !important;
}

.text-high-emphasis {
  --cui-text-opacity: 1;
  color: rgba(44, 56, 74, 0.95) !important;
}

.text-medium-emphasis {
  --cui-text-opacity: 1;
  color: rgba(44, 56, 74, 0.681) !important;
}

.text-disabled {
  --cui-text-opacity: 1;
  color: rgba(44, 56, 74, 0.38) !important;
}

.text-opacity-25 {
  --cui-text-opacity: 0.25;
}

.text-opacity-50 {
  --cui-text-opacity: 0.5;
}

.text-opacity-75 {
  --cui-text-opacity: 0.75;
}

.text-opacity-100 {
  --cui-text-opacity: 1;
}

.bg-primary {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity)) !important;
}

.bg-secondary {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-secondary-rgb), var(--cui-bg-opacity)) !important;
}

.bg-success {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-success-rgb), var(--cui-bg-opacity)) !important;
}

.bg-info {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-info-rgb), var(--cui-bg-opacity)) !important;
}

.bg-warning {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-warning-rgb), var(--cui-bg-opacity)) !important;
}

.bg-danger {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-danger-rgb), var(--cui-bg-opacity)) !important;
}

.bg-light {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important;
}

.bg-dark {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-dark-rgb), var(--cui-bg-opacity)) !important;
}

.bg-black {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-black-rgb), var(--cui-bg-opacity)) !important;
}

.bg-white {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-white-rgb), var(--cui-bg-opacity)) !important;
}

.bg-body {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-body-bg-rgb), var(--cui-bg-opacity)) !important;
}

.bg-transparent {
  --cui-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --cui-bg-opacity: 0.1;
}

.bg-opacity-15 {
  --cui-bg-opacity: 0.15;
}

.bg-opacity-25 {
  --cui-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --cui-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --cui-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --cui-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--cui-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--cui-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--cui-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--cui-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--cui-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--cui-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--cui-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--cui-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--cui-border-radius) !important;
  border-top-right-radius: var(--cui-border-radius) !important;
}

html:not([dir=rtl]) .rounded-end {
  border-top-right-radius: var(--cui-border-radius) !important;
}
*[dir=rtl] .rounded-end {
  border-top-left-radius: var(--cui-border-radius) !important;
}
html:not([dir=rtl]) .rounded-end {
  border-bottom-right-radius: var(--cui-border-radius) !important;
}
*[dir=rtl] .rounded-end {
  border-bottom-left-radius: var(--cui-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--cui-border-radius) !important;
  border-bottom-left-radius: var(--cui-border-radius) !important;
}

html:not([dir=rtl]) .rounded-start {
  border-bottom-left-radius: var(--cui-border-radius) !important;
}
*[dir=rtl] .rounded-start {
  border-bottom-right-radius: var(--cui-border-radius) !important;
}
html:not([dir=rtl]) .rounded-start {
  border-top-left-radius: var(--cui-border-radius) !important;
}
*[dir=rtl] .rounded-start {
  border-top-right-radius: var(--cui-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  html:not([dir=rtl]) .float-sm-start {
    float: left !important;
  }
  *[dir=rtl] .float-sm-start {
    float: right !important;
  }
  html:not([dir=rtl]) .float-sm-end {
    float: right !important;
  }
  *[dir=rtl] .float-sm-end {
    float: left !important;
  }
  html:not([dir=rtl]) .float-sm-none {
    float: none !important;
  }
  *[dir=rtl] .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  html:not([dir=rtl]) .me-sm-0 {
    margin-right: 0 !important;
  }
  *[dir=rtl] .me-sm-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  *[dir=rtl] .me-sm-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  *[dir=rtl] .me-sm-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-sm-3 {
    margin-right: 1rem !important;
  }
  *[dir=rtl] .me-sm-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  *[dir=rtl] .me-sm-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-sm-5 {
    margin-right: 3rem !important;
  }
  *[dir=rtl] .me-sm-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .me-sm-auto {
    margin-right: auto !important;
  }
  *[dir=rtl] .me-sm-auto {
    margin-left: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  html:not([dir=rtl]) .ms-sm-0 {
    margin-left: 0 !important;
  }
  *[dir=rtl] .ms-sm-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  *[dir=rtl] .ms-sm-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  *[dir=rtl] .ms-sm-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-sm-3 {
    margin-left: 1rem !important;
  }
  *[dir=rtl] .ms-sm-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  *[dir=rtl] .ms-sm-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-sm-5 {
    margin-left: 3rem !important;
  }
  *[dir=rtl] .ms-sm-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .ms-sm-auto {
    margin-left: auto !important;
  }
  *[dir=rtl] .ms-sm-auto {
    margin-right: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  html:not([dir=rtl]) .pe-sm-0 {
    padding-right: 0 !important;
  }
  *[dir=rtl] .pe-sm-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  *[dir=rtl] .pe-sm-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  *[dir=rtl] .pe-sm-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-sm-3 {
    padding-right: 1rem !important;
  }
  *[dir=rtl] .pe-sm-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  *[dir=rtl] .pe-sm-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-sm-5 {
    padding-right: 3rem !important;
  }
  *[dir=rtl] .pe-sm-5 {
    padding-left: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  html:not([dir=rtl]) .ps-sm-0 {
    padding-left: 0 !important;
  }
  *[dir=rtl] .ps-sm-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  *[dir=rtl] .ps-sm-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  *[dir=rtl] .ps-sm-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-sm-3 {
    padding-left: 1rem !important;
  }
  *[dir=rtl] .ps-sm-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  *[dir=rtl] .ps-sm-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-sm-5 {
    padding-left: 3rem !important;
  }
  *[dir=rtl] .ps-sm-5 {
    padding-right: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  html:not([dir=rtl]) .text-sm-start {
    text-align: left !important;
  }
  *[dir=rtl] .text-sm-start {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-sm-end {
    text-align: right !important;
  }
  *[dir=rtl] .text-sm-end {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-sm-center {
    text-align: center !important;
  }
  *[dir=rtl] .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  html:not([dir=rtl]) .float-md-start {
    float: left !important;
  }
  *[dir=rtl] .float-md-start {
    float: right !important;
  }
  html:not([dir=rtl]) .float-md-end {
    float: right !important;
  }
  *[dir=rtl] .float-md-end {
    float: left !important;
  }
  html:not([dir=rtl]) .float-md-none {
    float: none !important;
  }
  *[dir=rtl] .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  html:not([dir=rtl]) .me-md-0 {
    margin-right: 0 !important;
  }
  *[dir=rtl] .me-md-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .me-md-1 {
    margin-right: 0.25rem !important;
  }
  *[dir=rtl] .me-md-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-md-2 {
    margin-right: 0.5rem !important;
  }
  *[dir=rtl] .me-md-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-md-3 {
    margin-right: 1rem !important;
  }
  *[dir=rtl] .me-md-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .me-md-4 {
    margin-right: 1.5rem !important;
  }
  *[dir=rtl] .me-md-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-md-5 {
    margin-right: 3rem !important;
  }
  *[dir=rtl] .me-md-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .me-md-auto {
    margin-right: auto !important;
  }
  *[dir=rtl] .me-md-auto {
    margin-left: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  html:not([dir=rtl]) .ms-md-0 {
    margin-left: 0 !important;
  }
  *[dir=rtl] .ms-md-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  *[dir=rtl] .ms-md-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  *[dir=rtl] .ms-md-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-md-3 {
    margin-left: 1rem !important;
  }
  *[dir=rtl] .ms-md-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  *[dir=rtl] .ms-md-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-md-5 {
    margin-left: 3rem !important;
  }
  *[dir=rtl] .ms-md-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .ms-md-auto {
    margin-left: auto !important;
  }
  *[dir=rtl] .ms-md-auto {
    margin-right: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  html:not([dir=rtl]) .pe-md-0 {
    padding-right: 0 !important;
  }
  *[dir=rtl] .pe-md-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  *[dir=rtl] .pe-md-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  *[dir=rtl] .pe-md-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-md-3 {
    padding-right: 1rem !important;
  }
  *[dir=rtl] .pe-md-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  *[dir=rtl] .pe-md-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-md-5 {
    padding-right: 3rem !important;
  }
  *[dir=rtl] .pe-md-5 {
    padding-left: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  html:not([dir=rtl]) .ps-md-0 {
    padding-left: 0 !important;
  }
  *[dir=rtl] .ps-md-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  *[dir=rtl] .ps-md-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  *[dir=rtl] .ps-md-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-md-3 {
    padding-left: 1rem !important;
  }
  *[dir=rtl] .ps-md-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  *[dir=rtl] .ps-md-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-md-5 {
    padding-left: 3rem !important;
  }
  *[dir=rtl] .ps-md-5 {
    padding-right: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  html:not([dir=rtl]) .text-md-start {
    text-align: left !important;
  }
  *[dir=rtl] .text-md-start {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-md-end {
    text-align: right !important;
  }
  *[dir=rtl] .text-md-end {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-md-center {
    text-align: center !important;
  }
  *[dir=rtl] .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  html:not([dir=rtl]) .float-lg-start {
    float: left !important;
  }
  *[dir=rtl] .float-lg-start {
    float: right !important;
  }
  html:not([dir=rtl]) .float-lg-end {
    float: right !important;
  }
  *[dir=rtl] .float-lg-end {
    float: left !important;
  }
  html:not([dir=rtl]) .float-lg-none {
    float: none !important;
  }
  *[dir=rtl] .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  html:not([dir=rtl]) .me-lg-0 {
    margin-right: 0 !important;
  }
  *[dir=rtl] .me-lg-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  *[dir=rtl] .me-lg-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  *[dir=rtl] .me-lg-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-lg-3 {
    margin-right: 1rem !important;
  }
  *[dir=rtl] .me-lg-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  *[dir=rtl] .me-lg-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-lg-5 {
    margin-right: 3rem !important;
  }
  *[dir=rtl] .me-lg-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .me-lg-auto {
    margin-right: auto !important;
  }
  *[dir=rtl] .me-lg-auto {
    margin-left: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  html:not([dir=rtl]) .ms-lg-0 {
    margin-left: 0 !important;
  }
  *[dir=rtl] .ms-lg-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  *[dir=rtl] .ms-lg-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  *[dir=rtl] .ms-lg-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-lg-3 {
    margin-left: 1rem !important;
  }
  *[dir=rtl] .ms-lg-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  *[dir=rtl] .ms-lg-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-lg-5 {
    margin-left: 3rem !important;
  }
  *[dir=rtl] .ms-lg-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .ms-lg-auto {
    margin-left: auto !important;
  }
  *[dir=rtl] .ms-lg-auto {
    margin-right: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  html:not([dir=rtl]) .pe-lg-0 {
    padding-right: 0 !important;
  }
  *[dir=rtl] .pe-lg-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  *[dir=rtl] .pe-lg-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  *[dir=rtl] .pe-lg-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-lg-3 {
    padding-right: 1rem !important;
  }
  *[dir=rtl] .pe-lg-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  *[dir=rtl] .pe-lg-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-lg-5 {
    padding-right: 3rem !important;
  }
  *[dir=rtl] .pe-lg-5 {
    padding-left: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  html:not([dir=rtl]) .ps-lg-0 {
    padding-left: 0 !important;
  }
  *[dir=rtl] .ps-lg-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  *[dir=rtl] .ps-lg-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  *[dir=rtl] .ps-lg-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-lg-3 {
    padding-left: 1rem !important;
  }
  *[dir=rtl] .ps-lg-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  *[dir=rtl] .ps-lg-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-lg-5 {
    padding-left: 3rem !important;
  }
  *[dir=rtl] .ps-lg-5 {
    padding-right: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  html:not([dir=rtl]) .text-lg-start {
    text-align: left !important;
  }
  *[dir=rtl] .text-lg-start {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-lg-end {
    text-align: right !important;
  }
  *[dir=rtl] .text-lg-end {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-lg-center {
    text-align: center !important;
  }
  *[dir=rtl] .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  html:not([dir=rtl]) .float-xl-start {
    float: left !important;
  }
  *[dir=rtl] .float-xl-start {
    float: right !important;
  }
  html:not([dir=rtl]) .float-xl-end {
    float: right !important;
  }
  *[dir=rtl] .float-xl-end {
    float: left !important;
  }
  html:not([dir=rtl]) .float-xl-none {
    float: none !important;
  }
  *[dir=rtl] .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  html:not([dir=rtl]) .me-xl-0 {
    margin-right: 0 !important;
  }
  *[dir=rtl] .me-xl-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  *[dir=rtl] .me-xl-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  *[dir=rtl] .me-xl-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-xl-3 {
    margin-right: 1rem !important;
  }
  *[dir=rtl] .me-xl-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  *[dir=rtl] .me-xl-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-xl-5 {
    margin-right: 3rem !important;
  }
  *[dir=rtl] .me-xl-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .me-xl-auto {
    margin-right: auto !important;
  }
  *[dir=rtl] .me-xl-auto {
    margin-left: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  html:not([dir=rtl]) .ms-xl-0 {
    margin-left: 0 !important;
  }
  *[dir=rtl] .ms-xl-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  *[dir=rtl] .ms-xl-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  *[dir=rtl] .ms-xl-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-xl-3 {
    margin-left: 1rem !important;
  }
  *[dir=rtl] .ms-xl-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  *[dir=rtl] .ms-xl-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-xl-5 {
    margin-left: 3rem !important;
  }
  *[dir=rtl] .ms-xl-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .ms-xl-auto {
    margin-left: auto !important;
  }
  *[dir=rtl] .ms-xl-auto {
    margin-right: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  html:not([dir=rtl]) .pe-xl-0 {
    padding-right: 0 !important;
  }
  *[dir=rtl] .pe-xl-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  *[dir=rtl] .pe-xl-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  *[dir=rtl] .pe-xl-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-xl-3 {
    padding-right: 1rem !important;
  }
  *[dir=rtl] .pe-xl-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  *[dir=rtl] .pe-xl-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-xl-5 {
    padding-right: 3rem !important;
  }
  *[dir=rtl] .pe-xl-5 {
    padding-left: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  html:not([dir=rtl]) .ps-xl-0 {
    padding-left: 0 !important;
  }
  *[dir=rtl] .ps-xl-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  *[dir=rtl] .ps-xl-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  *[dir=rtl] .ps-xl-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-xl-3 {
    padding-left: 1rem !important;
  }
  *[dir=rtl] .ps-xl-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  *[dir=rtl] .ps-xl-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-xl-5 {
    padding-left: 3rem !important;
  }
  *[dir=rtl] .ps-xl-5 {
    padding-right: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  html:not([dir=rtl]) .text-xl-start {
    text-align: left !important;
  }
  *[dir=rtl] .text-xl-start {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-xl-end {
    text-align: right !important;
  }
  *[dir=rtl] .text-xl-end {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-xl-center {
    text-align: center !important;
  }
  *[dir=rtl] .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  html:not([dir=rtl]) .float-xxl-start {
    float: left !important;
  }
  *[dir=rtl] .float-xxl-start {
    float: right !important;
  }
  html:not([dir=rtl]) .float-xxl-end {
    float: right !important;
  }
  *[dir=rtl] .float-xxl-end {
    float: left !important;
  }
  html:not([dir=rtl]) .float-xxl-none {
    float: none !important;
  }
  *[dir=rtl] .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  html:not([dir=rtl]) .me-xxl-0 {
    margin-right: 0 !important;
  }
  *[dir=rtl] .me-xxl-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  *[dir=rtl] .me-xxl-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  *[dir=rtl] .me-xxl-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-xxl-3 {
    margin-right: 1rem !important;
  }
  *[dir=rtl] .me-xxl-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  *[dir=rtl] .me-xxl-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-xxl-5 {
    margin-right: 3rem !important;
  }
  *[dir=rtl] .me-xxl-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .me-xxl-auto {
    margin-right: auto !important;
  }
  *[dir=rtl] .me-xxl-auto {
    margin-left: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  html:not([dir=rtl]) .ms-xxl-0 {
    margin-left: 0 !important;
  }
  *[dir=rtl] .ms-xxl-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  *[dir=rtl] .ms-xxl-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  *[dir=rtl] .ms-xxl-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  *[dir=rtl] .ms-xxl-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  *[dir=rtl] .ms-xxl-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  *[dir=rtl] .ms-xxl-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-auto {
    margin-left: auto !important;
  }
  *[dir=rtl] .ms-xxl-auto {
    margin-right: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-0 {
    padding-right: 0 !important;
  }
  *[dir=rtl] .pe-xxl-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  *[dir=rtl] .pe-xxl-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  *[dir=rtl] .pe-xxl-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  *[dir=rtl] .pe-xxl-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  *[dir=rtl] .pe-xxl-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  *[dir=rtl] .pe-xxl-5 {
    padding-left: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-0 {
    padding-left: 0 !important;
  }
  *[dir=rtl] .ps-xxl-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  *[dir=rtl] .ps-xxl-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  *[dir=rtl] .ps-xxl-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  *[dir=rtl] .ps-xxl-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  *[dir=rtl] .ps-xxl-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  *[dir=rtl] .ps-xxl-5 {
    padding-right: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  html:not([dir=rtl]) .text-xxl-start {
    text-align: left !important;
  }
  *[dir=rtl] .text-xxl-start {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-xxl-end {
    text-align: right !important;
  }
  *[dir=rtl] .text-xxl-end {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-xxl-center {
    text-align: center !important;
  }
  *[dir=rtl] .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.bg-primary-gradient {
  background-color: var(--cui-primary, #483fce);
  background-image: linear-gradient(45deg, var(--cui-primary-start, #857eed) 0%, var(--cui-primary-stop, #483fce 100%));
}

.bg-secondary-gradient {
  background-color: var(--cui-secondary, #fff);
  background-image: linear-gradient(45deg, var(--cui-secondary-start, #c8d2dc) 0%, var(--cui-secondary-stop, #fff 100%));
}

.bg-success-gradient {
  background-color: var(--cui-success, #49b87c);
  background-image: linear-gradient(45deg, var(--cui-success-start, #85dbad) 0%, var(--cui-success-stop, #49b87c 100%));
}

.bg-info-gradient {
  background-color: var(--cui-info, #686eda);
  background-image: linear-gradient(45deg, var(--cui-info-start, #9ea2f6) 0%, var(--cui-info-stop, #686eda 100%));
}

.bg-warning-gradient {
  background-color: var(--cui-warning, #e6b800);
  background-image: linear-gradient(45deg, var(--cui-warning-start, #ffdb4d) 0%, var(--cui-warning-stop, #e6b800 100%));
}

.bg-danger-gradient {
  background-color: var(--cui-danger, #d73263);
  background-image: linear-gradient(45deg, var(--cui-danger-start, #f4739a) 0%, var(--cui-danger-stop, #d73263 100%));
}

.bg-light-gradient {
  background-color: var(--cui-light, #fff);
  background-image: linear-gradient(45deg, var(--cui-light-start, #e3e8ed) 0%, var(--cui-light-stop, #fff 100%));
}

.bg-dark-gradient {
  background-color: var(--cui-dark, #212333);
  background-image: linear-gradient(45deg, var(--cui-dark-start, #3c4b64) 0%, var(--cui-dark-stop, #212333 100%));
}

.wrapper {
  width: 100%;
  will-change: auto;
  transition: padding 0.15s;
}
html:not([dir=rtl]) .wrapper {
  padding-left: var(--cui-sidebar-occupy-start, 0);
}
*[dir=rtl] .wrapper {
  padding-right: var(--cui-sidebar-occupy-start, 0);
}
html:not([dir=rtl]) .wrapper {
  padding-right: var(--cui-sidebar-occupy-end, 0);
}
*[dir=rtl] .wrapper {
  padding-left: var(--cui-sidebar-occupy-end, 0);
}
@media (prefers-reduced-motion: reduce) {
  .wrapper {
    transition: none;
  }
}

.fc {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, .3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: var(--cui-border-color, #ebedef);
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;
  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, .25);
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: rgba(215, 215, 215, .3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: .3;
  --fc-highlight-color: rgba(188, 232, 241, .3);
  --fc-today-bg-color: rgba(255, 220, 40, .15);
  --fc-now-indicator-color: var(--cui-danger);
}

/* stylelint-disable declaration-no-important, scss/selector-no-redundant-nesting-selector */
.example:not(:first-child) {
  margin-top: 1.5rem;
}
.example .tab-content {
  background-color: #fbfcfd !important;
}
.dark-theme .example .tab-content {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.example + p {
  margin-top: 1.5rem;
}
.example .preview + p {
  margin-top: 2rem;
}
.example .preview > .form-control + .form-control {
  margin-top: 0.5rem;
}
.example .preview > .nav + .nav,
.example .preview > .alert + .alert,
.example .preview > .navbar + .navbar,
.example .preview > .progress + .progress {
  margin-top: 1rem;
}
.example .preview > .dropdown-menu {
  position: static;
  display: block;
}
.example .preview > :last-child {
  margin-bottom: 0;
}
.example .preview > svg + svg,
.example .preview > img + img {
  margin-left: 0.5rem;
}
.example .preview > .btn,
.example .preview > .btn-group {
  margin: 0.25rem 0.125rem;
}
.example .preview > .btn-toolbar + .btn-toolbar {
  margin-top: 0.5rem;
}
.example .preview > .list-group {
  max-width: 400px;
}
.example .preview > [class*=list-group-horizontal] {
  max-width: 100%;
}
.example .preview .fixed-top,
.example .preview .sticky-top {
  position: static;
  margin: -1rem -1rem 1rem;
}
.example .preview .fixed-bottom {
  position: static;
  margin: 1rem -1rem -1rem;
}
@media (min-width: 576px) {
  .example .preview .fixed-top,
  .example .preview .sticky-top {
    margin: -1.5rem -1.5rem 1rem;
  }
  .example .preview .fixed-bottom {
    margin: 1rem -1.5rem -1.5rem;
  }
}
.example .preview .pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.example .preview .docs-example-modal .modal {
  position: static;
  display: block;
}

.card,
.dropdown-menu,
.footer,
.header,
.sidebar {
  box-shadow: rgba(50, 50, 93, 0.02) 0 2px 5px -1px, rgba(0, 0, 0, 0.05) 0 1px 3px -1px;
}

.sidebar-overlaid {
  box-shadow: rgba(50, 50, 93, 0.02) 0 2px 5px -1px, rgba(0, 0, 0, 0.05) 0 1px 3px -1px, 0 5px 6px -3px rgba(0, 0, 0, 0.25);
}

.card-header {
  border-bottom: 1px solid var(--cui-card-border-color);
}

.footer,
.header {
  border: 0;
}

.sidebar-nav {
  margin: 0.75rem;
}
.sidebar-nav .nav-link {
  border-radius: 0.375rem;
}
.sidebar-nav .nav-group.show .nav-group-toggle:hover {
  color: rgba(255, 255, 255, 0.87);
}

.sidebar-narrow-unfoldable:not(:hover) .sidebar-nav {
  margin: 0.75rem 0;
}

.sidebar-brand {
  justify-content: space-between;
  padding: 0 0.75rem;
}

.sidebar-toggler {
  flex: 0 0 2rem;
  background-color: transparent;
}
.sidebar-toggler::before {
  width: 2rem;
}
.sidebar-toggler:hover {
  background-color: transparent;
}

.sidebar-narrow-unfoldable:not(:hover) .sidebar-brand {
  justify-content: center;
  padding: 0;
}
.sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler {
  display: none;
}

/*# sourceMappingURL=style.css.map */
